import { useCallback, useMemo } from "react";

import { Label, PanelHeader } from ".";
import DropDown from "../../Common/DropDown";
import FilterDropDown from "../../Common/FilterDropDown";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  fetchFilteredReportFilter,
  updateReportFilter,
} from "../../../redux/slices/reportSlice";

const HierarchyFilters = () => {
  const dispatch = useAppDispatch();
  const { reportFilters, selectedFilters, reportDataInfo } = useAppSelector(
    (state) => state.report
  );

  const handleFilterSelect = useCallback(
    (filterName: string, selectedOption: string[]) => {
      // Dispatch the action to update the selected filter
      dispatch(updateReportFilter({ filterName, selectedOption }));
    },
    [dispatch]
  );

  const handleApply = useCallback(
    (filterName: string, selectedOptions: string[]) => {
      let filteredOptions: Record<string, string | string[]> = {}; // Use Record to define the type

      reportDataInfo.filterHierarchy.forEach((filter, index) => {
        if (selectedFilters[filter] && selectedFilters[filter].length > 0) {
          filteredOptions[filter] = selectedFilters[filter];
        }
      });

      filteredOptions[filterName] = selectedOptions;

      dispatch(
        fetchFilteredReportFilter({
          reportName: reportDataInfo.name,
          filters: filteredOptions,
        })
      );
    },
    [dispatch, reportDataInfo, selectedFilters]
  );

  const handleGroupSelect = useCallback(
    (selectedGroup: string) => {
      // Dispatch the action to update the selected filter
      dispatch(
        updateReportFilter({
          filterName: "GroupBy",
          selectedOption: selectedGroup,
        })
      );
    },
    [dispatch]
  );

  const selectedOptions = useMemo(() => {
    return reportDataInfo.filterHierarchy.map((filterName, index) => {
      const filterValue = selectedFilters[filterName];

      if (typeof filterValue === "string") {
        return [filterValue]; // Convert single string to an array
      } else if (Array.isArray(filterValue) && filterValue.length > 0) {
        return filterValue.flat(); // Flatten any nested arrays
      } else {
        return reportFilters[filterName]; // Handle case where filterValue might be undefined or invalid
      }
    });
  }, [reportDataInfo.filterHierarchy, reportFilters, selectedFilters]);

  const renderHierarchyFilters = () => {
    return (
      <div className="grid grid-cols-2 gap-[16px] gap-y-[32px]">
        {reportDataInfo.filterHierarchy.map((filterName, index) => {
          // Add "ALL" to each filter's options
          const options = reportFilters[filterName];

          return (
            <div className="flex flex-col gap-[4px]" key={index}>
              <Label label={filterName} />

              <FilterDropDown
                options={options}
                selectedOption={selectedOptions[index] || ["ALL"]} // Set the current selection
                onOptionSelect={(option) =>
                  handleFilterSelect(filterName, option)
                }
                className="w-[100%]"
                buttonBgColor="bg-custom-darkTeal"
                buttonTextColor="text-primaryText"
                buttonHeight="h-[38px]"
                buttonWidth="!w-[100%]"
                dropdownOffset={42}
                dropdownBg="bg-custom-dropdownBg"
                dropdownText="text-custom-dropdownText"
                handleApply={(newSelectedOptions: string[]) => {
                  handleApply(filterName, newSelectedOptions);
                }}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const selectedGroupM = useMemo(() => {
    return selectedFilters["GroupBy"]
      ? typeof selectedFilters["GroupBy"] === "string"
        ? selectedFilters["GroupBy"]
        : selectedFilters["GroupBy"][0]
      : reportDataInfo.filterDefaultGroupBy;
  }, [reportDataInfo.filterDefaultGroupBy, selectedFilters]);

  const filterGroupNames = useMemo(() => {
    return Object.keys(reportDataInfo.filterGroupFields);
  }, [reportDataInfo.filterGroupFields]);

  return (
    <div className="w-full bg-custom-deepCharcoal rounded-[8px] gap-[24px] pb-[16px] shadow-8dp-umbra">
      <div className="flex flex-col w-full gap-[32px] px-[8px] pb-[24px]  border-b-[1px] border-custom-white-10">
        <PanelHeader header="GROUP BY" />

        <div className="flex flex-col gap-[4px]">
          <Label label="Group By" />

          <DropDown
            options={filterGroupNames}
            selectedOption={selectedGroupM}
            onOptionSelect={(option) => {
              handleGroupSelect(option);
            }}
            label="Select Report"
            className="w-[100%]"
            buttonBgColor="bg-custom-darkTeal"
            buttonTextColor="text-primaryText"
            buttonHeight="h-[38px]"
            buttonWidth="!w-[100%]"
            dropdownOffset="top-[42px]"
            dropdownBg="bg-custom-dropdownBg"
            dropdownText="text-custom-dropdownText"
          />
        </div>
      </div>

      <div className="flex flex-col w-full gap-[32px] px-[8px] pb-[24px]">
        <PanelHeader header="HIERARCHY" />
        {renderHierarchyFilters()}
      </div>
    </div>
  );
};

export default HierarchyFilters;

const TrophyIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.375 3.25C0.375 2.76675 0.766751 2.375 1.25 2.375H4.25V4.125H2.125V5.275C2.125 7.79959 4.14105 9.875 6.725 9.875V11.625C3.15895 11.625 0.375 8.75041 0.375 5.275V3.25ZM19.875 4.125H17.75V2.375H20.75C21.2332 2.375 21.625 2.76675 21.625 3.25V5.275C21.625 8.75041 18.8411 11.625 15.275 11.625V9.875C17.8589 9.875 19.875 7.79959 19.875 5.275V4.125ZM8 16.875C7.65825 16.875 7.375 17.1582 7.375 17.5V18.875H14.625V17.5C14.625 17.1582 14.3418 16.875 14 16.875H8ZM5.625 17.5C5.625 16.1918 6.69175 15.125 8 15.125H14C15.3082 15.125 16.375 16.1918 16.375 17.5V19.75C16.375 20.2332 15.9832 20.625 15.5 20.625H6.5C6.01675 20.625 5.625 20.2332 5.625 19.75V17.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.875 1.75C4.875 1.26675 5.26675 0.875 5.75 0.875H16.25C16.7332 0.875 17.125 1.26675 17.125 1.75V7.75C17.125 10.3235 15.5722 12.4465 13.375 13.3845V14.5H11.625V12.775C11.625 12.3917 11.8745 12.0529 12.2407 11.9393C14.0735 11.3705 15.375 9.74215 15.375 7.75V2.625H6.625V7.75C6.625 9.74215 7.92652 11.3705 9.75935 11.9393C10.1255 12.0529 10.375 12.3917 10.375 12.775V16H8.625V13.3845C6.42778 12.4465 4.875 10.3235 4.875 7.75V1.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default TrophyIcon;

import { API_BASE_URL } from "../const/config";
import { IHomeSheet } from "../interfaces/homeSheet";
import { removeEmptyArrays } from "../utils";
import axiosInstance from "./axiosInstance";

/**
 * Fetches the list of reports from the server.
 * @returns The list of reports retrieved from the server.
 */
export const getReportList = async () => {
  try {
    // Make a GET request to the /reports/ endpoint
    const response = await axiosInstance.get(`${API_BASE_URL}/reports/`);
    return response.data; // Return the data from the response
  } catch (error) {
    console.error("Error fetching production reports", error); // Log any errors
    throw error; // Rethrow the error for further handling
  }
};

/**
 * Fetches detailed information about a specific report by its name.
 * @param reportName - The name of the report to fetch info for.
 * @returns The report information retrieved from the server.
 */
export const getReportDataInfo = async (reportName: string) => {
  try {
    // Make a GET request to the /reports/info endpoint with the report name as a query parameter
    const response = await axiosInstance.get(
      `${API_BASE_URL}/reports/info?reportName=${reportName}`
    );
    return response.data; // Return the data from the response
  } catch (error) {
    console.error("Error fetching report info", error); // Log any errors
    throw error; // Rethrow the error for further handling
  }
};

/**
 * Fetches filtered report data based on the specified report name, view, and filters.
 * @param reportName - The name of the report to filter.
 * @param reportView - The view of the report to use.
 * @param filter - The filters to apply to the report data.
 * @returns The filtered report data retrieved from the server.
 */
export const getFilteredReportData = async (
  reportName: string,
  reportView: string,
  filter: any
) => {
  try {
    const cleanFilter = removeEmptyArrays(filter); // Clean the filter to remove empty arrays
    // Make a POST request to the /reports/data endpoint with the report name, view, and filters
    const response = await axiosInstance.post(
      `${API_BASE_URL}/reports/data?reportName=${reportName}&reportView=${reportView}`,
      { filters: cleanFilter }
    );
    return response.data; // Return the data from the response
  } catch (error) {
    console.error("Error fetching report data", error); // Log any errors
    throw error; // Rethrow the error for further handling
  }
};

/**
 * Fetches photos related to the reports.
 * @returns The photo information retrieved from the server.
 */
export const getPhotos = async () => {
  try {
    // Make a GET request to the /reports/photos/ endpoint
    const response = await axiosInstance.get(`${API_BASE_URL}/reports/photos/`);
    return response.data; // Return the data from the response
  } catch (error) {
    console.error("Error fetching photo infos", error); // Log any errors
    throw error; // Rethrow the error for further handling
  }
};

export const getCustomSheetReportData = async (sheets: IHomeSheet[]) => {
  try {
    // Make a GET request to the /reports/info endpoint with the report name as a query parameter
    const response = await axiosInstance.post(
      `${API_BASE_URL}/reports/home_sheets_data/`,
      sheets.map((sheet) => {
        return {
          report_fields: sheet.reportFields,
          report_filters: sheet.reportFilters,
          report_id: sheet.reportId,
          report_alias: sheet.reportAlias,
          report_name: sheet.reportName,
          report_filter_view_name: sheet.reportFilterViewName,
          report_view_name: sheet.reportViewName,
          sheet_field: sheet.sheetField,
          sheet_type: sheet.sheetType,
        };
      })
    );
    return response.data; // Return the data from the response
  } catch (error) {
    console.error("Error fetching report data", error); // Log any errors
    throw error; // Rethrow the error for further handling
  }
};

export const getHomeSheetsInfo = async (sheets: IHomeSheet[]) => {
  try {
    // Make a GET request to the /reports/info endpoint with the report name as a query parameter
    const response = await axiosInstance.post(
      `${API_BASE_URL}/reports/home_sheets_info/`,
      sheets.map((sheet) => {
        return {
          report_fields: sheet.reportFields,
          report_filters: sheet.reportFilters,
          report_id: sheet.reportId,
          report_alias: sheet.reportAlias,
          report_name: sheet.reportName,
          report_view_name: sheet.reportViewName,
          report_filter_view_name: sheet.reportFilterViewName,
          sheet_field: sheet.sheetField,
          sheet_type: sheet.sheetType,
        };
      })
    );
    return response.data; // Return the data from the response
  } catch (error) {
    console.error("Error fetching report data", error); // Log any errors
    throw error; // Rethrow the error for further handling
  }
};

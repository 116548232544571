// Helper to render table headers
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Optional for styling
import { ReactNode } from "react";

import ArrowDown from "../../../icons/ArrowDown";

const tableNormalHeightOffset = 274;
const tableFullScreenHeightOffset = 90;

const minTableHeaderClass = "!p-[2px] !h-[30px]";
const minTableSubHeaderClass = "!h-[20px] !top-[30px]";

const minTableCellGroupFieldClass = "!py-[0px] !max-w-[72px]";
const minTableCellClass = "!py-[0px]";

const standardTableGroupFieldClass = " !max-w-[120px]";
const standardTableCellClass = " max-w-[54px] !min-w-[54px]";

const minTableGroupFieldWidth = 72;

export const renderHeader = (
  isFitScreen: boolean,
  headers: string[] | undefined,
  loading: boolean,
  skeletonColCount: number,
  sortBy: (sortByColumn: number) => void,
  sortByColumn: number,
  sortByDirection: number,
  tableWidth: number,
  columnCount: number
) => {
  const cellWidth = isFitScreen
    ? (tableWidth - minTableGroupFieldWidth) / columnCount - 4
    : "";

  if (loading) {
    return (
      <tr>
        {Array.from({ length: skeletonColCount }).map((_, index) => (
          <th
            key={index}
            className={`
              py-[4px] px-[6px] h-[35px] text-center font-primary text-xs not-italic font-semibold 
              leading-[120%] whitespace-nowrap bg-custom-deepCharcoal sticky top-0 z-10 
              transition duration-200 overflow-ellipsis
              ${isFitScreen ? minTableHeaderClass : ""}
              ${
                isFitScreen
                  ? index === 0
                    ? minTableCellGroupFieldClass
                    : minTableCellClass
                  : index === 0
                  ? standardTableGroupFieldClass
                  : standardTableCellClass
              }
              ${
                index === 0 ? "sticky left-0 bg-custom-deepCharcoal z-20" : ""
              }`}
          >
            <Skeleton
              width={60}
              height={12}
              className="bg-custom-darkTeal animate-pulse rounded p-[8px]"
            />
          </th>
        ))}
      </tr>
    );
  }

  return (
    <tr>
      {headers?.map((header, index) => (
        <th
          key={index}
          className={`
            py-[4px] px-[6px] h-[35px] text-center font-primary text-xs not-italic font-semibold
            leading-[120%] whitespace-nowrap bg-custom-deepCharcoal sticky top-0 z-10 
            transition duration-200 select-none
            ${isFitScreen ? minTableHeaderClass : ""}
            ${
              isFitScreen
                ? index === 0
                  ? minTableCellGroupFieldClass
                  : minTableCellClass
                : index === 0
                ? standardTableGroupFieldClass
                : standardTableCellClass
            }
            ${
              index === 0
                ? "sticky left-0 bg-custom-deepCharcoal z-20"
                : "cursor-pointer"
            }`}
          onClick={
            index === 0
              ? () => {}
              : () => {
                  sortBy(index);
                }
          }
        >
          <div
            className="whitespace-nowrap overflow-hidden overflow-ellipsis"
            style={{
              width: cellWidth,
            }}
          >
            {header}
          </div>
          <div className="w-full flex justify-center">
            {index > 0 && (
              <ArrowDown
                className={`text-primaryText ${
                  sortByColumn === index && sortByDirection ? "rotate-180" : ""
                }`}
              />
            )}
          </div>
        </th>
      ))}
    </tr>
  );
};

// Helper to render table subheaders
export const renderSubHeader = (
  isFitScreen: boolean,
  subHeader: string[],
  loading: boolean,
  skeletonColCount: number,
  sortByColumn: number,
  tableWidth: number,
  columnCount: number
) => {
  const cellWidth = isFitScreen
    ? (tableWidth - minTableGroupFieldWidth) / columnCount
    : "";

  if (loading) {
    return (
      <tr>
        {Array.from({ length: skeletonColCount }).map((_, index) => (
          <th
            key={index}
            className={`p-[8px] h-[35px] text-primary text-center font-primary text-sm not-italic font-medium leading-[120%] bg-custom-deepCharcoal sticky top-[35px] z-10 
              ${isFitScreen ? minTableSubHeaderClass : ""}
              ${index === 0 ? "sticky left-0 bg-custom-deepCharcoal z-20" : ""}
            `}
          >
            <Skeleton
              width={24}
              height={19}
              className="bg-custom-darkTeal animate-pulse rounded p-[2.5px]"
            />
          </th>
        ))}
      </tr>
    );
  }

  return (
    <tr className="bg-custom-deepCharcoal">
      {subHeader.map((value, index) => (
        <th
          key={index}
          className={`p-[0px] h-[35px] text-primary text-center font-primary bg-custom-deepCharcoal sticky top-[35px] z-10 transition duration-200
            ${isFitScreen ? minTableSubHeaderClass : ""}
            ${
              isFitScreen
                ? index === 0
                  ? minTableCellGroupFieldClass
                  : minTableCellClass
                : index === 0
                ? standardTableGroupFieldClass
                : standardTableCellClass
            }
            ${index === 0 ? "sticky left-0 bg-custom-deepCharcoal z-20" : ""}`}
        >
          <div
            className={`font-medium text-sm not-italic leading-[120%] h-full flex items-center justify-center ${
              index === sortByColumn ? "bg-custom-white-10" : ""
            }`}
            style={{
              width: cellWidth,
            }}
          >
            {typeof value === "number"
              ? parseFloat(Number(value).toFixed(2))
              : value}
          </div>
        </th>
      ))}
    </tr>
  );
};

// Helper to render table rows
export const renderTableRows = (
  isFitScreen: boolean,
  isFullScreen: boolean,
  content: Record<string, any>[] | undefined,
  loading: boolean,
  skeletonRowCount: number,
  skeletonColCount: number,
  defaultIcon: ReactNode,
  sortByColumn: number,
  tableWidth: number,
  columnCount: number,
  handleOnClickEntity: (selectedEntity: string) => void
) => {
  const rowHeight = isFitScreen
    ? (window.innerHeight -
        (isFullScreen
          ? tableFullScreenHeightOffset
          : tableNormalHeightOffset)) /
      (content?.length || 1)
    : "";

  const cellWidth = isFitScreen
    ? (tableWidth - minTableGroupFieldWidth) / columnCount - 4
    : "";

  if (loading) {
    return Array.from({ length: skeletonRowCount }).map((_, rowIndex) => (
      <tr
        key={rowIndex}
        className={`${rowIndex % 2 === 0 ? "bg-custom-darkTeal" : ""}`}
        style={{ height: rowHeight, width: cellWidth }}
      >
        {Array.from({ length: skeletonColCount }).map((_, colIndex) => (
          <td
            key={colIndex}
            className={`p-[3px] text-primaryText font-primary text-sm not-italic font-medium leading-[120%] whitespace-nowrap text-center ${
              colIndex === 0 ? `sticky left-0` : ""
            }
              `}
          >
            <Skeleton
              width={colIndex === 0 ? 100 : 16}
              height={14}
              className="bg-custom-darkTeal animate-pulse rounded p-[7px]"
            />
          </td>
        ))}
      </tr>
    ));
  }

  return content?.map((row, rowIndex) => (
    <tr
      key={rowIndex}
      className={`${
        rowIndex % 2 === 0 ? "bg-custom-darkTeal " : "bg-custom-deepCharcoal"
      }`}
      style={{ height: rowHeight, minHeight: rowHeight, width: cellWidth }}
    >
      {Object.entries(row).map(([key, value], colIndex) => {
        // Skip rendering the "Photo" field
        if (key === "photo") return null;

        return (
          <td
            key={colIndex}
            className={`relative overflow-hidden overflow-ellipsis whitespace-nowrap transition duration-200 select-none
              ${
                isFitScreen
                  ? colIndex === 0
                    ? minTableCellGroupFieldClass
                    : minTableCellClass
                  : colIndex === 0
                  ? standardTableGroupFieldClass
                  : standardTableCellClass
              }
              ${colIndex === sortByColumn ? "bg-custom-white-10" : ""}
              ${colIndex === 0 ? `text-left sticky left-0 z-10` : "text-center"}
              ${
                colIndex === 0 &&
                (rowIndex % 2 === 0
                  ? "bg-custom-darkTeal "
                  : "bg-custom-deepCharcoal")
              }`}
            onClick={
              colIndex === 0
                ? () => {
                    handleOnClickEntity(value);
                  }
                : () => {}
            }
          >
            <span
              className="absolute inset-0 border-2 border-transparent transition duration-200
                        hover:border-custom-white-90
                        active:border-custom-white-b3"
              style={{
                height: rowHeight,
                width: colIndex > 0 ? cellWidth : "",
              }}
            />
            <div className="flex items-center gap-[4px] w-full">
              {colIndex === 0 &&
                (row.photo === "" ? (
                  defaultIcon
                ) : (
                  <img
                    src={row.photo}
                    alt=""
                    className="w-[28px] h-[28px] rounded-[50%] object-cover"
                    style={{
                      height: rowHeight,
                      width: colIndex > 0 ? cellWidth : "",
                    }}
                  />
                ))}
              <span
                className={`overflow-hidden overflow-ellipsis text-primaryText font-primary text-xs not-italic font-medium leading-[120%]
                  ${colIndex > 0 ? "text-center w-full" : ""}`}
                style={{
                  height: rowHeight,
                  width: colIndex > 0 ? cellWidth : "",
                  maxHeight: "14px",
                }}
              >
                {typeof value === "number"
                  ? Math.round(value * 10) / 10
                  : value}
              </span>
            </div>
          </td>
        );
      })}
    </tr>
  ));
};

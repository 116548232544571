const SmallHomeIconSelected = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      className={className}
    >
      <path
        d="M16.5417 7.89418C16.5413 7.89379 16.5409 7.8934 16.5405 7.89301L9.60584 0.958618C9.31025 0.662903 8.91726 0.5 8.49924 0.5C8.08122 0.5 7.68823 0.662773 7.39251 0.958488L0.461489 7.88938C0.459154 7.89172 0.45682 7.89418 0.454485 7.89651C-0.152509 8.50701 -0.151472 9.49753 0.457468 10.1065C0.735674 10.3848 1.10311 10.546 1.49597 10.5629C1.51193 10.5644 1.52801 10.5652 1.54422 10.5652H1.82061V15.6685C1.82061 16.6784 2.64226 17.5 3.65236 17.5H6.36542C6.64038 17.5 6.86347 17.277 6.86347 17.002V13.001C6.86347 12.5402 7.2383 12.1653 7.69912 12.1653H9.29936C9.76018 12.1653 10.135 12.5402 10.135 13.001V17.002C10.135 17.277 10.358 17.5 10.6331 17.5H13.3461C14.3562 17.5 15.1779 16.6784 15.1779 15.6685V10.5652H15.4342C15.852 10.5652 16.245 10.4024 16.5409 10.1067C17.1505 9.49675 17.1507 8.50455 16.5417 7.89418Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SmallHomeIconSelected;

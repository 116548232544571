import { API_BASE_URL } from "../const/config";
import { removeEmptyArrays } from "../utils";
import axiosInstance from "./axiosInstance";

/**
 * Fetches filtered report data based on the specified report name and filters.
 * @param reportName - The name of the report to filter.
 * @param filter - The filters to apply to the report data.
 * @returns The filtered report data retrieved from the server.
 */
export const getFilteredReportFilter = async (
  reportName: string,
  filter: any
) => {
  try {
    const cleanFilter = removeEmptyArrays(filter); // Clean the filter to remove empty arrays
    // Make a POST request to the /filters endpoint with the report name and filters
    const response = await axiosInstance.post(
      `${API_BASE_URL}/filters?reportName=${reportName}`,
      { filters: cleanFilter }
    );
    return response.data; // Return the data from the response
  } catch (error) {
    console.error("Error fetching report data", error); // Log any errors
    throw error; // Rethrow the error for further handling
  }
};

/**
 * Fetches all available report filters from the server.
 * @returns The list of report filters retrieved from the server.
 */
export const getReportFilters = async () => {
  try {
    // Make a GET request to the /filters/ endpoint
    const response = await axiosInstance.get(`${API_BASE_URL}/filters/`);
    return response.data; // Return the data from the response
  } catch (error) {
    console.error("Error fetching report filters", error); // Log any errors
    throw error; // Rethrow the error for further handling
  }
};

const FilterIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.6954 3C20.0169 2.99997 20.3319 3.09032 20.6044 3.26074C20.877 3.43116 21.0961 3.67478 21.2369 3.96379C21.3776 4.2528 21.4342 4.57555 21.4003 4.89521C21.3664 5.21486 21.2433 5.51854 21.045 5.77157L15.3107 13.0894C15.0744 13.3912 14.946 13.7634 14.946 14.1467V18.9566C14.946 19.2301 14.8806 19.4996 14.7551 19.7427C14.6297 19.9857 14.448 20.1953 14.225 20.3537C14.0021 20.5122 13.7445 20.6151 13.4738 20.6537C13.203 20.6923 12.9269 20.6656 12.6686 20.5757L10.3204 19.7589C9.98418 19.6419 9.69269 19.4232 9.4864 19.133C9.28012 18.8429 9.16929 18.4957 9.16929 18.1397V14.157C9.16923 13.7675 9.03651 13.3896 8.793 13.0856L2.94729 5.78571C2.74542 5.53358 2.61889 5.22955 2.58227 4.90864C2.54565 4.58773 2.60043 4.26301 2.74031 3.97188C2.88019 3.68075 3.09946 3.43506 3.37288 3.26311C3.6463 3.09117 3.96273 2.99996 4.28571 3H19.6954ZM18.8147 4.71429H5.178C5.09728 4.71434 5.01821 4.73718 4.94991 4.78019C4.8816 4.8232 4.82683 4.88463 4.7919 4.9574C4.75697 5.03017 4.7433 5.11133 4.75247 5.19153C4.76163 5.27172 4.79326 5.3477 4.84371 5.41071L10.1314 12.0137C10.589 12.5851 10.8517 13.288 10.881 14.0194L10.8836 14.157V17.835C10.8836 17.924 10.9114 18.0108 10.963 18.0833C11.0146 18.1557 11.0875 18.2104 11.1716 18.2396L12.6621 18.7581C12.7267 18.7806 12.7957 18.7874 12.8634 18.7777C12.9312 18.7681 12.9956 18.7424 13.0513 18.7028C13.1071 18.6632 13.1525 18.6109 13.1839 18.5501C13.2153 18.4893 13.2317 18.422 13.2317 18.3536V14.1467C13.2318 13.4292 13.4569 12.7298 13.8754 12.147L13.9611 12.0321L19.152 5.40729C19.2016 5.34401 19.2324 5.26805 19.2409 5.18809C19.2494 5.10813 19.2352 5.02739 19.2 4.95511C19.1648 4.88283 19.1099 4.82191 19.0417 4.77933C18.9735 4.73674 18.8951 4.7142 18.8147 4.71429Z"
        fill="currentColor"
      />
      <line x1="10" y1="12.5" x2="14" y2="12.5" stroke="currentColor" />
    </svg>
  );
};

export default FilterIcon;

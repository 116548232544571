const VideoTutorialIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_1_4)">
        <path
          d="M20.7324 4.5C20.9313 4.5 21.1221 4.57902 21.2628 4.71967C21.4034 4.86032 21.4824 5.05109 21.4824 5.25V16.5H3.48242V5.25C3.48242 5.05109 3.56144 4.86032 3.70209 4.71967C3.84274 4.57902 4.03351 4.5 4.23242 4.5H20.7324ZM4.23242 3C3.63568 3 3.06339 3.23705 2.64143 3.65901C2.21947 4.08097 1.98242 4.65326 1.98242 5.25V18H22.9824V5.25C22.9824 4.65326 22.7454 4.08097 22.3234 3.65901C21.9015 3.23705 21.3292 3 20.7324 3H4.23242ZM0.482422 18.75H24.4824C24.4824 19.3467 24.2454 19.919 23.8234 20.341C23.4015 20.7629 22.8292 21 22.2324 21H2.73242C2.13568 21 1.56339 20.7629 1.14143 20.341C0.719475 19.919 0.482422 19.3467 0.482422 18.75Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9824 14C14.9154 14 16.4824 12.433 16.4824 10.5C16.4824 8.567 14.9154 7 12.9824 7C11.0494 7 9.48242 8.567 9.48242 10.5C9.48242 12.433 11.0494 14 12.9824 14ZM11.9824 12.232L14.9824 10.5L11.9824 8.76795V12.232Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_4">
          <rect
            width="24"
            height="24"
            fill="currentColor"
            transform="translate(0.482422)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VideoTutorialIcon;

const StarIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      className={className}
    >
      <path
        d="M12.0009 0L8.58844 6.915L0.960938 8.0175L6.48094 13.4025L5.17594 21L12.0009 17.415L18.8259 21L17.5209 13.4025L23.0409 8.025L15.4134 6.915L12.0009 0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default StarIcon;

// src/store/authSlice.ts
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { L_JWT_TOKEN, L_USER_ID } from "../../const/localstorage";
import { getUserIdFromJwt, loginUser } from "../../services/authService";
import decode from "jwt-claims";

interface AuthState {
  authInfo: {
    userId: number;
    token: string | null;
  };
  loading: boolean;
  error: string | null;
}

const initialState: AuthState = {
  authInfo: {
    userId: -1,
    token: null,
  },
  loading: false,
  error: null,
};

export const login = createAsyncThunk(
  "auth/login",
  async (
    { username, password }: { username: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await loginUser(username, password);
      return response; // Return the token from the API response
    } catch (error: any) {
      // Handle error response from the server
      if (error.response) {
        return rejectWithValue(error.response.data.message || "Login failed");
      }
      return rejectWithValue(error.message);
    }
  }
);

export const checkSession = createAsyncThunk("auth/checkSession", async () => {
  const response = await getUserIdFromJwt();
  return response;
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loadAuthInfo: (state) => {
      const token = localStorage.getItem(L_JWT_TOKEN);
      const userId = localStorage.getItem(L_USER_ID);

      if (!token || !userId) return;

      state.authInfo.token = token;
      state.authInfo.userId = parseInt(userId);
    },
    logout: (state) => {
      state.authInfo.token = null;
      state.authInfo.userId = -1; // Reset userId on logout
      localStorage.removeItem(L_JWT_TOKEN);
      localStorage.removeItem(L_USER_ID);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { access_token: token } = action.payload;
        const decoded = decode(token);
        const userId = decoded["user_id"];

        state.authInfo.token = token;
        state.authInfo.userId = userId;
        state.loading = false;

        localStorage.setItem(L_JWT_TOKEN, token);
        localStorage.setItem(L_USER_ID, userId.toString());
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      .addCase(checkSession.pending, (state) => {})
      .addCase(checkSession.fulfilled, (state, { payload }) => {})
      .addCase(checkSession.rejected, (state, { error }) => {
        console.log(error);
        state.authInfo = initialState.authInfo;
      });
  },
});

export const { logout, loadAuthInfo } = authSlice.actions;

export default authSlice.reducer;

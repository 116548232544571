import axios from "axios";
import { API_BASE_URL } from "../const/config";

// Create an Axios instance with default settings
const axiosInstance = axios.create({
  baseURL: API_BASE_URL, // Set the base URL for all requests
  headers: {
    "Content-Type": "application/json", // Set default content type for requests
  },
});

// Request interceptor to add the Authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("jwt_token"); // Get the token from localStorage or wherever you store it
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Append the token to the Authorization header
    }
    return config; // Return the modified config
  },
  (error) => {
    return Promise.reject(error); // Reject the promise with the error
  }
);

// Response interceptor to handle responses and errors globally
axiosInstance.interceptors.response.use(
  (response) => response, // Return the response as is
  (error) => {
    // Handle errors globally if needed
    return Promise.reject(error); // Reject the promise with the error
  }
);

export default axiosInstance; // Export the configured Axios instance

import isMobile from "is-mobile";
import { forwardRef, ReactNode, useEffect, useRef, useState } from "react";
import { renderChart } from "./helper";

interface ChartProps {
  isFullScreen: boolean;
  isFitScreen: boolean;
  loading: boolean;
  className: string;
  chartData: any | any[];
  defaultIcon?: ReactNode;
}

const Chart = forwardRef<HTMLDivElement, ChartProps>(
  (
    {
      isFullScreen,
      isFitScreen,
      loading,
      className,
      chartData,
      defaultIcon,
    }: ChartProps,
    ref
  ) => {
    const chartRef = useRef<HTMLDivElement | null>(null);

    const rowIncrement = isMobile() ? 10 : 20; // Number of rows to load on each scroll
    const [visibleRows, setVisibleRows] = useState(rowIncrement); // Start with 20 rows

    // Handle scroll event to trigger lazy loading
    const handleScroll = () => {
      const table = document.querySelector(".report-chart");
      if (table) {
        const { scrollTop, scrollHeight, clientHeight } = table;
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          loadMoreRows();
        }
      }
    };

    const loadMoreRows = () => {
      if (visibleRows < chartData.length) {
        setVisibleRows((prev) =>
          Math.min(prev + rowIncrement, chartData.length)
        );
      }
    };

    useEffect(() => {
      const table = document.querySelector(".report-chart");
      table?.addEventListener("scroll", handleScroll);
      return () => {
        table?.removeEventListener("scroll", handleScroll);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleRows, chartData]);

    useEffect(() => {
      setVisibleRows(rowIncrement);
    }, [rowIncrement, chartData]);

    return (
      <div
        className={`report-chart w-full bg-custom-deepCharcoal ${className} overflow-auto 
          ${isFitScreen ? "overflow-hidden" : ""}`}
        style={{
          height: isFullScreen
            ? window.innerHeight - 40
            : window.innerHeight - 224,
        }}
        ref={(el) => {
          chartRef.current = el;
          if (ref) {
            if (typeof ref === "function") {
              ref(el);
            } else {
              ref.current = el;
            }
          }
        }}
      >
        <div
          className={
            isFitScreen
              ? "w-full flex flex-col p-sm"
              : "w-full flex flex-col gap-sm p-sm"
          }
        >
          {renderChart(
            isFitScreen,
            isFullScreen,
            loading,
            rowIncrement,
            visibleRows,
            chartData
          )}
        </div>
      </div>
    );
  }
);

export default Chart;

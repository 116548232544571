import CompetitionIcon from "../components/icons/CompetitionIcon";
import HomeIcon from "../components/icons/HomeIcon";
import LeaderBoardIcon from "../components/icons/LeaderBoardIcon";
import PayIcon from "../components/icons/PayIcon";
import SearchIcon from "../components/icons/SearchIcon";

export const NavBarItems = [
  {
    icon: HomeIcon,
    name: "Home",
    link: "/",
  },
  {
    icon: CompetitionIcon,
    name: "Competitions",
    link: "/competitions",
  },
  {
    icon: LeaderBoardIcon,
    name: "Leaderboard",
    link: "/leaderboard",
  },
  {
    icon: SearchIcon,
    name: "Search/AI",
    link: "/search",
  },
  {
    icon: PayIcon,
    name: "Pay",
    link: "/pay",
  },
];

const HomeIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        d="M4.90039 21V9L12.9004 3L20.9004 9V21H14.9004V14H10.9004V21H4.90039Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HomeIcon;

import { API_BASE_URL } from "../const/config";
import axiosInstance from "./axiosInstance";

/**
 * Logs in a user with the provided username and password.
 * @param username - The username of the user.
 * @param password - The password of the user.
 * @returns The response data containing the authentication token.
 */
export const loginUser = async (username: string, password: string) => {
  try {
    // Send a POST request to the login endpoint with username and password
    const response = await axiosInstance.post(
      `${API_BASE_URL}/auth/login/`,
      new URLSearchParams({
        username,
        password,
      }),
      {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }, // Set content type for form data
      }
    );

    return response.data; // Return the token from the API response
  } catch (error) {
    console.error("Error while login", error); // Log any errors during login
    throw error; // Rethrow the error for further handling
  }
};

/**
 * Fetches a JWT token for Tableau.
 * @returns The response data containing the Tableau JWT token.
 */
export const getTableauJwtToken = async () => {
  try {
    // Send a GET request to fetch the Tableau JWT token
    const response = await axiosInstance.get(`${API_BASE_URL}/auth/token/`);
    return response.data; // Return the token from the API response
  } catch (error) {
    console.error("Error fetching tableau jwt token", error); // Log any errors during token retrieval
    throw error; // Rethrow the error for further handling
  }
};

/**
 * Retrieves the user ID from the JWT token.
 * @returns The response data containing the user ID.
 */
export const getUserIdFromJwt = async () => {
  try {
    // Send a GET request to fetch the user ID from the protected route
    const response = await axiosInstance.get(`${API_BASE_URL}/auth/protected`);
    return response.data; // Return the user ID from the API response
  } catch (error) {
    console.error("Jwt token expired", error); // Log any errors if the JWT token is expired
    throw error; // Rethrow the error for further handling
  }
};

import { ReactNode } from "react";
import Button from "./Button";

interface ConfirmPopupProps {
  children?: ReactNode; // To render any custom content inside the popup
  isOpen: boolean; // Control the open/close state externally
  onClose: () => void; // Handle closing the popup externally
  backBg?: string;
  popupBg?: string;
  popupBorder?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const ConfirmPopup = ({
  children,
  isOpen,
  onClose,
  backBg = "bg-opposite-highlight",
  popupBg,
  popupBorder,
  onConfirm,
  onCancel,
}: ConfirmPopupProps) => {
  return (
    <div
      className={`items-center fixed inset-0 z-50 flex justify-center items-end transition-opacity duration-300 ease-out ${backBg} ${
        isOpen
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      }`}
      onClick={onClose}
    >
      <div
        className={`p-[24px] pt-[30px] bg-custom-deepCharcoal bottom-[50%] w-[100%] max-w-[320px] md:w-1/2 ring-[1px] ring-neutral-800 rounded-sm transform transition-all duration-300 ease-out ${popupBg} ${popupBorder} ${
          isOpen
            ? "translate-y-[0] scale-100 opacity-100"
            : "translate-y-[50%] scale-95 opacity-0"
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        <div>{children}</div>
        <div className="flex flex-col pt-[24px]">
          <Button
            bgColor="bg-primary"
            activeColor="active:bg-neutral-200"
            className="w-[100%] py-sm px-[14px] max-h-[50px] rounded-sm mt-[16px]"
            onClick={onConfirm}
          >
            <span
              className={`font-primary text-m not-italic font-medium leading-[120%] text-base-highlight`}
            >
              Confirm
            </span>
          </Button>

          <Button
            bgColor="bg-secondary"
            activeColor="active:bg-neutral-200"
            className="w-[100%] py-sm px-[14px] max-h-[50px] rounded-sm mt-[16px]"
            onClick={onCancel}
          >
            <span
              className={`font-primary text-m not-italic font-medium leading-[120%] text-opposite-highlight`}
            >
              Cancel
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPopup;

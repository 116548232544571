import { useState } from "react";
import toast from "react-hot-toast";
import { PageType } from "../../../const/enums/PageType";
import { useReportActionContext } from "../../../context/ReportActionContext";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { setReportViewOpened } from "../../../redux/slices/globalSlice";
import { saveFiltersViewAction } from "../../../redux/slices/reportSlice";

import LatestViews from "./LatestViews";
import ReportViewList from "./ReportViewList";
import SaveView from "./SaveView";
import ConfirmPopup from "../../Common/ConfirmPopup";

interface ReportViewProps {
  isPopupOpen: boolean;
}

const ReportView = ({ isPopupOpen }: ReportViewProps) => {
  const { onHandleApplyFilters } = useReportActionContext();
  const {
    selectedDateRange,
    selectedFilters,
    reportFields,
    reportFilterViews,
    currentReport,
  } = useAppSelector((state) => state.report);
  const dispatch = useAppDispatch();

  const [viewName, setViewName] = useState("");
  const [isDuplicatePopupOpen, setIsDuplicatePopupOpen] = useState(false);
  const { currentPage } = useAppSelector((state) => state.global);

  const onHandleSaveView = () => {
    if (viewName.length === 0) {
      toast.error("Please input view name.");
      return;
    }

    const isSameNameExist =
      reportFilterViews.filter((view) => view.viewName === viewName).length > 0;

    if (isSameNameExist) {
      setIsDuplicatePopupOpen(true);
    } else {
      onConfirmSave();
    }
  };

  const onClosePopup = () => {
    setIsDuplicatePopupOpen(false);
  };

  const onConfirmSave = (isOverwrite: boolean = false) => {
    const filters = {
      ...selectedFilters,
      Date: selectedDateRange,
    };

    dispatch(
      saveFiltersViewAction({
        isOverwrite,
        reportId: currentReport?.id || -1,
        viewName: viewName,
        fields: reportFields,
        savedFilters: filters,
      })
    )
      .unwrap()
      .then(() => {
        toast.success("Saved a view successfully!");
        onHandleApplyFilters();
        dispatch(setReportViewOpened(false));
      });
  };

  return (
    <div
      className={`fixed top-[0] left-[0px] w-full h-full transition duration-300 ease-in-out z-30 max-h-max-height ${
        isPopupOpen
          ? "opacity-100 scale-100 translate-y-[0%]"
          : "opacity-0 scale-95 translate-y-[100%] pointer-events-none"
      }`}
    >
      <div className="h-[52px] pointer-events-none"></div>

      <div className="flex flex-col gap-[17px] flex-grow-1 h-full z-50 overflow-y-scroll px-[28px] bg-custom-deepCharcoal ">
        {currentPage === PageType.ReportViewList ? (
          <ReportViewList />
        ) : (
          <>
            <SaveView
              viewName={viewName}
              onViewNameChange={setViewName}
              onHandleSaveView={onHandleSaveView}
            />

            <LatestViews />
          </>
        )}
      </div>

      <ConfirmPopup
        isOpen={isDuplicatePopupOpen}
        onClose={onClosePopup}
        backBg="bg-[#20202080]"
        onConfirm={() => {
          onConfirmSave(true);
          setIsDuplicatePopupOpen(false);
        }}
        onCancel={onClosePopup}
      >
        <div className="rounded-sm">
          <div className="text-primaryText text-center font-primary text-xl not-italic font-semibold leading-[120%]">
            Confirm Overwrite
          </div>
          <div className="mt-[8px] text-primaryText text-center font-primary not-italic font-normal leading-[20px] tracking-[-0.096px]">
            A view with the same name already exists. Would you like to
            overwrite the previous view?
          </div>
        </div>
      </ConfirmPopup>
    </div>
  );
};

export default ReportView;

const EyeIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 20C4.14881 20 0.256312 13.1555 0.0945 12.8643C-0.0315 12.6376 -0.0315 12.3622 0.0945 12.1355C0.256312 11.8445 4.14881 5 12 5C19.8512 5 23.7437 11.8445 23.9055 12.1357C24.0315 12.3624 24.0315 12.6378 23.9055 12.8645C23.7437 13.1555 19.8512 20 12 20ZM1.63256 12.4989C2.52469 13.8509 6.02737 18.5 12 18.5C17.9916 18.5 21.4787 13.8547 22.3674 12.5011C21.4753 11.1491 17.9726 6.5 12 6.5C6.00844 6.5 2.52131 11.1453 1.63256 12.4989ZM12 17C9.51862 17 7.5 14.9814 7.5 12.5C7.5 10.0186 9.51862 8 12 8C14.4814 8 16.5 10.0186 16.5 12.5C16.5 14.9814 14.4814 17 12 17ZM12 9.5C10.3459 9.5 9 10.8459 9 12.5C9 14.1541 10.3459 15.5 12 15.5C13.6541 15.5 15 14.1541 15 12.5C15 10.8459 13.6541 9.5 12 9.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EyeIcon;

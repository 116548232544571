const RemoveIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      className={className}
    >
      <path
        d="M11.8281 2.375H9.25V1.90625C9.25 1.13085 8.61915 0.5 7.84375 0.5H5.96875C5.19335 0.5 4.5625 1.13085 4.5625 1.90625V2.375H1.98438C1.3382 2.375 0.8125 2.9007 0.8125 3.54688V5.1875C0.8125 5.44637 1.02238 5.65625 1.28125 5.65625H1.53742L1.94239 14.1606C1.97816 14.9117 2.59516 15.5 3.34703 15.5H10.4655C11.2174 15.5 11.8344 14.9117 11.8701 14.1606L12.2751 5.65625H12.5312C12.7901 5.65625 13 5.44637 13 5.1875V3.54688C13 2.9007 12.4743 2.375 11.8281 2.375ZM5.5 1.90625C5.5 1.64779 5.71029 1.4375 5.96875 1.4375H7.84375C8.10221 1.4375 8.3125 1.64779 8.3125 1.90625V2.375H5.5V1.90625ZM1.75 3.54688C1.75 3.41765 1.85515 3.3125 1.98438 3.3125H11.8281C11.9574 3.3125 12.0625 3.41765 12.0625 3.54688V4.71875C11.918 4.71875 2.34862 4.71875 1.75 4.71875V3.54688ZM10.9337 14.116C10.9217 14.3664 10.7161 14.5625 10.4655 14.5625H3.34703C3.0964 14.5625 2.89073 14.3664 2.87884 14.116L2.47598 5.65625H11.3365L10.9337 14.116Z"
        fill="currentColor"
      />
      <path
        d="M6.90625 13.625C7.16512 13.625 7.375 13.4151 7.375 13.1562V7.0625C7.375 6.80363 7.16512 6.59375 6.90625 6.59375C6.64738 6.59375 6.4375 6.80363 6.4375 7.0625V13.1562C6.4375 13.4151 6.64735 13.625 6.90625 13.625Z"
        fill="currentColor"
      />
      <path
        d="M9.25 13.625C9.50887 13.625 9.71875 13.4151 9.71875 13.1562V7.0625C9.71875 6.80363 9.50887 6.59375 9.25 6.59375C8.99113 6.59375 8.78125 6.80363 8.78125 7.0625V13.1562C8.78125 13.4151 8.9911 13.625 9.25 13.625Z"
        fill="currentColor"
      />
      <path
        d="M4.5625 13.625C4.82137 13.625 5.03125 13.4151 5.03125 13.1562V7.0625C5.03125 6.80363 4.82137 6.59375 4.5625 6.59375C4.30363 6.59375 4.09375 6.80363 4.09375 7.0625V13.1562C4.09375 13.4151 4.3036 13.625 4.5625 13.625Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RemoveIcon;

const SearchIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.3008 21L16.8148 16.506M19.3008 10.5C19.3008 12.7543 18.4052 14.9163 16.8112 16.5104C15.2171 18.1045 13.0551 19 10.8008 19C8.54644 19 6.38443 18.1045 4.79037 16.5104C3.19631 14.9163 2.30078 12.7543 2.30078 10.5C2.30078 8.24566 3.19631 6.08365 4.79037 4.48959C6.38443 2.89553 8.54644 2 10.8008 2C13.0551 2 15.2171 2.89553 16.8112 4.48959C18.4052 6.08365 19.3008 8.24566 19.3008 10.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SearchIcon;

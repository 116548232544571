import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchHomeSheetsDataAction,
  fetchHomeSheetsInfoAction,
  fetchTrendingInfoAction,
} from "../../redux/slices/reportSlice";
import CompetitionSection from "./CompetitionSection";
import EditFavoritePopup from "./EditFavoritePopup";
import FavoriteSection from "./FavoriteSection";
import ReportSheetSection from "./ReportSheetSection";
import TrendingSection from "./TrendingSection";
import EditHomeSheetPopup from "./EditHomeSheetsPopup";

const Home = () => {
  const dispatch = useAppDispatch();
  const {
    featuredCompetitions,
    trendingInfos,
    homeSheets,
    homeSheetsData,
    homeSheetsDataInfo,
    photos,
    isLoadingHomeSheetsData,
    isLoadingHomeSheetsInfo,
  } = useAppSelector((state) => state.report);
  const [isEditFavoriteOpen, setIsEditFavoriteOpen] = useState(false);
  const [isEditHomeSheetOpen, setIsHomeSheetOpen] = useState(false);

  const openFavoriteEdit = () => {
    setIsEditFavoriteOpen(true);
  };

  const closeFavoriteEdit = () => {
    setIsEditFavoriteOpen(false);
  };

  const openHomeSheetEdit = () => {
    setIsHomeSheetOpen(true);
  };

  const closeHomeSheetEdit = () => {
    setIsHomeSheetOpen(false);
  };

  useEffect(() => {
    dispatch(fetchTrendingInfoAction());
  }, [dispatch]);

  useEffect(() => {
    if (homeSheets.length === 0) return;

    dispatch(fetchHomeSheetsDataAction(homeSheets));
    dispatch(fetchHomeSheetsInfoAction(homeSheets));
  }, [dispatch, homeSheets]);

  return (
    <>
      <FavoriteSection openPopup={openFavoriteEdit} />
      <CompetitionSection
        competitionName="Competitions"
        competitions={featuredCompetitions}
      />
      <TrendingSection trendingInfos={trendingInfos} />

      <ReportSheetSection
        sheets={homeSheets}
        sheetsData={homeSheetsData}
        sheetsInfo={homeSheetsDataInfo}
        photos={photos}
        isLoading={isLoadingHomeSheetsData || isLoadingHomeSheetsInfo}
        openPopup={openHomeSheetEdit}
      />

      <EditFavoritePopup
        isPopupOpen={isEditFavoriteOpen}
        closePopup={closeFavoriteEdit}
      />

      <EditHomeSheetPopup
        isPopupOpen={isEditHomeSheetOpen}
        closePopup={closeHomeSheetEdit}
      />
    </>
  );
};

export default Home;

import IconButton from "../Common/IconButton";
import ChatSendIcon from "../icons/ChatSendIcon";

const ChatVVNTFooter = () => {
  return (
    <div className="flex w-full gap-[17px] px-[24px] pb-[24px] pt-[24px]">
      <input
        type="text"
        placeholder="Type your question here..."
        className="rounded-xxs flex-grow-[1] bg-custom-grey-d9 p-sm pl-[16px] text-custom-grey-57 outline-none	font-primary text-sm not-italic font-medium leading-[100%]"
      />

      <IconButton size={45} padding={0} onClick={() => {}}>
        <ChatSendIcon className="text-primary" />
      </IconButton>
    </div>
  );
};

export default ChatVVNTFooter;

import { useMemo } from "react";
import { FavoriteType } from "../const/enums/FavoriteType";
import { IFavoriteReport } from "../interfaces/favorite";
import { useAppSelector } from "../redux/hooks";

const useParsedFavorites = () => {
  const { favorites, reports } = useAppSelector((state) => state.report);

  return useMemo(() => {
    const flattenedReports = reports.flatMap((category) => category.reports);
    const favoritesInfo: IFavoriteReport[] = [];

    favorites.forEach((favorite) => {
      if (favorite.favoriteType === FavoriteType.Report) {
        const foundReport = flattenedReports.find(
          (report) => report.id === favorite.favoriteId
        );
        if (foundReport) {
          favoritesInfo.push({ ...foundReport, ...favorite });
        }
      }
    });

    return favoritesInfo.sort((f1, f2) => f1.position - f2.position);
  }, [favorites, reports]);
};

export default useParsedFavorites;

import { handleError } from ".";
import { FavoriteType } from "../const/enums/FavoriteType";
import { IFavorite } from "../interfaces/favorite";
import { IReportField } from "../interfaces/reportField";
import { ISavedFilter } from "../interfaces/savedFilter";
import axiosInstance from "./axiosInstance"; // Adjust the path accordingly

/**
 * Saves a user view with the specified report ID, view name, fields, and filters.
 * @param reportId - The ID of the report.
 * @param viewName - The name of the view to save.
 * @param fields - The fields associated with the report.
 * @param selectedFilters - The filters to apply to the report.
 * @returns The response data from the server.
 */
export const saveUserView = async (
  reportId: number,
  viewName: string,
  fields: IReportField[],
  selectedFilters: ISavedFilter
) => {
  try {
    const response = await axiosInstance.post("/user/filter/", {
      report_id: reportId,
      view_name: viewName,
      fields: JSON.stringify(fields), // Convert fields to JSON string
      filters: JSON.stringify(selectedFilters), // Convert filters to JSON string
    });
    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

/**
 * Overwrites an existing filter view with new data.
 * @param reportId - The ID of the report.
 * @param viewName - The name of the view to overwrite.
 * @param fields - The new fields for the report.
 * @param selectedFilters - The new filters to apply to the report.
 * @returns The response data from the server.
 */
export const overwriteFilterView = async (
  reportId: number,
  viewName: string,
  fields: IReportField[],
  selectedFilters: ISavedFilter
) => {
  try {
    const response = await axiosInstance.put("/user/filter/", {
      report_id: reportId,
      view_name: viewName,
      fields: JSON.stringify(fields),
      filters: JSON.stringify(selectedFilters),
    });
    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

/**
 * Reorders the filter views based on the provided order.
 * @param viewOrders - An array representing the new order of views.
 * @returns The response data from the server.
 */
export const reorderFilterViews = async (viewOrders: any[]) => {
  try {
    const response = await axiosInstance.post(
      "/user/filter/order/",
      viewOrders // Send the new order of views
    );
    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

/**
 * Retrieves the selected filter view for a specific report.
 * @param reportId - The ID of the report.
 * @returns The selected filter view data from the server.
 */
export const getSelectedFilterView = async (reportId: number) => {
  try {
    const response = await axiosInstance.get(
      `/user/filter?reportId=${reportId}` // Get the filter view for the report
    );
    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

/**
 * Retrieves all saved filter views for a specific report.
 * @param reportId - The ID of the report.
 * @returns All saved filter views data from the server.
 */
export const getAllFilterViews = async (reportId: number) => {
  try {
    const response = await axiosInstance.get(
      `/user/filter/all?reportId=${reportId}` // Get all filter views for the report
    );
    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

/**
 * Updates the selected filter view for a specific report.
 * @param filterViewId - The ID of the filter view to update.
 * @param reportId - The ID of the report.
 * @returns The updated filter view data from the server.
 */
export const updateSelectedFilterView = async (
  filterViewId: number,
  reportId: number
) => {
  try {
    const response = await axiosInstance.patch(
      `/user/filter?viewId=${filterViewId}&reportId=${reportId}` // Update the filter view
    );

    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

/**
 * Deletes a specific filter view.
 * @param filterViewId - The ID of the filter view to delete.
 * @param reportId - The ID of the report associated with the filter view.
 * @returns The response data from the server.
 */
export const deleteFilterView = async (
  filterViewId: number,
  reportId: number
) => {
  try {
    const response = await axiosInstance.delete(
      `/user/filter?viewId=${filterViewId}&reportId=${reportId}` // Delete the filter view
    );
    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

/**
 * Toggles a user's favorite item.
 * @param favoriteType - The type of favorite (e.g., report, theme).
 * @param favoriteId - The ID of the favorite item.
 * @returns The response data from the server.
 */
export const toggleUserFavorite = async (
  favoriteType: FavoriteType,
  favoriteId: number
) => {
  try {
    const response = await axiosInstance.post("/user/favorite/", {
      favorite_type: favoriteType, // Send the favorite type
      favorite_id: favoriteId, // Send the favorite ID
    });
    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

/**
 * Retrieves all user favorites.
 * @returns The list of user favorites from the server.
 */
export const getUserFavorites = async () => {
  try {
    const response = await axiosInstance.get(`/user/favorite/`); // Get user favorites
    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

/**
 * Retrieves available themes for the user.
 * @returns The list of themes from the server.
 */
export const getThemes = async () => {
  try {
    const response = await axiosInstance.get("/user/theme/"); // Get user themes
    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

/**
 * Saves a new theme with the specified name and color.
 * @param themeName - The name of the theme.
 * @param themeColor - The color of the theme.
 * @returns The response data from the server.
 */
export const saveTheme = async ({
  themeColor,
  themeName,
}: {
  themeName: string;
  themeColor: string;
}) => {
  try {
    const response = await axiosInstance.post("/user/theme/", {
      theme_name: themeName, // Send the theme name
      theme_colors: themeColor, // Send the theme color
    });

    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

/**
 * Edits an existing theme with the specified ID, name, and colors.
 * @param themeId - The ID of the theme to edit.
 * @param themeName - The new name of the theme.
 * @param themeColors - The new colors of the theme.
 * @returns The response data from the server.
 */
export const editTheme = async ({
  themeId,
  themeName,
  themeColors,
}: {
  themeId: number;
  themeName: string;
  themeColors: string;
}) => {
  try {
    const response = await axiosInstance.put(`/user/theme/`, {
      theme_id: themeId, // Send the theme ID
      theme_name: themeName, // Send the theme name
      theme_colors: themeColors, // Send the theme colors
    });

    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

/**
 * Deletes a theme by its ID.
 * @param themeId - The ID of the theme to delete.
 * @returns The response data from the server.
 */
export const deleteTheme = async (themeId: number) => {
  try {
    const response = await axiosInstance.delete(
      `/user/theme?theme_id=${themeId}`
    ); // Delete the theme

    return response.data; // Return the response data
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

export const saveHomeSheet = async ({
  reportId,
  reportViewName,
  reportFilterViewName,
  reportFilters,
  reportFields,
  sheetType,
  sheetField,
}: {
  reportId: number;
  reportViewName: string;
  reportFilterViewName: string;
  reportFilters: string;
  reportFields: string;
  sheetType: string;
  sheetField: string;
}) => {
  try {
    const response = await axiosInstance.post("/user/home_sheets/", {
      report_id: reportId,
      report_view_name: reportViewName,
      report_filter_view_name: reportFilterViewName,
      report_filters: reportFilters,
      report_fields: reportFields,
      sheet_type: sheetType,
      sheet_field: sheetField,
    });

    return response.data;
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

export const getHomeSheets = async () => {
  try {
    const response = await axiosInstance.get("/user/home_sheets/");

    return response.data;
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

export const deleteHomeSheet = async ({
  reportId,
  reportViewName,
  reportFilterViewName,
  sheetType,
  sheetField,
}: {
  reportId: number;
  reportViewName: string;
  reportFilterViewName: string;
  sheetType: string;
  sheetField: string;
}) => {
  try {
    const response = await axiosInstance.delete(`/user/home_sheets`, {
      params: {
        report_id: reportId,
        report_view_name: reportViewName,
        report_filter_view_name: reportFilterViewName,
        sheet_type: sheetType,
        sheet_field: sheetField,
      },
    });

    return response.data;
  } catch (error) {
    handleError(error); // Handle any errors that occur
  }
};

export const updateUserFavoritePositions = async (favorites: IFavorite[]) => {
  try {
    await axiosInstance.patch(
      `/user/favorites`,
      favorites.map((f) => ({
        id: f.id,
        position: f.position,
      }))
    );
    return favorites;
  } catch (err) {
    handleError(err);
  }
};

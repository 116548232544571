import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";

import { PageType } from "../../../const/enums/PageType";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  setCurrentPage,
  setReportViewOpened,
} from "../../../redux/slices/globalSlice";
import {
  clearAndApplyFilter,
  removeFilterViewAction,
  reorderFilterViewAction,
  selectFilterViewAction,
  updateCurrentFilterViewName,
  updateReportFields,
  updateReportFilterViews,
  updateSelectedFilters,
} from "../../../redux/slices/reportSlice";
import { clearToast } from "../../../utils";

import ConfirmPopup from "../../Common/ConfirmPopup";
import IconButton from "../../Common/IconButton";

import { useReportActionContext } from "../../../context/ReportActionContext";
import Button from "../../Common/Button";
import RemoveIcon from "../../icons/RemoveIcon";
import SmallHomeIcon from "../../icons/SmallHomeIcon";
import SmallHomeIconSelected from "../../icons/SmallHomeSelectedIcon";

const DefaultViewName = "Default";

interface ReportViewListItemProps {
  isDefault: boolean;
  isSelected: boolean;
  reportViewName: string;
  onSelect: () => void;
  onRemove: () => void;
  onSetDefault: () => void;
  index?: number;
}

const ReportViewListItem = ({
  reportViewName,
  isDefault,
  isSelected,
  onSelect,
  onSetDefault,
  onRemove,
  index,
}: ReportViewListItemProps) => {
  const itemContent = (
    <>
      <div className="flex gap-[15px]">
        {isDefault ? (
          <IconButton
            size={24}
            padding={0}
            className={"hover:bg-transparent w-[24px]"}
            onClick={onSetDefault}
          >
            <SmallHomeIconSelected className="text-primary" />
          </IconButton>
        ) : (
          <IconButton
            size={24}
            padding={0}
            className={"hover:bg-transparent w-[24px]"}
            onClick={onSetDefault}
          >
            <SmallHomeIcon className="text-primaryText" />{" "}
          </IconButton>
        )}
        <div className="flex items-center gap-2">
          <div className="text-primaryText font-primary text-primaryText not-italic font-normal leading-[20px] tracking-[-0.096px] select-none">
            {reportViewName}
          </div>

          {isDefault && (
            <div className="text-primaryText opacity-60 font-primary text-primaryText not-italic font-normal leading-[20px] tracking-[-0.096px]">
              (Default)
            </div>
          )}
        </div>
      </div>

      <div className="flex items-center justify-center">
        {index !== undefined && (
          <IconButton
            size={24}
            padding={0}
            className={"hover:bg-transparent w-[24px]"}
            onClick={onRemove}
          >
            <RemoveIcon className="text-primaryText" />
          </IconButton>
        )}
      </div>
    </>
  );

  return index === undefined ? (
    <div
      className={`bg-custom-white-10 active:bg-custom-white-30 flex p-[15px] rounded-sm justify-between cursor-pointer ${
        isSelected ? "bg-custom-white-30" : ""
      }`}
      onClick={onSelect}
    >
      {itemContent}
    </div>
  ) : (
    <Draggable draggableId={reportViewName} index={index}>
      {(provided) => {
        return (
          <div
            className={`bg-custom-white-10 active:bg-custom-white-30 flex p-[15px] rounded-sm justify-between cursor-pointer ${
              isSelected ? "bg-custom-white-30" : ""
            }`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={onSelect}
          >
            {itemContent}
          </div>
        );
      }}
    </Draggable>
  );
};

const ReportViewList = () => {
  const { fetchSelectedFilterView } = useReportActionContext();
  const dispatch = useAppDispatch();
  const { reportFilterViews, currentFilterViewName, currentReport } =
    useAppSelector((state) => state.report);

  const [applyButtonVisible, setApplyButtonVisible] = useState(false);
  const [selectIndex, setSelectIndex] = useState(-1);
  const [removeIndex, setRemoveIndex] = useState(-1);
  const [showSelectConfirm, setShowSelectConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleOnSelect = (index: number, viewName: string) => {
    if (index === -1) {
      dispatch(clearAndApplyFilter());
    } else {
      const selectedView = reportFilterViews[index];
      const filters = selectedView.filters;
      const fields = selectedView.fields;

      dispatch(updateSelectedFilters(filters));
      dispatch(updateReportFields(fields));
    }
    dispatch(setReportViewOpened(false));
    dispatch(setCurrentPage(PageType.Report));
    dispatch(updateCurrentFilterViewName(viewName));
  };

  const handleOnRemove = (index: number) => {
    setShowDeleteConfirm(true);
    setRemoveIndex(index);
  };

  const handleOnSetDefault = (index: number) => {
    setShowSelectConfirm(true);
    setSelectIndex(index);
  };

  const closeSelectPopup = () => {
    setShowSelectConfirm(false);
  };

  const closeRemovePopup = () => {
    setShowDeleteConfirm(false);
  };

  const onConfirmSelect = () => {
    const selectedViewName =
      selectIndex === -1
        ? DefaultViewName
        : reportFilterViews[selectIndex].viewName;
    setShowSelectConfirm(false);
    toast.promise(
      dispatch(
        selectFilterViewAction({
          viewId: selectIndex === -1 ? -1 : reportFilterViews[selectIndex].id,
          reportId: currentReport?.id || -1,
        })
      ).unwrap(),
      {
        success: (data) => {
          fetchSelectedFilterView();
          clearToast();
          return `Successfully set ${selectedViewName} as your default view.`;
        },
        error:
          "An error occurred while trying to set the default view. Please try again.",
        loading: "Selecting...",
      }
    );

    setSelectIndex(-1);
  };

  const onConfirmRemove = () => {
    if (removeIndex < 0) return;

    const filterName = reportFilterViews[removeIndex].viewName;
    setShowDeleteConfirm(false);

    toast.promise(
      dispatch(
        removeFilterViewAction({
          viewId: reportFilterViews[removeIndex].id,
          reportId: currentReport?.id || -1,
        })
      ).unwrap(),
      {
        loading: "Deleting now",
        error: () => {
          clearToast();
          return "An error occurred while deleting the view. Please check your connection and try again.";
        },
        success: (data) => {
          fetchSelectedFilterView();
          clearToast();
          return `The view "${filterName}" has been deleted successfully.`;
        },
      }
    );

    setRemoveIndex(-1);
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedViews = Array.from(reportFilterViews);
    const [removed] = reorderedViews.splice(result.source.index, 1);
    reorderedViews.splice(result.destination.index, 0, removed);
    setApplyButtonVisible(true);
    dispatch(updateReportFilterViews(reorderedViews));
  };

  const handleApplyChange = () => {
    const viewOrders = reportFilterViews.map((view, index) => {
      return { view_id: view.id, ordered_id: index };
    });

    toast.promise(dispatch(reorderFilterViewAction(viewOrders)).unwrap(), {
      loading: "Updating...",
      error: () => {
        clearToast();
        return "An error occurred while updating filter view order. Please check your connection and try again.";
      },
      success: (data) => {
        setApplyButtonVisible(false);
        clearToast();
        return `The updated filter view order successfully.`;
      },
    });
  };

  const isDefaultViewSelected = useMemo(() => {
    return reportFilterViews.filter((view) => view.selected).length === 0;
  }, [reportFilterViews]);

  useEffect(() => {
    const selectedViewName =
      reportFilterViews.find((view) => view.selected)?.viewName ||
      DefaultViewName;

    if (!currentFilterViewName) {
      dispatch(updateCurrentFilterViewName(selectedViewName));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, reportFilterViews]);

  return (
    <div className="flex flex-col gap-[19px] py-[55px]">
      <div className="w-full flex items-center justify-between h-[32px]">
        <div className="text-primaryText font-primary text-xl not-italic font-semibold leading-[120%]">
          SAVED VIEW(S)
        </div>
        {applyButtonVisible && (
          <Button
            bgColor="bg-primary"
            activeColor="active:bg-neutral-200"
            className="!py-xs px-[14px] max-h-[32px] rounded-sm"
            onClick={handleApplyChange}
          >
            <span
              className={`font-primary text-m not-italic font-semibold leading-[120%] text-primaryText`}
            >
              Apply
            </span>
          </Button>
        )}
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className="droppable "
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className="flex flex-col gap-[10px]">
                <ReportViewListItem
                  reportViewName={DefaultViewName}
                  isSelected={DefaultViewName === currentFilterViewName}
                  isDefault={isDefaultViewSelected}
                  onSelect={() => {
                    handleOnSelect(-1, DefaultViewName);
                  }}
                  onRemove={() => {
                    handleOnRemove(-1);
                  }}
                  onSetDefault={() => {
                    handleOnSetDefault(-1);
                  }}
                />

                {reportFilterViews.map((filterView, index) => {
                  return (
                    <ReportViewListItem
                      key={filterView.viewName}
                      index={index}
                      reportViewName={filterView.viewName}
                      isSelected={filterView.viewName === currentFilterViewName}
                      isDefault={filterView.selected}
                      onSelect={() => {
                        handleOnSelect(index, filterView.viewName);
                      }}
                      onRemove={() => {
                        handleOnRemove(index);
                      }}
                      onSetDefault={() => {
                        handleOnSetDefault(index);
                      }}
                    />
                  );
                })}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <ConfirmPopup
        isOpen={showSelectConfirm}
        onClose={closeSelectPopup}
        backBg="bg-[#20202080]"
        onConfirm={() => {
          onConfirmSelect();
        }}
        onCancel={closeSelectPopup}
      >
        <div className="rounded-sm">
          <div className="text-primaryText text-center font-primary text-xl not-italic font-semibold leading-[120%]">
            SET AS DEFAULT VIEW?
          </div>
          <div className="mt-[8px] text-primaryText text-center font-primary text-primaryText not-italic font-normal leading-[20px] tracking-[-0.096px]">
            {selectIndex >= 0 &&
              reportFilterViews[selectIndex] &&
              reportFilterViews[selectIndex].viewName}
          </div>
        </div>
      </ConfirmPopup>

      <ConfirmPopup
        isOpen={showDeleteConfirm}
        onClose={closeRemovePopup}
        backBg="bg-[#20202080]"
        onConfirm={onConfirmRemove}
        onCancel={closeRemovePopup}
      >
        <div className="rounded-sm">
          <div className="text-primaryText text-center font-primary text-xl not-italic font-semibold leading-[120%]">
            DELETE VIEW?
          </div>
          <div className="mt-[8px] text-primaryText text-center font-primary text-primaryText not-italic font-normal leading-[20px] tracking-[-0.096px]">
            {removeIndex >= 0 &&
              reportFilterViews[removeIndex] &&
              reportFilterViews[removeIndex].viewName}
          </div>
        </div>
      </ConfirmPopup>
    </div>
  );
};

export default ReportViewList;

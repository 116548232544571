const DropDownArrowIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M10.0006 12.2501L6.81836 9.06782L7.87961 8.00732L10.0006 10.1291L12.1216 8.00732L13.1829 9.06782L10.0006 12.2501Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DropDownArrowIcon;

import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchTableauJwtToken } from "../../redux/slices/reportSlice";

// The Tableau API Script
const tableauScriptUrl =
  "https://10az.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js";

interface EmbeddedReportProps {
  reportName: string;
  reportUrl: string;
}

const EmbeddedReport = ({ reportName, reportUrl }: EmbeddedReportProps) => {
  const { tableauJwtToken } = useAppSelector((state) => state.report);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Dynamically load Tableau script
    const script = document.createElement("script");
    script.src = tableauScriptUrl;
    script.type = "module";
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchTableauJwtToken());
  }, [dispatch]);

  return (
    <div className="container mx-auto">
      {tableauJwtToken && reportUrl && (
        <tableau-viz
          id="tableauViz"
          src={reportUrl}
          toolbar="hidden"
          height="600px"
          showShareOptions="false"
          hide-tabs
          iframe-auth
          token={tableauJwtToken}
          device="mobile"
          className="w-full border shadow-md"
        ></tableau-viz>
      )}
    </div>
  );
};

export default EmbeddedReport;

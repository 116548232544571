const LeaderBoardIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.5 11V3H8.5V9H2.5V21H22.5V11H16.5ZM10.5 5H14.5V19H10.5V5ZM4.5 11H8.5V19H4.5V11ZM20.5 19H16.5V13H20.5V19Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LeaderBoardIcon;

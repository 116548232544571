import isMobile from "is-mobile";
import { forwardRef, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import Chart from "../Common/Chart";
import ComparisonChart from "../Common/Chart/ComparisonChart";
import DateChart from "../Common/Chart/DateChart";
import Table from "../Common/Table";
import ReportActions from "./ReportActions";

import UserIcon from "../icons/UserIcon";
import UsersIcon from "../icons/UsersIcon";

import { ReportSheetType } from "../../const/enums/ReportSheetType";
import { IReportViewSheet } from "../../interfaces/reportView";

import { TimeUnit } from "../../const/enums/TimeUnit";
import {
  applyFilter,
  updateReportFilter,
} from "../../redux/slices/reportSlice";

import useChartData from "./hooks/useChartData";
import useTableData from "./hooks/useTableData";
import ReportBreadCrumb from "./ReportBreadCrumb";

interface CustomReportProps {
  reportData: any[];
  reportSheet: IReportViewSheet;
  reportTitle?: string;
  reportType?: string;
  isFilterOpen?: boolean;
  isFitScreen: boolean;
  isFullScreen: boolean;
  toggleFullScreen: () => void;
  toggleFitScreen: () => void;
  handleShareSC: (
    tableRef: React.RefObject<HTMLDivElement>,
    reportName: string,
    isFitScreen: boolean
  ) => void;
  handleDownload: (selectedOption: string) => void;

  isHomeSheet: boolean;
  handleToggleHomeSheet: () => void;
}

const CustomReport = forwardRef<HTMLDivElement, CustomReportProps>(
  (
    {
      reportData,
      reportSheet,
      reportTitle,
      isFilterOpen,
      isFitScreen,
      isFullScreen,
      toggleFullScreen,
      toggleFitScreen,
      handleShareSC,
      handleDownload,
      isHomeSheet,
      handleToggleHomeSheet,
    }: CustomReportProps,
    ref
  ) => {
    const dispatch = useAppDispatch();

    const tableRef = useRef<HTMLDivElement>(null); // Create a ref for the Table component

    const { reportFields, isLoadingReportData, isLoadingReportDataInfo } =
      useAppSelector((state) => state.report);

    const { reportDataInfo, photos, selectedFilters, appliedFilters } =
      useAppSelector((state) => state.report);

    const groupBy = appliedFilters["GroupBy"] || [
      reportDataInfo.filterDefaultGroupBy,
    ];

    const groupByDate =
      typeof appliedFilters["GroupByDate"] === "string"
        ? appliedFilters["GroupByDate"]
        : TimeUnit.Days;

    const { tableHeader, tableSubHeader, tableContent } = useTableData(
      reportData,
      photos,
      groupBy,
      reportFields,
      reportDataInfo.tableColumns
    );

    const { chartData } = useChartData(
      reportData,
      photos,
      groupBy,
      groupByDate,
      reportSheet
    );

    const handleOnClickRow = (selectedEntity: string) => {
      const filters = reportDataInfo.filterHierarchy;
      const parsedGroupBy = typeof groupBy === "string" ? groupBy : groupBy[0];
      if (groupBy === filters[filters.length - 1]) return;

      const newGroupBy = filters.at(filters.indexOf(parsedGroupBy) + 1);
      if (!newGroupBy) return;

      dispatch(
        updateReportFilter({
          filterName: "GroupBy",
          selectedOption: newGroupBy,
        })
      );

      dispatch(
        updateReportFilter({
          filterName: parsedGroupBy,
          selectedOption: [selectedEntity],
        })
      );

      setTimeout(() => {
        dispatch(applyFilter());
      }, 10);
    };

    const renderReportView = () => {
      if (reportSheet.sheetType === ReportSheetType.Table) {
        return (
          <Table
            isFullScreen={isFullScreen}
            isFitScreen={isFitScreen}
            loading={isLoadingReportData || isLoadingReportDataInfo}
            tableHeader={tableHeader}
            tableSubHeader={tableSubHeader}
            tableContent={tableContent}
            className={`${isFullScreen ? "!border-none" : "border-rounded"}`}
            handleOnClickEntity={handleOnClickRow}
            defaultIcon={
              groupBy === "Rep" ? (
                <UserIcon
                  className={
                    isFitScreen
                      ? "text-primaryText w-[0px] h-[0px] min-w-[0px]"
                      : "text-primaryText w-[28px] h-[28px] min-w-[28px]"
                  }
                />
              ) : (
                <UsersIcon
                  className={
                    isFitScreen
                      ? "text-primaryText w-[0px] h-[0px] min-w-[0px]"
                      : "text-primaryText w-[28px] h-[28px] min-w-[28px]"
                  }
                />
              )
            }
          />
        );
      } else if (reportSheet.sheetType === ReportSheetType.Chart) {
        return (
          <Chart
            isFullScreen={isFullScreen}
            isFitScreen={isFitScreen}
            loading={isLoadingReportData || isLoadingReportDataInfo}
            className={`${isFullScreen ? "!border-none" : "border-rounded"}`}
            chartData={chartData}
          />
        );
      } else if (reportSheet.sheetType === ReportSheetType.ChartByDate) {
        return (
          <DateChart
            isFullScreen={isFullScreen}
            isFitScreen={isFitScreen}
            loading={isLoadingReportData || isLoadingReportDataInfo}
            className={`${isFullScreen ? "!border-none" : "border-rounded"}`}
            chartData={chartData}
            groupByDate={groupByDate}
          />
        );
      } else if (reportSheet.sheetType === ReportSheetType.ComparisonChart) {
        const years: number[] = [];
        reportData.forEach((row) => {
          if (!row.group_field) return;
          const date = new Date(row.group_field);
          const year = date.getFullYear();
          years.push(year);
        });

        return (
          <>
            <div className="flex bg-custom-deepCharcoal pt-[10px]">
              <div className="flex items-center">
                <div className="w-[11px] h-[11px] mx-[8px] bg-chart-gradient-1 rounded-[50%]"></div>
                <div className="text-[#FFF] font-primary text-xs not-italic font-normal">
                  {chartData ? Math.max(...years) - 1 : ""}
                </div>
              </div>

              <div className="flex items-center">
                <div className="w-[11px] h-[11px] mx-[8px] bg-chart-gradient-2 rounded-[50%]"></div>
                <div className="text-[#FFF] font-primary text-xs not-italic font-normal">
                  {chartData ? Math.max(...years) : ""}
                </div>
              </div>
            </div>
            <ComparisonChart
              isFullScreen={isFullScreen}
              isFitScreen={isFitScreen}
              loading={isLoadingReportData || isLoadingReportDataInfo}
              className={`${isFullScreen ? "!border-none" : "border-rounded"}`}
              chartData={chartData}
              groupByDate={groupByDate}
            />
          </>
        );
      }
    };

    const parsedBreadcrumbItems = useMemo(() => {
      const items: any[] = [];

      reportDataInfo.filterHierarchy.forEach((filter, index) => {
        if (selectedFilters[filter] && selectedFilters[filter].length === 1) {
          if (items.length === 0) {
            items.push({
              key: filter,
              label: filter,
            });
          }

          items.push({ key: filter, label: selectedFilters[filter][0] });
        }
      });

      return items;
    }, [reportDataInfo.filterHierarchy, selectedFilters]);

    return (
      <div
        ref={ref}
        className={`flex flex-col gap-sm bg-custom-deepCharcoal transition-all duration-300 ${
          isFilterOpen ? "overflow-hidden" : ""
        } ${
          isFullScreen
            ? "bg-custom-deepCharcoal"
            : "rounded-t-ml bg-custom-deepCharcoal"
        }`}
      >
        {reportTitle && (
          <div className="text-center text-primaryText pt-[12px] pb-[6px] font-primary text-lg not-italic font-semibold leading-[120%] border-b-[0.6px] border-custom-white-10">
            {reportTitle}
          </div>
        )}

        <div className="flex items-center justify-between">
          {parsedBreadcrumbItems.length > 0 ? (
            <ReportBreadCrumb
              items={parsedBreadcrumbItems}
              maxWidth={isMobile() ? 200 : 360}
            />
          ) : (
            <div></div>
          )}

          <ReportActions
            onFitScreen={toggleFitScreen}
            onFullScreen={toggleFullScreen}
            isFullScreen={isFullScreen}
            isFitScreen={isFitScreen}
            handleShareSC={() => {
              handleShareSC(tableRef, reportDataInfo.name, isFitScreen);
            }}
            handleDownload={handleDownload}
            isHomeSheet={isHomeSheet}
            handleToggleHomeSheet={handleToggleHomeSheet}
          />
        </div>

        <div ref={tableRef}>{renderReportView()}</div>
      </div>
    );
  }
);

export default CustomReport;

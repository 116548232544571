import { SelectedDateType } from "../const/enums/SelectedDateType";
import { IDateRangeType } from "../interfaces/dateRangeType";
import {
  getFullSeason,
  getLastMonth,
  getLastWeek,
  getMTD,
  getToday,
  getWTD,
  getYesterday,
  getYTD,
} from "../utils/date";

export const dateRanges: IDateRangeType[] = [
  { name: SelectedDateType.Today, range: getToday() },
  { name: SelectedDateType.Yesterday, range: getYesterday() },
  { name: SelectedDateType.FullSeason, range: getFullSeason() },
  { name: SelectedDateType.YTD, range: getYTD() },
  { name: SelectedDateType.LastWeek, range: getLastWeek() },
  { name: SelectedDateType.WTD, range: getWTD() },
  { name: SelectedDateType.LastMonth, range: getLastMonth() },
  { name: SelectedDateType.MTD, range: getMTD() },
];

export const hierarchyFilters = [
  {
    name: "DIV",
    values: ["All"],
  },
  {
    name: "PTR",
    values: ["All"],
  },
  {
    name: "SG REG",
    values: ["All"],
  },
  {
    name: "REG",
    values: ["All"],
  },
  {
    name: "SR MGMT",
    values: ["All"],
  },
  {
    name: "MGMT",
    values: ["All"],
  },
  {
    name: "TEAM",
    values: ["All"],
  },
  {
    name: "OFFICE",
    values: ["All"],
  },
  {
    name: "REP",
    values: ["All"],
  },
];

export const additionalFilters = [
  {
    name: "FUNDED",
    values: ["All"],
  },

  {
    name: "POSITION",
    values: ["All"],
  },

  {
    name: "CURRENT POSITION",
    values: ["All"],
  },

  {
    name: "TENURE",
    values: ["All"],
  },

  {
    name: "COMMISSION TYPE",
    values: ["All"],
  },

  {
    name: "SMART ENERGY BUNDLES",
    values: ["All"],
  },

  {
    name: "RECRUITER",
    values: ["All"],
  },
  {
    name: "EMPLOYEE TYPE",
    values: ["All"],
  },

  {
    name: "YEAR ROUND",
    values: ["All"],
  },
  {
    name: "H2B",
    values: ["All"],
  },
  {
    name: "EMPLOYEE STATUS",
    values: ["All"],
  },
  {
    name: "ACCOUNT NAME",
    values: ["All"],
  },
];

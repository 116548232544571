import { ReportDataInfo } from "../../../interfaces/reportDataInfo";
import { ISavedFilter } from "../../../interfaces/savedFilter";

export const getFilterSummary = (
  selectedFilters: ISavedFilter,
  reportDataInfo: ReportDataInfo
) => {
  const filters: any[] = [];
  Object.keys(selectedFilters).forEach((key, index) => {
    if (reportDataInfo.filterHierarchy.indexOf(key) >= 0) {
      if (selectedFilters[key].length > 0) {
        filters.push({ key: key, filter: selectedFilters[key] });
      }
    }
  });

  if (filters.length > 0) {
    let filterSummaryStr = "";
    filters.forEach((filter, index) => {
      filterSummaryStr = `${filterSummaryStr} ${
        filter.key
      }: ${filter.filter.join(",")}`;
    });

    return filterSummaryStr;
  } else {
    return (
      "All " +
      (selectedFilters["GroupBy"] || reportDataInfo.filterDefaultGroupBy)
    );
  }
};

import ChatVVNTContent from "./ChatVVNTContent";
import ChatVVNTFooter from "./ChatVVNTFooter";
import ChatVVNTHeader from "./ChatVVNTHeader";

interface ChatVVNTProps {
  showChat: boolean;
  onClose: () => void;
}

const ChatVVNT = ({ showChat, onClose }: ChatVVNTProps) => {
  return (
    <div
      className={`fixed top-0 bottom-0 left-0 right-0 transition duration-300 ${
        showChat
          ? "opacity-100 scale-100 translate-y-0"
          : "opacity-0 scale-95 translate-y-[72px] pointer-events-none"
      }`}
    >
      <div className="fixed top-0 z-50 left-0 w-full h-full flex flex-col">
        <div className="bg-custom-darkTeal  flex flex-col h-full w-full">
          <div
            className="bg-cover bg-center h-full w-full flex flex-col justify-between"
            style={{ backgroundImage: "url(./assets/imgs/chat_bg.png)" }}
          >
            <ChatVVNTHeader onClose={onClose} />
            <ChatVVNTContent />
            <ChatVVNTFooter />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatVVNT;

import isMobile from "is-mobile";
import toast from "react-hot-toast";

/**
 * Converts a data URL to a File object.
 * @param dataURL - The data URL string to convert.
 * @param filename - The name for the resulting file.
 * @returns A File object created from the data URL.
 * @throws Will throw an error if the data URL format is invalid.
 */
export const dataURLtoFile = (dataURL: string, filename: string): File => {
  const [header, data] = dataURL.split(","); // Split the data URL into header and data parts
  const mimeTypeMatch = header.match(/:(.*?);/); // Extract the MIME type from the header

  if (!mimeTypeMatch) {
    throw new Error("Invalid data URL format"); // Throw an error if MIME type is not found
  }

  const mimeType = mimeTypeMatch[1]; // Get the MIME type
  const decodedData = atob(data); // Decode the base64 data
  const lengthOfDecodedData = decodedData.length; // Get the length of the decoded data
  const u8array = new Uint8Array(lengthOfDecodedData); // Create a Uint8Array to hold the binary data

  // Populate the Uint8Array with the decoded data
  for (let i = 0; i < lengthOfDecodedData; i++) {
    u8array[i] = decodedData.charCodeAt(i);
  }

  return new File([u8array], filename, { type: mimeType }); // Return a new File object
};

/**
 * Shares or copies a file to the clipboard based on the platform.
 * @param file - The File object to share or copy.
 * @param title - The title for the shared content (not used in this implementation).
 * @param text - The text for the shared content (not used in this implementation).
 * @returns A promise that resolves when the share or copy operation is complete.
 */
export const shareOrCopyFile = async (
  file: File,
  title: string,
  text: string
): Promise<void> => {
  // Check if the device is mobile and supports sharing
  if (
    isMobile() &&
    navigator.canShare &&
    navigator.canShare({ files: [file] })
  ) {
    // Mobile or platforms that support sharing
    navigator
      .share({
        files: [file], // Share the file
      })
      .then(() => console.log("Share was successful.")) // Log success message
      .catch((error) => console.log("Sharing failed", error)); // Log error message
  } else {
    // Desktop or platforms where sharing isn't supported
    try {
      if (navigator.clipboard && navigator.clipboard.write) {
        // Prepare the file to copy to clipboard
        const clipboardItem = new ClipboardItem({
          [file.type]: file, // Create a ClipboardItem with the file
        });

        // Copy file to the clipboard
        await navigator.clipboard.write([clipboardItem]); // Write the item to the clipboard
        toast.success("Image copied to clipboard."); // Show success toast
      } else {
        toast.error("Clipboard API not supported on this browser."); // Show error toast
      }
    } catch (error) {
      toast.error("Clipboard API not supported on this browser."); // Handle exceptions and show error toast
    }
  }
};

const ArrowLeftIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      className={className}
    >
      <path
        d="M0.782422 5.00003C0.782422 4.98816 0.786244 4.96608 0.810101 4.93979L0.830966 4.92284L5.23118 1.34668C5.3334 1.26361 5.51986 1.26361 5.62208 1.34668L6.13388 1.76263C6.17666 1.7974 6.18242 1.82786 6.18242 1.84212C6.18242 1.85611 6.17696 1.88514 6.13562 1.91823L6.13561 1.91822L6.13325 1.92015L2.6472 4.76904L2.36282 5.00145L2.6473 5.23372L6.13335 8.08L6.13388 8.08042C6.17665 8.11519 6.18242 8.14565 6.18242 8.15991C6.18242 8.17391 6.17696 8.20294 6.13562 8.23602L6.13561 8.23602L6.13388 8.23743L5.62208 8.65338C5.51986 8.73645 5.33339 8.73645 5.23118 8.65338L5.2311 8.65332L0.830966 5.07984C0.788298 5.04516 0.782422 5.01475 0.782422 5.00003Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.6"
      />
    </svg>
  );
};

export default ArrowLeftIcon;

// redux/store.ts
import { configureStore } from "@reduxjs/toolkit";
import reportReducer from "./slices/reportSlice";
import globalReducer from "./slices/globalSlice";
import authReducer from "./slices/authSlice";

const store = configureStore({
  reducer: {
    report: reportReducer,
    global: globalReducer,
    auth: authReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

import toast from "react-hot-toast";
import { Location } from "react-router-dom";

/**
 * Utility function to retrieve a specific query parameter from the URL.
 * @param location - The current location object from react-router-dom.
 * @param key - The key of the query parameter to retrieve.
 * @returns The value of the query parameter as a string, or null if not found.
 */
export const getQueryParam = (
  location: Location<any>,
  key: string
): string | null => {
  return new URLSearchParams(location.search).get(key);
};

/**
 * Function to remove keys from an object that have empty array values.
 * @param obj - The object from which to remove empty array keys.
 * @returns A new object with empty arrays removed.
 */
export const removeEmptyArrays = (
  obj: Record<string, any>
): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => {
      // Keep only non-empty arrays or non-array values
      return !Array.isArray(value) || value.length > 0;
    })
  );
};

/**
 * Utility function to clear toast notifications after a delay.
 * The toast will be dismissed after 1500 milliseconds (1.5 seconds).
 */
export const clearToast = () => {
  setTimeout(() => {
    toast.dismiss();
  }, 1500);
};

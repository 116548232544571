import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import { useReportActionContext } from "../../context/ReportActionContext";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setCurrentPage,
  setCurrentReportFilterCategory,
  setMenuOpened,
  setReportFilterOpened,
  setReportViewOpened,
} from "../../redux/slices/globalSlice";
import {
  clearFilter,
  updatedSelectedDateType,
  updateSelectedDateRange,
} from "../../redux/slices/reportSlice";
import { getDateType } from "../../utils/date";

import { FavoriteType } from "../../const/enums/FavoriteType";
import { PageType } from "../../const/enums/PageType";
import { ReportFilterCategory } from "../../const/enums/ReportFilterCategory";
import { SelectedDateType } from "../../const/enums/SelectedDateType";

import IconButton from "../../components/Common/IconButton";
import IconToggle from "../../components/Common/IconToggle";
import ArrowBackIcon from "../../components/icons/ArrowBack";
import CalenderIcon from "../../components/icons/CalenderIcon";
import EyeIcon from "../../components/icons/EyeIcon";
import FilterResetIcon from "../../components/icons/FilterResetIcon";
import MenuIcon from "../../components/icons/MenuIcon";
import StarIcon from "../../components/icons/StarIcon";
import StarIconEmpty from "../../components/icons/StarIconEmpty";
import SelectThemeButton from "../../components/Theme/SelectThemeButton";

interface HeaderProps {
  openThemeSelectPopup: () => void;
}

const Header = ({ openThemeSelectPopup }: HeaderProps) => {
  const { onHandleToggleFavorite, onHandleSideMenuBack } =
    useReportActionContext();

  // Get the current location using the useLocation hook
  const location = useLocation();
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const { currentPage, isMenuOpened } = useAppSelector((state) => state.global);
  const {
    currentReport,
    selectedDateType,
    appliedDateType,
    selectedFilters,
    favorites,
    reportFilterViews,
  } = useAppSelector((state) => state.report);

  const [headerTitle, setHeaderTitle] = useState<string>(PageType.Home);

  const [isFavorite, setIsFavorite] = useState<boolean>(false);

  const toggleMenu = () => {
    dispatch(setMenuOpened(!isMenuOpened));
    if (!isMenuOpened) {
      dispatch(setCurrentPage(PageType.SideMenu));
    }
  };

  const handleBack = () => {
    switch (currentPage) {
      case PageType.Report:
        navigator("/");
        break;
      case PageType.SideMenu:
        dispatch(setMenuOpened(false));
        onHandleSideMenuBack(location.pathname);
        break;
      case PageType.ReportFilter:
        dispatch(setCurrentPage(PageType.Report));
        handleFilterBack();
        break;

      case PageType.ReportViewList:
        dispatch(setCurrentPage(PageType.Report));
        dispatch(setReportViewOpened(false));
        break;

      case PageType.ReportViewSave:
        dispatch(setCurrentPage(PageType.ReportFilter));
        dispatch(setReportViewOpened(false));
        break;
      default:
        break;
    }
  };

  const handleFavoriteToggle = () => {
    onHandleToggleFavorite(() => {
      toast.success(
        !isFavorite ? "Added to favorite list" : "Removed from favorite list"
      );
    });
  };

  const handleFilterBack = () => {
    const dateFilter = selectedFilters["Date"];
    if (!dateFilter) return;
    dispatch(updatedSelectedDateType(appliedDateType));
    dispatch(updateSelectedDateRange(dateFilter));
  };

  const handleResetFilter = () => {
    dispatch(clearFilter());
  };

  useEffect(() => {
    const titleMap: Record<string, string> = {
      [PageType.Report]: currentReport?.reportName || "",
      [PageType.Home]: PageType.Home,
      [PageType.ReportFilter]: PageType.ReportFilter,
      [PageType.Competition]: PageType.Competition,
      [PageType.ReportViewList]: PageType.ReportViewList,
      [PageType.ReportViewSave]: PageType.ReportViewSave,
    };
    setHeaderTitle(titleMap[currentPage] || "");
  }, [currentPage, currentReport]);

  useEffect(() => {
    const pageMap: Record<string, PageType> = {
      "/": PageType.Home,
      "/report": PageType.Report,
      "/competitions": PageType.Competition,
    };
    const newPage = pageMap[location.pathname];
    if (newPage) {
      dispatch(setCurrentPage(newPage));
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (currentPage === PageType.Report) {
      const favorite = favorites.find(
        (favorite) =>
          favorite.favoriteType === FavoriteType.Report &&
          favorite.favoriteId === currentReport?.id
      );

      if (favorite) {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  }, [currentPage, currentReport, favorites]);

  const renderMenuIcon = () => {
    if (
      currentPage === PageType.Report ||
      currentPage === PageType.Home ||
      currentPage === PageType.Competition
    ) {
      return (
        <IconButton
          size={24}
          padding={0}
          onClick={toggleMenu}
          className="hover:bg-transparent"
        >
          <MenuIcon className="text-primaryText active:text-primary" />
        </IconButton>
      );
    } else if (
      currentPage === PageType.SideMenu ||
      currentPage === PageType.ReportFilter ||
      currentPage === PageType.ReportViewList ||
      currentPage === PageType.ReportViewSave
    ) {
      return (
        <IconButton
          size={24}
          padding={0}
          onClick={handleBack}
          className="hover:bg-transparent"
        >
          <ArrowBackIcon className="text-primaryText active:text-primary" />
        </IconButton>
      );
    }
  };

  const renderFavoriteIcon = () => {
    return (
      currentPage === PageType.Report && (
        <IconToggle
          value={isFavorite}
          onValueChange={handleFavoriteToggle}
          iconOn={<StarIcon className="text-primary" />}
          iconOff={<StarIconEmpty className="text-primaryText" />}
        ></IconToggle>
      )
    );
  };

  const renderHeaderAction = () => {
    if (currentPage === PageType.ReportFilter) {
      return (
        <div className="flex items-center py-s gap-s">
          <IconButton
            size={28}
            padding={0}
            className="hover:bg-transparent"
            onClick={handleResetFilter}
          >
            <FilterResetIcon className="text-primaryText active:text-primary" />
          </IconButton>
        </div>
      );
    } else if (currentPage === PageType.ReportViewList) {
      return (
        <div className="flex items-center py-s gap-s">
          <IconButton
            size={28}
            padding={0}
            className="hover:bg-transparent relative"
            onClick={() => {}}
          >
            <EyeIcon className="text-primaryText active:text-primary w-[28px] h-[28px]" />

            <div className="absolute right-[0px] bottom-[-4px] w-[12px] h-[12px] bg-primary h-min flex items-center justify-center rounded-xl">
              <span className="text-base-highlight text-center font-primary text-[8px] not-italic font-medium leading-[146%]">
                {reportFilterViews.length}
              </span>
            </div>
          </IconButton>
        </div>
      );
    } else if (currentPage === PageType.Report) {
      if (!selectedFilters["Date"]) return "";

      const dateType =
        selectedDateType === SelectedDateType.Custom
          ? `${selectedFilters["Date"][0]} ~ ${selectedFilters["Date"][1]}`
          : getDateType(selectedFilters["Date"][0], selectedFilters["Date"][1]);

      return (
        <div
          className="flex items-center py-s gap-s text-primaryText active:text-primary cursor-pointer"
          onClick={() => {
            dispatch(setCurrentPage(PageType.ReportFilter));
            dispatch(setCurrentReportFilterCategory(ReportFilterCategory.Date));
            dispatch(setReportFilterOpened(true));
          }}
        >
          <h2 className="font-noto text-sm not-italic font-normal leading-[120%] whitespace-nowrap">
            {dateType}
          </h2>

          <CalenderIcon className="" />
        </div>
      );
    }
  };

  const renderTitle = () => {
    return (
      currentPage !== PageType.SideMenu && (
        <div
          className={`font-noto not-italic whitespace-nowrap overflow-hidden overflow-ellipsis overflow-ellipsis max-w-[100px] ${
            headerTitle === PageType.Home
              ? "text-2xl font-bold leading-[120%]"
              : "text-m font-semibold leading-[120%]"
          }`}
        >
          {headerTitle}
        </div>
      )
    );
  };

  const renderSeparator = () => {
    return (
      currentPage !== PageType.SideMenu &&
      currentPage !== PageType.ReportFilter && (
        <div className="w-px h-[21px] flex-shrink-0 bg-neutral-900"></div>
      )
    );
  };

  const renderThemeButton = () => {
    return (
      (currentPage === PageType.Home || currentPage === PageType.SideMenu) && (
        <SelectThemeButton openSelectTheme={openThemeSelectPopup} />
      )
    );
  };

  return (
    <header className="flex p-[12px] h-[52px] justify-between items-center bg-opposite z-40">
      <div className="flex gap-2 items-center">
        {renderMenuIcon()}
        {renderSeparator()}
        {renderFavoriteIcon()}
        {renderTitle()}
      </div>

      {renderHeaderAction()}
      {renderThemeButton()}
    </header>
  );
};

export default Header;

const ChatSendIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="45" height="45" rx="2" fill="currentColor" />
      <path
        d="M31.2646 21.8231L15.4342 14.1009C15.298 14.0345 15.1485 14 14.9971 14C14.4464 14 14 14.4464 14 14.9971V15.0258C14 15.1596 14.0164 15.2929 14.0489 15.4227L15.5674 21.4968C15.6088 21.6627 15.7491 21.7852 15.9191 21.8041L22.5934 22.5457C22.8249 22.5714 23 22.7671 23 23C23 23.2329 22.8249 23.4286 22.5934 23.4543L15.9191 24.1959C15.7491 24.2148 15.6088 24.3373 15.5674 24.5032L14.0489 30.5773C14.0164 30.7071 14 30.8404 14 30.9742V31.003C14 31.5536 14.4464 32 14.9971 32C15.1485 32 15.298 31.9655 15.4342 31.899L31.2646 24.1769C31.7145 23.9574 32 23.5006 32 23C32 22.4994 31.7145 22.0426 31.2646 21.8231Z"
        fill="#151C1C"
      />
    </svg>
  );
};

export default ChatSendIcon;

export const getTablePhotoLink = (
  row: any,
  photos: any[],
  groupBy: string | string[]
) => {
  return (
    photos
      .find(
        (photo) =>
          photo.recordId === parseInt(row.photo) && photo.category === groupBy
      )
      ?.link.replace("upload/", "upload/w_64,c_fill,g_auto/") || ""
  );
};

export const getChartPhotoLink = (
  row: any,
  photos: any[],
  groupBy: string | string[]
) => {
  return (
    photos
      .find(
        (photo) =>
          photo.recordId === parseInt(row.photo) && photo.category === groupBy
      )
      ?.link.replace("upload/", "upload/w_256,c_fill,g_auto/") || ""
  );
};

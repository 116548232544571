import { handleError } from ".";
import axiosInstance from "./axiosInstance";

/**
 * Fetches trending information from the server.
 * @returns The trending data retrieved from the server.
 */
export const getTrendingInfos = async () => {
  try {
    // Make a GET request to the /trending/ endpoint
    const response = await axiosInstance.get("/trending/");
    return response.data; // Return the data from the response
  } catch (error) {
    handleError(error); // Handle any errors that occur during the request
  }
};

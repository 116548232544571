const UsersIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1682_5582)">
        <path
          d="M12 12C13.933 12 15.5 10.433 15.5 8.5C15.5 6.567 13.933 5 12 5C10.067 5 8.5 6.567 8.5 8.5C8.5 10.433 10.067 12 12 12Z"
          fill="currentColor"
        />
        <path
          d="M17.38 14.43C15.7236 13.5382 13.8807 13.0484 12 13C10.1228 13.0499 8.28349 13.5397 6.63004 14.43C6.13903 14.682 5.72706 15.0647 5.43948 15.5357C5.15189 16.0068 4.99984 16.5481 5.00003 17.1V17.56C4.99872 17.7486 5.03473 17.9357 5.106 18.1103C5.17728 18.285 5.2824 18.4438 5.41532 18.5776C5.54824 18.7115 5.70633 18.8177 5.88048 18.8902C6.05463 18.9627 6.2414 19 6.43003 19H17.57C17.7587 19 17.9454 18.9627 18.1196 18.8902C18.2937 18.8177 18.4518 18.7115 18.5848 18.5776C18.7177 18.4438 18.8228 18.285 18.8941 18.1103C18.9653 17.9357 19.0014 17.7486 19 17.56V17.1C19.0012 16.5491 18.8506 16.0086 18.5649 15.5376C18.2791 15.0666 17.8692 14.6834 17.38 14.43Z"
          fill="currentColor"
        />
        <path
          d="M4.5 10C5.88071 10 7 8.88071 7 7.5C7 6.11929 5.88071 5 4.5 5C3.11929 5 2 6.11929 2 7.5C2 8.88071 3.11929 10 4.5 10Z"
          fill="currentColor"
        />
        <path
          d="M19.5 10C20.8807 10 22 8.88071 22 7.5C22 6.11929 20.8807 5 19.5 5C18.1193 5 17 6.11929 17 7.5C17 8.88071 18.1193 10 19.5 10Z"
          fill="currentColor"
        />
        <path
          d="M22.9201 11.53C21.8865 10.9047 20.7077 10.56 19.5001 10.53C18.2925 10.56 17.1136 10.9047 16.0801 11.53C15.9257 11.6243 15.7845 11.7387 15.6601 11.87C15.6016 11.93 15.5591 12.0038 15.5365 12.0845C15.514 12.1652 15.512 12.2503 15.531 12.332C15.5499 12.4136 15.589 12.4892 15.6448 12.5518C15.7005 12.6143 15.7711 12.6619 15.8501 12.69C16.5363 12.9249 17.2047 13.209 17.8501 13.54C18.4165 13.8372 18.9035 14.2658 19.2701 14.79C19.3166 14.8553 19.3781 14.9085 19.4494 14.9451C19.5208 14.9816 19.5999 15.0005 19.6801 15H22.9501C23.2419 14.982 23.515 14.8505 23.7112 14.6338C23.9074 14.417 24.0111 14.1322 24.0001 13.84V13.52C24.007 13.1233 23.9111 12.7316 23.7219 12.3829C23.5327 12.0342 23.2564 11.7404 22.9201 11.53ZM6.17007 13.54C6.81531 13.2087 7.48373 12.9246 8.17007 12.69C8.24901 12.6619 8.3196 12.6143 8.37536 12.5518C8.43112 12.4892 8.47026 12.4136 8.48918 12.332C8.5081 12.2503 8.50619 12.1652 8.48363 12.0845C8.46107 12.0038 8.41858 11.93 8.36007 11.87C8.23561 11.7387 8.09439 11.6244 7.94007 11.53C6.90653 10.9047 5.72767 10.56 4.52007 10.53C3.31247 10.56 2.13362 10.9047 1.10007 11.53C0.762234 11.7413 0.485074 12.0367 0.295749 12.3873C0.106424 12.7379 0.0114292 13.1317 0.0200726 13.53V13.85C0.011729 14.1405 0.116588 14.4228 0.312507 14.6374C0.508426 14.8519 0.780068 14.982 1.07007 15H4.34007C4.42024 15.0005 4.49934 14.9816 4.5707 14.9451C4.64205 14.9085 4.70357 14.8553 4.75007 14.79C5.11665 14.2658 5.6036 13.8372 6.17007 13.54Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1682_5582">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UsersIcon;

const FitRestoreIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M11.75 9.5H15.5V8.25H11.75V4.5H10.5V5.75V9.5H11.75ZM8.25 11.75V15.5H9.5V14.25V10.5H5.75H4.5V11.75H8.25Z"
        fill="currentColor"
      />
      <path
        d="M16.25 17.5H3.75C3.41848 17.5 3.10054 17.3683 2.86612 17.1339C2.6317 16.8995 2.5 16.5815 2.5 16.25V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5H16.25C16.5815 2.5 16.8995 2.6317 17.1339 2.86612C17.3683 3.10054 17.5 3.41848 17.5 3.75V16.25C17.5 16.5815 17.3683 16.8995 17.1339 17.1339C16.8995 17.3683 16.5815 17.5 16.25 17.5ZM3.75 3.75V16.25H16.2506L16.25 3.75H3.75Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FitRestoreIcon;

import { useMemo, useState } from "react";

import Button from "../../Common/Button";
import IconButton from "../../Common/IconButton";
import MobilePopup from "../../Common/MobilePopup";

import CloseIcon from "../../icons/CloseIcon";
import SmallHomeIcon from "../../icons/SmallHomeIcon";
import SmallHomeIconSelected from "../../icons/SmallHomeSelectedIcon";

import { IReportFilterView } from "../../../interfaces/reportFilterView";
import { useAppSelector } from "../../../redux/hooks";
import { formatDate, getLastWeek } from "../../../utils/date";

const DefaultViewName = "Default";

interface SelectReportViewPopupProps {
  isPopupOpen: boolean;
  closePopup: () => void;
  onConfirm: (filterView: IReportFilterView) => void;
}

interface ReportViewListItemProps {
  isDefault: boolean;
  isSelected: boolean;
  reportViewName: string;
  onSelect: () => void;
}

const ReportViewListItem = ({
  reportViewName,
  isDefault,
  isSelected,
  onSelect,
}: ReportViewListItemProps) => {
  const itemContent = (
    <>
      <div className="flex gap-[15px]">
        {isDefault ? (
          <IconButton
            size={20}
            padding={0}
            className={"hover:bg-transparent w-[20px]"}
            onClick={() => {}}
          >
            <SmallHomeIconSelected className="text-primary" />
          </IconButton>
        ) : (
          <IconButton
            size={20}
            padding={0}
            className={"hover:bg-transparent w-[20px]"}
            onClick={() => {}}
          >
            <SmallHomeIcon className="text-primaryText" />{" "}
          </IconButton>
        )}
        <div className="flex items-center gap-2">
          <div className="text-primaryText font-primary text-sm text-primaryText not-italic font-normal leading-[20px] tracking-[-0.096px] select-none">
            {reportViewName}
          </div>

          {isDefault && (
            <div className="text-primaryText opacity-60 font-primary text-primaryText not-italic font-normal leading-[20px] tracking-[-0.096px]">
              (Default)
            </div>
          )}
        </div>
      </div>
    </>
  );

  return (
    <div
      className={`bg-custom-white-10 active:bg-custom-white-30 flex p-[12px] rounded-sm justify-between cursor-pointer ${
        isSelected ? "bg-custom-white-30" : ""
      }`}
      onClick={onSelect}
    >
      {itemContent}
    </div>
  );
};

const SelectReportViewPopup = ({
  isPopupOpen,
  closePopup,
  onConfirm,
}: SelectReportViewPopupProps) => {
  const { reportFilterViews, reportDataInfo } = useAppSelector(
    (state) => state.report
  );

  const [selectedViewIndex, setSelectedViewIndex] = useState(-1);

  const isDefaultViewSelected = useMemo(() => {
    return reportFilterViews.filter((view) => view.selected).length === 0;
  }, [reportFilterViews]);

  return (
    <MobilePopup
      isOpen={isPopupOpen}
      onClose={closePopup}
      backBg="bg-overlay"
      popupBg="bg-custom-deepCharcoal"
      popupBorder="ring-custom-custom-white-30"
      //   position={MobilePopupPosition.Center}
    >
      <div className="flex flex-col gap-m">
        <div className="flex flex-col items-center justify-between pt-4 pb-6 px-4 gap-6">
          <div className="flex flex-col w-full gap-1">
            <div className="flex w-full justify-between items-center py-[5px]">
              <h2 className="text-primaryText font-primary text-[18px] not-italic font-semibold leading-[120%]">
                Select View
              </h2>

              <IconButton onClick={closePopup} padding={0}>
                <CloseIcon className="text-primaryText" />
              </IconButton>
            </div>

            <h3 className="text-custom-white-b3 font-primary text-xs not-italic font-normal leading-[120%]">
              Create sheet on home screen with selected report view.
            </h3>
          </div>

          <div className="flex flex-col w-full gap-1">
            <ReportViewListItem
              reportViewName={DefaultViewName}
              isSelected={-1 === selectedViewIndex}
              isDefault={isDefaultViewSelected}
              onSelect={() => {
                setSelectedViewIndex(-1);
              }}
            />

            {reportFilterViews.map((view, index) => {
              return (
                <ReportViewListItem
                  key={index}
                  reportViewName={view.viewName}
                  isSelected={index === selectedViewIndex}
                  isDefault={view.selected}
                  onSelect={() => {
                    setSelectedViewIndex(index);
                  }}
                />
              );
            })}
          </div>

          <div className="flex gap-4 w-full">
            <Button
              bgColor="bg-primary"
              activeColor="active:bg-neutral-200"
              className="py-sm px-m max-h-[40px] w-[100%] rounded-sm"
              onClick={() => {
                if (selectedViewIndex === -1) {
                  // Default view selected
                  onConfirm({
                    createdAt: 0,
                    fields: JSON.stringify(
                      reportDataInfo.tableColumns.map((c) => {
                        return {
                          field: c.field,
                          fieldVisible: true,
                        };
                      })
                    ),
                    id: 0,
                    selected: true,
                    userId: 0,
                    viewName: "Default",
                    filters: JSON.stringify({
                      Date: [
                        formatDate(getLastWeek().from),
                        formatDate(getLastWeek().to),
                      ],
                    }),
                  } as IReportFilterView);
                } else {
                  onConfirm(reportFilterViews[selectedViewIndex]);
                }
              }}
            >
              <span className="font-primary text-base-highlight text-sm not-italic font-medium leading-[120%]">
                Apply
              </span>
            </Button>

            <Button
              bgColor="bg-secondary"
              activeColor="active:bg-neutral-200"
              className="py-[6px] px-[10px] w-[100%] max-h-[40px]"
              onClick={() => {
                setSelectedViewIndex(-1);
                closePopup();
              }}
            >
              <span className="font-primary text-custom-buttonText text-sm not-italic font-medium leading-[120%]">
                Cancel
              </span>
            </Button>
          </div>
        </div>
      </div>
    </MobilePopup>
  );
};

export default SelectReportViewPopup;

import React, { useEffect, useRef, useState } from "react";
import { HexColorPicker } from "react-colorful"; // Import the color picker
import toast from "react-hot-toast";

import { applyThemeColors } from "../../utils/theme";

import Button from "../Common/Button";
import IconButton from "../Common/IconButton";
import MobilePopup, { MobilePopupPosition } from "../Common/MobilePopup";

import DEFAULT_THEME_COLORS from "../../const/theme";
import { ITheme } from "../../interfaces/theme";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  editThemeAction,
  updateCurrentTheme,
} from "../../redux/slices/globalSlice";

import CloseIcon from "../icons/CloseIcon";

interface EditThemePopupProps {
  isPopupOpen: boolean;
  closePopup: () => void;
  editTheme: ITheme | undefined;
}

const EditThemePopup = ({
  isPopupOpen,
  closePopup,
  editTheme,
}: EditThemePopupProps) => {
  const dispatch = useAppDispatch();
  const { currentTheme, themes } = useAppSelector((state) => state.global);
  const [colors, setColors] = useState<string[]>(
    editTheme ? editTheme.themeColors : []
  );
  const [themeName, setThemeName] = useState(
    editTheme ? editTheme.themeName : ""
  );
  const [showPicker, setShowPicker] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0); // Track which color is active
  const pickerRef = useRef<HTMLDivElement>(null); // Ref for the color picker

  const handleColorChange = (color: string) => {
    if (activeIndex !== null) {
      const newColors = [...colors];
      newColors[activeIndex] = color; // Update the active color
      setColors(newColors);

      applyThemeColors(newColors);
    }
  };

  const handleHexChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newColors = [...colors];
    newColors[index] = event.target.value; // Update hex value
    setColors(newColors);
  };

  const handleOnApply = () => {
    if (themeName.length === 0) {
      toast.error("Theme name cannot be empty.");
      return;
    }

    if (
      themes.find(
        (theme) => theme.themeName === themeName && theme.id !== editTheme?.id
      )
    ) {
      toast.error(
        "The theme name you entered already exists. Please choose a different name."
      );
      return;
    }

    const themeColorString = colors.join(",");
    if (!editTheme) return;

    toast.promise(
      dispatch(
        editThemeAction({
          themeName,
          themeId: editTheme.id,
          themeColors: themeColorString,
        })
      ).unwrap(),
      {
        loading: "Saving...",
        success: () => {
          dispatch(updateCurrentTheme(themeName));
          return `The ${themeName} has been successfully saved.`;
        },
        error: "An error occurred while attempting to save the edited theme.",
      }
    );

    closePopup();
  };

  const handleOnCancel = () => {
    if (editTheme) {
      setColors(editTheme.themeColors);
      applyThemeColors(editTheme.themeColors);
    }
    closePopup();
  };

  // Hide color picker when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        pickerRef.current &&
        !pickerRef.current.contains(event.target as Node)
      ) {
        setShowPicker(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (Object.keys(DEFAULT_THEME_COLORS).indexOf(currentTheme) === -1) {
      const customTheme = themes.find(
        (theme) => theme.themeName === currentTheme
      );

      if (customTheme) {
        setColors(customTheme.themeColors);
      }
    } else {
      setColors(
        JSON.parse(
          JSON.stringify(DEFAULT_THEME_COLORS[currentTheme])
        ) as string[]
      );
    }
  }, [currentTheme, themes]);

  useEffect(() => {
    setThemeName(editTheme ? editTheme.themeName : "");
    setColors(editTheme ? editTheme.themeColors : []);
  }, [editTheme]);

  return (
    <MobilePopup
      isOpen={isPopupOpen}
      onClose={closePopup}
      backBg="bg-overlay"
      popupBg="bg-custom-deepCharcoal"
      popupBorder="ring-custom-custom-white-30"
      position={MobilePopupPosition.Bottom}
    >
      <div className="flex flex-col gap-m">
        <div className="flex flex-col items-center justify-between pt-4 pb-6 px-4 gap-6">
          <div className="flex flex-col w-full gap-1">
            <div className="flex w-full justify-between items-center py-[5px]">
              <h2 className="text-primaryText font-primary text-[18px] not-italic font-semibold leading-[120%]">
                Edit theme
              </h2>

              <IconButton onClick={closePopup} padding={0}>
                <CloseIcon className="text-primaryText" />
              </IconButton>
            </div>

            <h3 className="text-primaryText font-primary text-xs not-italic font-normal leading-[120%]">
              Edit custom theme by selecting specific colors.
            </h3>
          </div>

          <div className="w-full">
            <h3 className="text-primaryText font-primary text-xs not-italic font-normal leading-[120%]">
              Name
            </h3>
            <input
              type="text"
              placeholder="Input theme name"
              value={themeName}
              onChange={(event) => {
                setThemeName(event.target.value);
              }}
              className="rounded border border-custom-white-30 bg-transparent transition-colors p-2 px-2 mt-2 w-full outline-none focus:border-custom-white-80"
            />
          </div>

          <div className="grid grid-cols-2 w-full gap-y-4 gap-x-2 pb-2">
            {colors.map((color, index: number) => (
              <div key={index} className="flex items-center gap-2">
                <label className="text-primaryText">{`Color ${index}`}</label>
                <input
                  type="text"
                  value={color}
                  onChange={(event) => handleHexChange(index, event)}
                  className="rounded border border-custom-white-30 bg-transparent transition-colors p-1 px-2 w-[68px] md:w-24 outline-none focus:border-custom-white-80"
                />
                <div
                  className="w-6 h-6 cursor-pointer border-2 border-transparent hover:border-white rounded-xs"
                  style={{ backgroundColor: color }}
                  onClick={() => {
                    setActiveIndex(index); // Set the active index
                    setShowPicker(true); // Show the color picker
                  }}
                ></div>
              </div>
            ))}
          </div>

          {showPicker && activeIndex !== null && (
            <div className="fixed inset-0 flex items-center justify-center z-20">
              <div
                className="absolute inset-0 bg-black opacity-50 rounded-sm"
                onClick={() => setShowPicker(false)}
              ></div>
              <div className="relative z-30">
                <HexColorPicker
                  color={colors[activeIndex]} // Set the color of the active index
                  onChange={(color) => handleColorChange(color)} // Update color on change
                />
              </div>
            </div>
          )}

          <div className="flex gap-4 w-full">
            <Button
              bgColor="bg-primary"
              activeColor="active:bg-neutral-200"
              className="py-sm px-m max-h-[40px] w-[100%] rounded-sm"
              onClick={handleOnApply}
            >
              <span className="font-primary text-base-highlight text-sm not-italic font-medium leading-[120%]">
                Save
              </span>
            </Button>

            <Button
              bgColor="bg-secondary"
              activeColor="active:bg-neutral-200"
              className="py-[6px] px-[10px] w-[100%] max-h-[40px]"
              onClick={handleOnCancel}
            >
              <span className="font-primary text-custom-buttonText text-sm not-italic font-medium leading-[120%]">
                Cancel
              </span>
            </Button>
          </div>
        </div>
      </div>
    </MobilePopup>
  );
};

export default EditThemePopup;

const FitScreenIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      className={className}
    >
      <path
        d="M13.9805 10H15.2305V5H10.2305V6.25H13.9805V10ZM5.23047 15H10.2305V13.75H6.48047V10H5.23047V15Z"
        fill="currentColor"
      />
      <path
        d="M16.4805 17.5H3.98047C3.64895 17.5 3.33101 17.3683 3.09659 17.1339C2.86216 16.8995 2.73047 16.5815 2.73047 16.25V3.75C2.73047 3.41848 2.86216 3.10054 3.09659 2.86612C3.33101 2.6317 3.64895 2.5 3.98047 2.5H16.4805C16.812 2.5 17.1299 2.6317 17.3644 2.86612C17.5988 3.10054 17.7305 3.41848 17.7305 3.75V16.25C17.7305 16.5815 17.5988 16.8995 17.3644 17.1339C17.1299 17.3683 16.812 17.5 16.4805 17.5ZM3.98047 3.75V16.25H16.4811L16.4805 3.75H3.98047Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default FitScreenIcon;

const ThemeIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M7.08 11.25C7.19839 10.4514 7.51465 9.69513 8 9.05L4.43 5.49C3.0179 7.09637 2.16519 9.11759 2 11.25H7.08ZM9.05 8C9.69613 7.51822 10.4523 7.20543 11.25 7.09V2C9.11759 2.16519 7.09637 3.0179 5.49 4.43L9.05 8ZM12.75 2V7C13.5737 7.1338 14.3487 7.47828 15 8L18.56 4.44C16.9416 3.0143 14.9011 2.15736 12.75 2ZM8 15C7.51822 14.3539 7.20543 13.5977 7.09 12.8H2C2.15394 14.9277 2.99239 16.9484 4.39 18.56L8 15ZM11.25 16.92C10.4514 16.8016 9.69513 16.4853 9.05 16L5.49 19.57C7.09637 20.9821 9.11759 21.8348 11.25 22V16.92ZM16 9.05C16.4818 9.69613 16.7946 10.4523 16.91 11.25H21.91C21.7561 9.12233 20.9176 7.10163 19.52 5.49L16 9.05ZM15 16C14.3539 16.4818 13.5977 16.7946 12.8 16.91V21.91C14.9277 21.7561 16.9484 20.9176 18.56 19.52L15 16ZM16.92 12.75C16.8097 13.5661 16.4931 14.3405 16 15L19.56 18.56C20.9857 16.9416 21.8426 14.9011 22 12.75H16.92Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ThemeIcon;

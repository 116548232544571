import React from "react";
import Button from "../../Common/Button";

interface SaveViewProps {
  viewName: string;
  onViewNameChange: React.Dispatch<React.SetStateAction<string>>;
  onHandleSaveView: () => void;
}

const SaveView = ({
  viewName,
  onViewNameChange,
  onHandleSaveView,
}: SaveViewProps) => {
  return (
    <div className="flex flex-col pt-[72px]">
      <span className="text-base-highlight text-center font-primary text-xl not-italic font-semibold leading-[120%]">
        SAVE THIS VIEW
      </span>

      <div className="mt-[22px] rounded-sm bg-neutral-900 shadow-pen-umbra-1 flex w-full h-[50px] items-center gap-[8px] flex-shrink-0">
        <input
          type="text"
          value={viewName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onViewNameChange(e.target.value);
          }}
          className="outline-none text-[#FFF] pl-[20px] pr-[10px] bg-transparent w-full font-primary text-primaryText not-italic font-normal leading-[20px] tracking-[-0.096px]"
          placeholder="Enter view name"
        />
      </div>

      <Button
        bgColor="bg-primary"
        activeColor="active:bg-neutral-200"
        className="w-[100%] py-sm px-[14px] max-h-[50px] rounded-sm mt-[16px]"
        onClick={onHandleSaveView}
      >
        <span
          className={`font-primary text-m not-italic font-medium leading-[120%] text-base-highlight`}
        >
          Save
        </span>
      </Button>
    </div>
  );
};

export default SaveView;

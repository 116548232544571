const FullScreenIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M18 8V2M18 2H12M18 2L12 8M12 18H18M18 18V12M18 18L12 12M2 8V2H8M8 8L2.38086 2.40181M8 18H2M2 18V12M2 18L8 12"
        stroke="currentColor"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FullScreenIcon;

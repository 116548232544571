import { useAppDispatch } from "../../redux/hooks";
import {
  applyFilter,
  updateReportFilter,
} from "../../redux/slices/reportSlice";
import IconButton from "../Common/IconButton";
import ArrowLeftBack from "../icons/ArrowBack";

interface BreadCrumbItem {
  label: string;
  key: string;
}

interface ReportBreadCrumbProps {
  items: BreadCrumbItem[];
  maxWidth: number; // Maximum width for the breadcrumb
}

const ReportBreadCrumb = ({ items, maxWidth }: ReportBreadCrumbProps) => {
  const dispatch = useAppDispatch();
  const truncateItems = () => {
    const totalWidth = items.length * 100; // Assuming each item has a width of 100px
    if (totalWidth <= maxWidth) {
      return items;
    }

    // Calculate how many items can be displayed
    const visibleCount = Math.floor(maxWidth / 100);
    const startItemsCount = Math.max(items.length - visibleCount, 0); // Keep last item visible
    return [
      { label: "...", key: "" }, // Add ellipsis
      ...items.slice(startItemsCount), // Show starting items
    ];
  };

  const displayedItems = truncateItems();

  const handleOnClicked = (item: BreadCrumbItem) => {
    const itemIndex = items.indexOf(item);
    if (itemIndex === items.length - 1) {
      return;
    } else if (itemIndex === 0) {
      dispatch(
        updateReportFilter({
          filterName: "GroupBy",
          selectedOption: item.key,
        })
      );

      items.forEach((item, index) => {
        if (index !== 0) {
          dispatch(
            updateReportFilter({
              filterName: item.key,
              selectedOption: [],
            })
          );
        }
      });
    } else {
      const nextItem = items[itemIndex + 1];
      dispatch(
        updateReportFilter({
          filterName: "GroupBy",
          selectedOption: nextItem.key,
        })
      );

      items.forEach((item, index) => {
        if (index > itemIndex) {
          dispatch(
            updateReportFilter({
              filterName: item.key,
              selectedOption: [],
            })
          );
        }
      });
    }

    setTimeout(() => {
      dispatch(applyFilter());
    }, 100);
  };

  const handleBackClicked = () => {
    handleOnClicked(items[items.length - 2]);
  };

  return (
    <div
      className="flex items-center mt-[10px] pl-3 gap-2"
      style={{
        maxWidth: `${maxWidth}px`,
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      <IconButton size={20} padding={0} onClick={handleBackClicked}>
        <ArrowLeftBack className="w-5 h-5 text-primaryText active:text-primary" />
      </IconButton>

      <div className="flex items-center">
        {displayedItems.map((item, index) => (
          <div
            key={index}
            className="flex items-center"
            style={{ maxWidth: "100px" }}
          >
            <div
              className="font-primary text-primaryText text-sm not-italic font-semibold overflow-hidden text-ellipsis cursor-pointer active:text-primary select-none"
              onClick={
                item.key
                  ? () => {
                      handleOnClicked(item);
                    }
                  : () => {}
              }
            >
              {item.label}
            </div>

            {index < displayedItems.length - 1 && (
              <span className="mx-1 font-primary text-primaryText opacity-60 text-sm not-italic font-semibold text-">
                &gt;
              </span> // Separator
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportBreadCrumb;

const CompetitionIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.7012 21C17.6717 21 21.7012 16.9706 21.7012 12C21.7012 7.02944 17.6717 3 12.7012 3C7.73061 3 3.70117 7.02944 3.70117 12C3.70117 16.9706 7.73061 21 12.7012 21Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0082 9.739L15.7012 9L14.9622 12.693C14.8848 13.0801 14.6946 13.4356 14.4156 13.7148C14.1366 13.994 13.7812 14.1844 13.3942 14.262L9.70117 15L10.4402 11.307C10.5177 10.9201 10.708 10.5648 10.987 10.2858C11.266 10.0068 11.6213 9.81654 12.0082 9.739Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompetitionIcon;

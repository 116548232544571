const HelpIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      className={className}
    >
      <path
        d="M6.81445 6.73312C6.81445 2.75563 13.0645 2.75563 13.0645 6.73312C13.0645 9.57396 10.2236 9.00562 10.2236 12.4148M10.2311 15.8415L10.2395 15.8323"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HelpIcon;

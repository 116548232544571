const UserIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12 12C13.933 12 15.5 10.433 15.5 8.5C15.5 6.567 13.933 5 12 5C10.067 5 8.5 6.567 8.5 8.5C8.5 10.433 10.067 12 12 12Z"
        fill="currentColor"
      />
      <path
        d="M17.38 14.43C15.7236 13.5382 13.8807 13.0484 12 13C10.1228 13.0499 8.28349 13.5397 6.63004 14.43C6.13903 14.682 5.72706 15.0647 5.43948 15.5357C5.15189 16.0068 4.99984 16.5481 5.00003 17.1V17.56C4.99872 17.7486 5.03473 17.9357 5.106 18.1103C5.17728 18.285 5.2824 18.4438 5.41532 18.5776C5.54824 18.7115 5.70633 18.8177 5.88048 18.8902C6.05463 18.9627 6.2414 19 6.43003 19H17.57C17.7587 19 17.9454 18.9627 18.1196 18.8902C18.2937 18.8177 18.4518 18.7115 18.5848 18.5776C18.7177 18.4438 18.8228 18.285 18.8941 18.1103C18.9653 17.9357 19.0014 17.7486 19 17.56V17.1C19.0012 16.5491 18.8506 16.0086 18.5649 15.5376C18.2791 15.0666 17.8692 14.6834 17.38 14.43Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UserIcon;

const StarIconEmpty = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
      className={className}
    >
      <path
        d="M12.0009 3.39L14.0709 7.575L14.4159 8.325L15.1659 8.4375L19.7859 9.105L16.5009 12.33L15.9384 12.8775L16.0734 13.6275L16.8609 18.225L12.7284 16.0575L12.0009 15.75L11.3034 16.1175L7.17094 18.255L7.92094 13.6575L8.05594 12.9075L7.50094 12.33L4.18594 9.0675L8.80594 8.4L9.55594 8.2875L9.90094 7.5375L12.0009 3.39ZM12.0009 0L8.58844 6.915L0.960938 8.0175L6.48094 13.4025L5.17594 21L12.0009 17.415L18.8259 21L17.5209 13.4025L23.0409 8.025L15.4134 6.915L12.0009 0Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default StarIconEmpty;

import { DateDirection } from "../../../const/enums/DateDirection";
import { SelectedDateType } from "../../../const/enums/SelectedDateType";
import { IDateRange } from "../../../interfaces/dateRange";
import {
  shiftDay,
  shiftMonth,
  shiftWeek,
  shiftYear,
} from "../../../utils/date";

export function getShiftedDate(
  currentDateRange: IDateRange,
  appliedDateType: SelectedDateType,
  direction: DateDirection
) {
  const offset = direction === DateDirection.Last ? -1 : 1;
  if (
    appliedDateType === SelectedDateType.LastMonth ||
    appliedDateType === SelectedDateType.MTD
  ) {
    // Shift the from and to dates by one month
    const shiftedDateFilterRange: IDateRange = {
      from: shiftMonth(currentDateRange.from, offset),
      to: shiftMonth(currentDateRange.to, offset),
    };

    return shiftedDateFilterRange;
  } else if (
    appliedDateType === SelectedDateType.WTD ||
    appliedDateType === SelectedDateType.LastWeek
  ) {
    // Shift the from and to dates by one week
    const shiftedDateFilterRange: IDateRange = {
      from: shiftWeek(currentDateRange.from, offset),
      to: shiftWeek(currentDateRange.to, offset),
    };

    return shiftedDateFilterRange;
  } else if (
    appliedDateType === SelectedDateType.Today ||
    appliedDateType === SelectedDateType.Yesterday
  ) {
    // Shift the from and to dates by one week
    const shiftedDateFilterRange: IDateRange = {
      from: shiftDay(currentDateRange.from, offset),
      to: shiftDay(currentDateRange.to, offset),
    };

    return shiftedDateFilterRange;
  } else if (appliedDateType === SelectedDateType.YTD) {
    // Shift the from and to dates by one week
    const shiftedDateFilterRange: IDateRange = {
      from: shiftYear(currentDateRange.from, offset),
      to: shiftYear(currentDateRange.to, offset),
    };

    return shiftedDateFilterRange;
  }
  return currentDateRange;
}

const ArrowLeftBack = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19 11.5H7.14L10.77 7.14C10.9397 6.93578 11.0214 6.6725 10.997 6.40808C10.9726 6.14365 10.8442 5.89974 10.64 5.73C10.4358 5.56026 10.1725 5.4786 9.90808 5.50298C9.64365 5.52736 9.39974 5.65578 9.23 5.86L4.23 11.86C4.19636 11.9077 4.16628 11.9579 4.14 12.01C4.14 12.06 4.14 12.09 4.07 12.14C4.02467 12.2547 4.00094 12.3767 4 12.5C4.00094 12.6233 4.02467 12.7453 4.07 12.86C4.07 12.91 4.07 12.94 4.14 12.99C4.16628 13.0421 4.19636 13.0923 4.23 13.14L9.23 19.14C9.32402 19.2529 9.44176 19.3437 9.57485 19.4059C9.70793 19.4681 9.85309 19.5002 10 19.5C10.2337 19.5005 10.4601 19.4191 10.64 19.27C10.7413 19.1861 10.825 19.083 10.8863 18.9666C10.9477 18.8503 10.9855 18.7229 10.9975 18.592C11.0096 18.461 10.9957 18.3289 10.9567 18.2033C10.9176 18.0777 10.8542 17.9611 10.77 17.86L7.14 13.5H19C19.2652 13.5 19.5196 13.3946 19.7071 13.2071C19.8946 13.0196 20 12.7652 20 12.5C20 12.2348 19.8946 11.9804 19.7071 11.7929C19.5196 11.6054 19.2652 11.5 19 11.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ArrowLeftBack;

import { useNavigate } from "react-router-dom";

import { ReportSheetType } from "../../const/enums/ReportSheetType";
import { TimeUnit } from "../../const/enums/TimeUnit";
import { IHomeSheet } from "../../interfaces/homeSheet";
import { IPhoto } from "../../interfaces/photo";
import { ReportDataInfo } from "../../interfaces/reportDataInfo";

import Button from "../Common/Button";
import Chart from "../Common/Chart";
import ComparisonChart from "../Common/Chart/ComparisonChart";
import DateChart from "../Common/Chart/DateChart";
import Table from "../Common/Table";

import UserIcon from "../icons/UserIcon";
import UsersIcon from "../icons/UsersIcon";
import useChartData from "../Report/hooks/useChartData";
import useTableData from "../Report/hooks/useTableData";

interface ReportSheetSectionProps {
  sheets: IHomeSheet[];
  sheetsData: { name: string; data: any[] };
  sheetsInfo: ReportDataInfo[];
  isLoading: boolean;
  photos: IPhoto[];
  openPopup: () => void;
}

const ReportSheet = ({
  reportSheet,
  sheetInfo,
  reportData,
  photos,
  isLoading,
}: {
  reportSheet: IHomeSheet;
  sheetInfo: ReportDataInfo;
  reportData: any[];
  photos: IPhoto[];
  isLoading: boolean;
}) => {
  const reportFilters = JSON.parse(reportSheet.reportFilters);
  const reportFields = JSON.parse(reportSheet.reportFields);
  const groupBy = reportFilters["GroupBy"] || [sheetInfo.filterDefaultGroupBy];

  const groupByDate =
    typeof reportFilters["GroupByDate"] === "string"
      ? reportFilters["GroupByDate"]
      : TimeUnit.Days;

  const { tableHeader, tableSubHeader, tableContent } = useTableData(
    reportData,
    photos,
    groupBy,
    reportFields,
    sheetInfo.tableColumns
  );

  const { chartData } = useChartData(
    reportData,
    photos,
    groupBy,
    groupByDate,
    reportSheet
  );

  if (reportSheet.sheetType === ReportSheetType.Table) {
    return (
      <Table
        isFitScreen={false}
        isFullScreen={false}
        loading={isLoading}
        isMinHeight={true}
        tableHeader={tableHeader}
        tableSubHeader={tableSubHeader}
        tableContent={tableContent}
        className={"rounded-sm"}
        handleOnClickEntity={() => {}}
        defaultIcon={
          groupBy === "Rep" ? (
            <UserIcon
              className={"text-primaryText w-[28px] h-[28px] min-w-[28px]"}
            />
          ) : (
            <UsersIcon
              className={"text-primaryText w-[28px] h-[28px] min-w-[28px]"}
            />
          )
        }
      />
    );
  } else if (reportSheet.sheetType === ReportSheetType.Chart) {
    return (
      <Chart
        isFullScreen={false}
        isFitScreen={false}
        loading={isLoading}
        className={"rounded-sm"}
        chartData={chartData}
      />
    );
  } else if (reportSheet.sheetType === ReportSheetType.ChartByDate) {
    return (
      <DateChart
        isFullScreen={false}
        isFitScreen={false}
        loading={isLoading}
        className={"rounded-sm"}
        chartData={chartData}
        groupByDate={groupByDate}
      />
    );
  } else if (reportSheet.sheetType === ReportSheetType.ComparisonChart) {
    const years: number[] = [];
    reportData.forEach((row) => {
      if (!row.group_field) return;
      const date = new Date(row.group_field);
      const year = date.getFullYear();
      years.push(year);
    });

    return (
      <>
        <div className="flex bg-custom-deepCharcoal pt-[10px]">
          <div className="flex items-center">
            <div className="w-[11px] h-[11px] mx-[8px] bg-chart-gradient-1 rounded-[50%]"></div>
            <div className="text-[#FFF] font-primary text-xs not-italic font-normal">
              {chartData ? Math.max(...years) - 1 : ""}
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-[11px] h-[11px] mx-[8px] bg-chart-gradient-2 rounded-[50%]"></div>
            <div className="text-[#FFF] font-primary text-xs not-italic font-normal">
              {chartData ? Math.max(...years) : ""}
            </div>
          </div>
        </div>
        <ComparisonChart
          isFullScreen={false}
          isFitScreen={false}
          loading={false}
          className={"rounded-sm"}
          chartData={chartData}
          groupByDate={groupByDate}
        />
      </>
    );
  }

  return <></>;
};

const ReportSheetSection = ({
  sheets,
  sheetsData: { data: reportData },
  sheetsInfo,
  photos,
  isLoading,
  openPopup,
}: ReportSheetSectionProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-4">
      {!isLoading &&
      reportData.length === sheetsInfo.length &&
      reportData.length === sheets.length ? (
        sheets.map((sheet, index) => {
          return (
            <div
              className="flex flex-col items-start gap-[15px] mt-m items-center"
              key={index}
            >
              <div className="flex flex-col justify-between	 items-center gap-[10px] self-stretch mt-[6px]">
                <div className="flex justify-between items-center w-full">
                  <h2 className="text-primaryText text-base not-italic font-bold leading-[120%] w-full">
                    {sheet.reportName} - {sheet.reportFilterViewName}
                  </h2>

                  <button
                    className="flex justify-center items-center px-m py-sm"
                    onClick={openPopup}
                  >
                    <span className="text-primary font-primary text-sm not-italic font-semibold leading-[120%]">
                      Edit
                    </span>
                  </button>
                </div>

                <ReportSheet
                  sheetInfo={sheetsInfo[index]}
                  reportSheet={sheet}
                  reportData={reportData[index]}
                  photos={photos}
                  isLoading={isLoading}
                />
              </div>

              <Button
                activeColor="active:bg-primary-400"
                onClick={() => {
                  navigate(
                    `/report?alias=${sheet.reportAlias}&view=${sheet.reportViewName}`
                  );
                }}
              >
                <span className="font-primary text-sm not-italic font-semibold leading-[120%]">
                  View Full Report
                </span>
              </Button>
            </div>
          );
        })
      ) : (
        <div className="m-auto flex items-center justify-between mt-10">
          <div className="w-8 h-8 border-2 border-t-primary border-gray-300 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
};

export default ReportSheetSection;

export enum SelectedDateType {
  Custom = "Custom",
  LastNext = "Last/Next",
  Today = "Today",
  Yesterday = "Yesterday",
  FullSeason = "Full Season",
  YTD = "YTD",
  LastWeek = "Last Week",
  WTD = "WTD",
  LastMonth = "Last Month",
  MTD = "MTD",
}

import { useEffect, useState } from "react";

import Button from "../../Common/Button";
import AdditionalFilters from "./AdditionalFilters";
import ColumnSelections from "./ColumnSelections";
import DateFilters from "./DateFilters";
import HierarchyFilters from "./HierarchyFilters";

import { ReportFilterCategory } from "../../../const/enums/ReportFilterCategory";
import { useReportActionContext } from "../../../context/ReportActionContext";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { PageType } from "../../../const/enums/PageType";
import {
  setCurrentPage,
  setReportViewOpened,
} from "../../../redux/slices/globalSlice";
import EyeIcon from "../../icons/EyeIcon";

interface ReportFilterProps {
  isPopupOpen: boolean;
}

export const Label = ({ label }: { label: string }) => {
  return (
    <span
      className={`text-primaryText font-primary text-sm not-italic font-semibold leading-[120%] ${"text-base-highlight"}`}
    >
      {label}
    </span>
  );
};

export const PanelHeader = ({ header }: { header: string }) => {
  return (
    <div className="text-primaryText py-[16px] text-center font-primary not-italic font-semibold leading-[120%] border-b-[1px] border-custom-white-10 ">
      {header}
    </div>
  );
};

const ReportFilter = ({ isPopupOpen }: ReportFilterProps) => {
  const dispatch = useAppDispatch();
  const { onHandleApplyFilters } = useReportActionContext();

  const { currentReportFilterCategory } = useAppSelector(
    (state) => state.global
  );

  const [currentFilterType, setCurrentFilterType] = useState(
    currentReportFilterCategory
  );

  const onHandleSaveView = () => {
    dispatch(setCurrentPage(PageType.ReportViewSave));
    dispatch(setReportViewOpened(true));
  };

  useEffect(() => {
    setCurrentFilterType(currentReportFilterCategory);
  }, [currentReportFilterCategory]);

  const renderReportCategories = () => {
    return Object.values(ReportFilterCategory).map((category) => (
      <Button
        key={category}
        bgColor={
          currentFilterType === category
            ? "bg-primary"
            : "bg-custom-deepCharcoal"
        }
        activeColor="active:bg-neutral-200"
        className="py-sm px-[14px] max-h-[40px] rounded-sm"
        onClick={() => setCurrentFilterType(category)}
      >
        <span
          className={`font-primary text-m not-italic font-medium leading-[120%] ${
            currentFilterType === category
              ? "text-base-highlight"
              : "text-custom-grey-a3"
          }`}
        >
          {category}
        </span>
      </Button>
    ));
  };

  const renderFilters = () => {
    switch (currentFilterType) {
      case ReportFilterCategory.Date:
        return <DateFilters />;
      case ReportFilterCategory.Hierarchy:
        return <HierarchyFilters />;
      case ReportFilterCategory.Additional:
        return <AdditionalFilters />;
      case ReportFilterCategory.Columns:
        return <ColumnSelections />;
    }
  };

  return (
    <div
      className={`fixed top-[0] left-[0px] w-full h-full  transition duration-300 ease-in-out z-30 max-h-fit-height ${
        isPopupOpen
          ? "opacity-100 scale-100 translate-y-[0%]"
          : "opacity-0 scale-95 translate-y-[100%] pointer-events-none"
      }`}
    >
      <div className="h-[52px] pointer-events-none"></div>
      <div className="flex flex-col gap-[17px] flex-grow-1 h-full z-50 overflow-y-scroll px-[12px] pt-[12px] pb-[12px] bg-opposite">
        <div className="flex gap-[16px] min-h-[50px] pb-2 w-full overflow-x-scroll">
          {renderReportCategories()}
        </div>

        {renderFilters()}

        <div className="flex gap-[9px] pb-[16px]">
          <Button
            bgColor="bg-custom-deepCharcoal"
            activeColor="active:bg-custom-deepCharcoal10"
            className="w-[100%] py-sm px-[14px] max-h-[40px] rounded-sm mt-[16px] border-[1px] border-custom-white-30"
            onClick={onHandleSaveView}
          >
            <EyeIcon className="active:text-primary transition-colors text-primaryText" />

            <span
              className={`font-primary text-m not-italic font-medium leading-[120%] text-primaryText`}
            >
              Save View
            </span>
          </Button>

          <Button
            bgColor="bg-primary"
            activeColor="active:bg-neutral-200"
            className="w-[100%] py-sm px-[14px] max-h-[40px] rounded-sm mt-[16px]"
            onClick={onHandleApplyFilters}
          >
            <span
              className={`font-primary text-m not-italic font-medium leading-[120%] text-base-highlight`}
            >
              Apply
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReportFilter;

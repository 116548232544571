const CheckIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="check">
        <path
          id="Icon"
          d="M11.5827 3.5625L5.2806 9.86458L2.41602 7"
          stroke="currentColor"
          strokeWidth="1.48958"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default CheckIcon;

const PlusIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={className}
    >
      <path
        d="M13.994 23.867C14.5685 23.867 15.049 23.41 15.049 22.8475V15.055H22.619C23.1815 15.055 23.674 14.575 23.674 14.0005C23.674 13.4265 23.1815 12.9575 22.619 12.9575H15.049V5.1525C15.049 4.5905 14.5685 4.1335 13.994 4.1335C13.42 4.1335 12.951 4.5905 12.951 5.153V12.958H5.38099C4.81849 12.958 4.32599 13.4265 4.32599 14.0005C4.32599 14.5745 4.81849 15.0555 5.38099 15.0555H12.951V22.848C12.951 23.4105 13.42 23.868 13.994 23.868"
        fill="currentColor"
      />
    </svg>
  );
};

export default PlusIcon;

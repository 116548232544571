const RestoreIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <path
        d="M17 7H11M11 7V1M11 7L17 1M11 17V11M11 11H17M11 11L17 17M1 7H7M7 7V1M7 7L1 1M7 17V11M7 11H1M7 11L1 17"
        stroke="currentColor"
        strokeWidth="1.14286"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RestoreIcon;

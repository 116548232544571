import { ITheme } from "../../interfaces/theme";

export const parseThemes = (payload: any) => {
  return payload.map((theme: any) => {
    return {
      id: theme.id,
      themeName: theme.theme_name,
      themeColors: theme.theme_colors.split(","),
    } as ITheme;
  });
};

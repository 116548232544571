import { useAppSelector } from "../../../redux/hooks";

interface LatestViewItemProps {
  viewName: string;
}

const LatestViewItem = ({ viewName }: LatestViewItemProps) => {
  return (
    <div className="bg-custom-white-10 active:bg-custom-white-30 transition-all flex justify-center items-center  p-sm rounded-sm h-[50px] text-base-highlight font-primary not-italic font-normal leading-[20px] tracking-[-0.096px] select-none">
      {viewName}
    </div>
  );
};

const LatestViews = () => {
  const { reportFilterViews } = useAppSelector((state) => state.report);

  return (
    <div className="flex flex-col gap-[22px] mt-[138px]">
      <div className="text-base-highlight text-center font-primary not-italic font-semibold leading-[120%] uppercase ">
        LATEST VIEWS
      </div>
      <div className="flex flex-col gap-[10px]">
        {reportFilterViews.map((filterView, index) => {
          return <LatestViewItem viewName={filterView.viewName} key={index} />;
        })}
      </div>
    </div>
  );
};

export default LatestViews;

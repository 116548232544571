const PayIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.09961 3H16.0996C16.8953 3 17.6583 3.31607 18.2209 3.87868C18.7835 4.44129 19.0996 5.20435 19.0996 6V7H23.0996V21H1.09961V3ZM3.09961 9V19H21.0996V9H3.09961ZM3.09961 7H17.0996V6C17.0996 5.73478 16.9943 5.48043 16.8067 5.29289C16.6192 5.10536 16.3648 5 16.0996 5H3.09961V7ZM16.0996 13H19.0996V15H16.0996V13Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PayIcon;

import classNames from "classnames";
import Report from "../../components/Report";
import { useScreenActions } from "../../hooks/useReportActions";
import { useAppSelector } from "../../redux/hooks";

const ReportPage = () => {
  const { isPopupOpened } = useAppSelector((state) => state.global);
  // Use screen actions hook
  const {
    isFullScreen,
    isFitScreen,
    toggleFullScreen,
    toggleFitScreen,
    reportRefs,
  } = useScreenActions();

  const containerClasses = classNames(
    "flex flex-col gap-[18px] p-[12px] max-w-[500px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] mx-auto relative",
    {
      "overflow-hidden": isPopupOpened,
      "overflow-y-auto": !isPopupOpened,
      "max-h-fit-height": !isFullScreen.some((value) => value === true),
      "h-[100%] z-40": isFullScreen.some((value) => value === true), // Check if any is true
    }
  );

  return (
    <div className={containerClasses}>
      <Report
        isFitScreen={isFitScreen}
        isFullScreen={isFullScreen}
        toggleFullScreen={toggleFullScreen}
        toggleFitScreen={toggleFitScreen}
        reportRefs={reportRefs}
      />
    </div>
  );
};

export default ReportPage;

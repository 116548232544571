const EditIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={className}
    >
      <path
        d="M21.6465 8.463L22.83 7.303C23.428 6.705 23.451 6.0605 22.924 5.5215L22.5255 5.123C21.998 4.5955 21.3535 4.6545 20.7555 5.2285L19.572 6.4005L21.6465 8.463ZM7.83001 22.244L20.615 9.4705L18.5645 7.408L5.76751 20.193L4.65501 22.795C4.54951 23.076 4.84251 23.4045 5.12351 23.287L7.83001 22.244Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditIcon;

const ArrowRightIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="9"
      viewBox="0 0 7 9"
      fill="none"
      className={className}
    >
      <path
        d="M6.20046 5.01233C6.2004 5.0242 6.19648 5.04626 6.17251 5.07245L6.15157 5.08931L1.73534 8.64567C1.63275 8.72828 1.44629 8.72745 1.34445 8.64392L0.834517 8.22567C0.791897 8.19071 0.786267 8.16023 0.786331 8.14597C0.786394 8.13197 0.791987 8.10296 0.833474 8.07006L0.833484 8.07007L0.835854 8.06815L4.33466 5.23495L4.62008 5.00382L4.33665 4.77027L0.863412 1.90837L0.86289 1.90794C0.820269 1.87298 0.814639 1.8425 0.814703 1.82824C0.814766 1.81424 0.820359 1.78524 0.861846 1.75233L0.861851 1.75234L0.863594 1.75094L1.37726 1.33728C1.47985 1.25467 1.66631 1.25551 1.76815 1.33904L1.76823 1.33911L6.15227 4.93231C6.19478 4.96717 6.20052 4.99762 6.20046 5.01233Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.6"
      />
    </svg>
  );
};

export default ArrowRightIcon;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICategory } from "../../interfaces/category";
import { ICompetition } from "../../interfaces/competition";
import { IFavorite } from "../../interfaces/favorite";
import { IFilterList } from "../../interfaces/filterList";
import { IReport } from "../../interfaces/report";
import { ReportDataInfo } from "../../interfaces/reportDataInfo";
import { IReportField } from "../../interfaces/reportField";
import { IReportFilterView } from "../../interfaces/reportFilterView";
import { IReportList } from "../../interfaces/reportList";
import { ISavedFilter } from "../../interfaces/savedFilter";
import { ITrending } from "../../interfaces/trending";
import { IPhoto } from "./../../interfaces/photo";

import { FavoriteType } from "../../const/enums/FavoriteType";
import { SelectedDateType } from "../../const/enums/SelectedDateType";
import { TimeUnit } from "../../const/enums/TimeUnit";

import { getTableauJwtToken } from "../../services/authService";
import {
  getFilteredReportFilter,
  getReportFilters,
} from "../../services/filterService";
import {
  getCustomSheetReportData,
  getFilteredReportData,
  getHomeSheetsInfo,
  getPhotos,
  getReportDataInfo,
  getReportList,
} from "../../services/reportService";
import {
  deleteFilterView,
  deleteHomeSheet,
  getAllFilterViews,
  getHomeSheets,
  getSelectedFilterView,
  getUserFavorites,
  overwriteFilterView,
  reorderFilterViews,
  saveHomeSheet,
  saveUserView,
  toggleUserFavorite,
  updateSelectedFilterView,
  updateUserFavoritePositions,
} from "../../services/userService";

import { IHomeSheet } from "../../interfaces/homeSheet";
import { getTrendingInfos } from "../../services/trendingService";
import { formatDate, getDateType, getLastWeek } from "../../utils/date";
import {
  groupReportsByCategory,
  parseFavorites,
  parseFilterData,
  parseHomeSheets,
  parsePhotos,
  parseReportDataInfo,
  parseReportFilterViews,
  parseReports,
  parseTrendingInfos,
} from "../helpers/report";

export interface ReportState {
  reports: IReportList[];
  loading: boolean;
  error: string | null;
  currentReport: IReport | null;
  tableauJwtToken: string;

  reportFilters: IFilterList;
  appliedFilters: ISavedFilter;
  selectedFilters: ISavedFilter;
  isLoadingFilters: boolean;

  selectedDateRange: [string, string];
  selectedDateType: SelectedDateType;
  appliedDateType: SelectedDateType;
  selectedDateUnit: TimeUnit;

  currentFilterViewName: string;
  reportFilterViews: IReportFilterView[];
  reportDataInfo: ReportDataInfo;
  reportData: { name: string; data: any[] };
  reportFields: IReportField[];

  isLoadingReportData: boolean;
  isLoadingReportDataInfo: boolean;

  favorites: IFavorite[];

  photos: IPhoto[];

  featuredCompetitions: ICompetition[];

  trendingInfos: ITrending[];

  homeSheets: IHomeSheet[];
  homeSheetsData: { name: string; data: any[] };
  homeSheetsDataInfo: ReportDataInfo[];

  isLoadingHomeSheetsData: boolean;
  isLoadingHomeSheetsInfo: boolean;
}

// Initial state with proper types
const initialState: ReportState = {
  reports: [],
  loading: false,
  error: null,
  currentReport: null,
  tableauJwtToken: "",

  reportFilters: {},
  isLoadingFilters: false,

  appliedFilters: {},
  selectedFilters: {},

  selectedDateRange: [
    formatDate(getLastWeek().from),
    formatDate(getLastWeek().to),
  ],

  selectedDateType: SelectedDateType.LastWeek,
  appliedDateType: SelectedDateType.LastWeek,
  selectedDateUnit: TimeUnit.Days,

  reportData: { name: "", data: [] },
  isLoadingReportData: false,

  reportDataInfo: {
    name: "",
    filterDefaultGroupBy: "",
    filterHierarchy: [],
    filterAdditional: [],
    filterGroupFields: {},
    tableColumns: [],
    view: [],
  },

  reportFields: [],

  currentFilterViewName: "",
  reportFilterViews: [],

  favorites: [],
  isLoadingReportDataInfo: false,

  photos: [],
  featuredCompetitions: [],

  trendingInfos: [],

  homeSheets: [],
  homeSheetsData: { name: "", data: [] },
  homeSheetsDataInfo: [],

  isLoadingHomeSheetsData: false,
  isLoadingHomeSheetsInfo: false,
};

// Async thunk for fetching production reports
export const fetchReportList = createAsyncThunk(
  "report/fetchReportList",
  async () => {
    const response = await getReportList();
    return response;
  }
);

// Async thunk for fetching production reports
export const fetchTableauJwtToken = createAsyncThunk(
  "report/fetchTableauJwtToken",
  async () => {
    const response = await getTableauJwtToken();
    return response;
  }
);

export const fetchReportFilterList = createAsyncThunk(
  "report/fetchReportFilterList",
  async () => {
    const response = await getReportFilters();
    return response;
  }
);

export const fetchReportDataInfo = createAsyncThunk(
  "report/fetchReportDataInfo",
  async ({ reportName }: { reportName: string }) => {
    const response = await getReportDataInfo(reportName);
    return response;
  }
);

export const fetchReportData = createAsyncThunk(
  "report/fetchReportData",
  async ({
    reportName,
    reportView,
    filters,
  }: {
    reportName: string;
    reportView: string;
    filters: any;
  }) => {
    const response = await getFilteredReportData(
      reportName,
      reportView,
      filters
    );
    return response;
  }
);

export const fetchFilteredReportFilter = createAsyncThunk(
  "report/fetchFilteredReportFilter",
  async ({ reportName, filters }: { reportName: string; filters: any }) => {
    const response = await getFilteredReportFilter(reportName, filters);
    return response;
  }
);

export const fetchPhotos = createAsyncThunk("report/fetchPhotos", async () => {
  const response = await getPhotos();
  return response;
});

export const fetchSelectedFilterViewAction = createAsyncThunk(
  "report/fetchSelectedFilterViewAction",
  async (reportId: number) => {
    const response = await getSelectedFilterView(reportId);

    return response;
  }
);

export const fetchAllFilterViewsAction = createAsyncThunk(
  "report/fetchAllFilterViewsAction",
  async (reportId: number) => {
    const response = await getAllFilterViews(reportId);

    return response;
  }
);

export const saveFiltersViewAction = createAsyncThunk(
  "report/saveFiltersViewAction",
  async ({
    viewName,
    reportId,
    fields,
    savedFilters,
    isOverwrite,
  }: {
    viewName: string;
    reportId: number;
    fields: IReportField[];
    savedFilters: ISavedFilter;
    isOverwrite: boolean;
  }) => {
    if (isOverwrite) {
      const response = await overwriteFilterView(
        reportId,
        viewName,
        fields,
        savedFilters
      );

      return response;
    } else {
      const response = await saveUserView(
        reportId,
        viewName,
        fields,
        savedFilters
      );

      return response;
    }
  }
);

export const selectFilterViewAction = createAsyncThunk(
  "report/selectFilterViewAction",
  async ({ viewId, reportId }: { viewId: number; reportId: number }) => {
    const response = await updateSelectedFilterView(viewId, reportId);

    return response;
  }
);

export const removeFilterViewAction = createAsyncThunk(
  "report/removeFilterViewAction",
  async ({ viewId, reportId }: { viewId: number; reportId: number }) => {
    const response = await deleteFilterView(viewId, reportId);

    return response;
  }
);

export const reorderFilterViewAction = createAsyncThunk(
  "report/reorderFilterViewAction",
  async (viewOrders: { view_id: number; ordered_id: number }[]) => {
    const response = await reorderFilterViews(viewOrders);

    return response;
  }
);

export const fetchFavorites = createAsyncThunk(
  "report/fetchFavorites",
  async () => {
    const response = await getUserFavorites();

    return response;
  }
);

export const toggleFavorite = createAsyncThunk(
  "report/toggleFavorite",
  async ({
    favoriteType,
    favoriteId,
  }: {
    favoriteType: FavoriteType;
    favoriteId: number;
  }) => {
    const response = await toggleUserFavorite(favoriteType, favoriteId);

    return response;
  }
);

export const updateFavoritePositions = createAsyncThunk(
  "report/updateFavoritePositions",
  async (favorites: IFavorite[]) => {
    const response = await updateUserFavoritePositions(favorites);
    return response;
  }
);

export const fetchTrendingInfoAction = createAsyncThunk(
  "report/fetchTrendingInfoAction",
  async () => {
    const response = await getTrendingInfos();

    return response;
  }
);

export const fetchHomeSheetsAction = createAsyncThunk(
  "report/fetchHomeSheetsAction",
  async () => {
    const response = await getHomeSheets();

    return response;
  }
);

export const saveHomeSheetAction = createAsyncThunk(
  "report/saveHomeSheetAction",
  async ({
    reportId,
    reportViewName,
    reportFilterViewName,
    sheetType,
    sheetField,
    reportFilters,
    reportFields,
  }: {
    reportId: number;
    reportViewName: string;
    reportFilterViewName: string;
    sheetType: string;
    sheetField: string;
    reportFilters: string;
    reportFields: string;
  }) => {
    const response = await saveHomeSheet({
      reportId,
      reportViewName,
      reportFilterViewName,
      sheetType,
      sheetField,
      reportFilters,
      reportFields,
    });

    return response;
  }
);

export const deleteHomeSheetAction = createAsyncThunk(
  "report/deleteHomeSheetAction",
  async ({
    reportId,
    reportViewName,
    reportFilterViewName,
    sheetType,
    sheetField,
  }: {
    reportId: number;
    reportViewName: string;
    reportFilterViewName: string;
    sheetType: string;
    sheetField: string;
  }) => {
    const response = await deleteHomeSheet({
      reportId,
      reportViewName,
      reportFilterViewName,
      sheetType,
      sheetField,
    });

    return response;
  }
);

export const fetchHomeSheetsDataAction = createAsyncThunk(
  "report/fetchHomeSheetsDataAction",
  async (sheets: IHomeSheet[]) => {
    const response = await getCustomSheetReportData(sheets);

    return response;
  }
);

export const fetchHomeSheetsInfoAction = createAsyncThunk(
  "report/fetchHomeSheetsInfoAction",
  async (sheets: IHomeSheet[]) => {
    const response = await getHomeSheetsInfo(sheets);

    return response;
  }
);

// Slice for production state
const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {
    setCurrentReport: (state, { payload }) => {
      state.currentReport = payload;
    },

    updateSelectedDateRange: (state, { payload }) => {
      state.selectedDateRange = payload;
    },

    updatedSelectedDateType: (state, { payload }) => {
      state.selectedDateType = payload;
    },

    updatedAppliedDateType: (state, { payload }) => {
      state.appliedDateType = payload;
    },

    // Action to update the selected filter
    updateReportFilter: (
      state,
      action: PayloadAction<{
        filterName: string;
        selectedOption: string | string[];
      }>
    ) => {
      const { filterName, selectedOption } = action.payload;
      state.selectedFilters[filterName] = selectedOption;
    },

    applyFilter: (state) => {
      state.appliedFilters = state.selectedFilters;
    },

    clearFilter: (state) => {
      state.selectedFilters = {
        Date: state.selectedFilters["Date"],
      };
      state.selectedDateType = state.appliedDateType;
      state.reportFields = [];
    },

    clearAndApplyFilter: (state) => {
      state.selectedFilters = {
        Date: [formatDate(getLastWeek().from), formatDate(getLastWeek().to)],
      };
      state.selectedDateType = state.appliedDateType;
      state.reportFields = [];

      state.appliedFilters = state.selectedFilters;
    },

    updateSelectedFilters: (state, { payload }) => {
      if (!payload) return;

      const savedFilter = JSON.parse(payload);

      state.selectedFilters = {
        ...savedFilter,
        Date: [
          formatDate(new Date(savedFilter["Date"][0])),
          formatDate(new Date(savedFilter["Date"][1])),
        ],
      };

      state.appliedFilters = state.selectedFilters;

      state.selectedDateType =
        (getDateType(
          state.selectedFilters["Date"][0],
          state.selectedFilters["Date"][1]
        ) as SelectedDateType) || SelectedDateType.Custom;

      state.selectedDateRange = [
        state.selectedFilters["Date"][0],
        state.selectedFilters["Date"][1],
      ];

      state.appliedDateType =
        (getDateType(
          state.selectedFilters["Date"][0],
          state.selectedFilters["Date"][1]
        ) as SelectedDateType) || SelectedDateType.Custom;
    },

    updateReportFields: (state, { payload }) => {
      if (!payload) return;

      state.reportFields =
        typeof payload === "string" ? JSON.parse(payload) : payload;
    },

    updateCurrentFilterViewName: (state, { payload }) => {
      state.currentFilterViewName = payload;
    },

    updateReportFilterViews: (state, { payload }) => {
      state.reportFilterViews = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReportList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReportList.fulfilled, (state, action) => {
        state.loading = false;
        const reports = parseReports(action.payload);
        const categories = reports
          .map((r) => r.category)
          .reduce<ICategory[]>(
            (all, c) =>
              all.findIndex((item) => item.id === c.id) > -1
                ? all
                : [...all, c],
            []
          );
        const reportList = groupReportsByCategory(reports);
        state.reports = reportList;
        state.featuredCompetitions = [
          ...categories
            .filter((c) => c.is_featured_competition)
            .map((c) => ({
              imageUrl: c.image_url,
              name: c.name,
              link: `/competitions?id=${c.id}`,
              external: false,
            })),
          ...reports
            .filter((r) => r.isFeaturedCompetition)
            .map((r) => ({
              imageUrl: r.imageUrl,
              name: r.reportName,
              link:
                r.reportType === "web_link"
                  ? r.reportUrl
                  : `/report?alias=${r.alias}`,
              external: r.reportType === "web_link",
            })),
        ];
      })
      .addCase(fetchReportList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to load reports list";
      })
      .addCase(fetchTableauJwtToken.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTableauJwtToken.fulfilled, (state, action) => {
        state.loading = false;
        state.tableauJwtToken = action.payload.token;
      })
      .addCase(fetchTableauJwtToken.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch tableau jwt token";
      })
      .addCase(fetchReportFilterList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReportFilterList.fulfilled, (state, action) => {
        state.loading = false;
        state.reportFilters = parseFilterData(action.payload);
      })
      .addCase(fetchReportFilterList.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch report filter list.";
      })
      .addCase(fetchReportData.pending, (state) => {
        state.isLoadingReportData = true;
        state.error = null;
      })
      .addCase(fetchReportData.fulfilled, (state, action) => {
        state.isLoadingReportData = false;
        state.reportData = action.payload;
      })
      .addCase(fetchReportData.rejected, (state, action) => {
        state.isLoadingReportData = false;
        state.error =
          action.error.message || "Failed to fetch report filter list.";
      })
      .addCase(fetchReportDataInfo.pending, (state) => {
        state.isLoadingReportDataInfo = true;
        state.error = null;
      })
      .addCase(fetchReportDataInfo.fulfilled, (state, action) => {
        state.isLoadingReportDataInfo = false;
        if (Object.keys(action.payload).length === 0) return;

        const reportDataInfo: ReportDataInfo = parseReportDataInfo(
          action.payload
        );
        state.reportDataInfo = reportDataInfo;
      })
      .addCase(fetchReportDataInfo.rejected, (state, action) => {
        state.isLoadingReportDataInfo = false;
        state.error =
          action.error.message || "Failed to fetch report filter list.";
      })
      .addCase(fetchFilteredReportFilter.pending, (state) => {
        state.isLoadingFilters = true;
        state.error = null;
      })
      .addCase(fetchFilteredReportFilter.fulfilled, (state, { payload }) => {
        state.isLoadingFilters = false;
        state.reportFilters = {
          ...state.reportFilters,
          Division: payload["Division"] ?? state.reportFilters["Division"],
          Region: payload["Region"] ?? state.reportFilters["Region"],
          Team: payload["Team"] ?? state.reportFilters["Team"],
          Rep: payload["Rep"] ?? state.reportFilters["Rep"],
        };
      })
      .addCase(fetchFilteredReportFilter.rejected, (state, action) => {
        state.isLoadingFilters = false;
        state.error =
          action.error.message || "Failed to fetch report filter list.";
      })
      .addCase(fetchPhotos.pending, (state) => {})
      .addCase(fetchPhotos.fulfilled, (state, { payload }) => {
        state.photos = parsePhotos(payload);
      })
      .addCase(fetchPhotos.rejected, (state, { error }) => {})
      .addCase(saveFiltersViewAction.pending, (state) => {})
      .addCase(saveFiltersViewAction.fulfilled, (state, { payload }) => {
        state.reportFilterViews = parseReportFilterViews(payload);
      })
      .addCase(saveFiltersViewAction.rejected, (state, { error }) => {})
      .addCase(fetchSelectedFilterViewAction.pending, (state) => {})
      .addCase(
        fetchSelectedFilterViewAction.fulfilled,
        (state, { payload }) => {
          const { filters: filterString, fields: fieldString } = payload;

          if (!fieldString) {
            state.reportFields = state.reportDataInfo.tableColumns.map((c) => {
              return {
                field: c.field,
                fieldVisible: true,
              };
            });
          } else {
            const savedFields = JSON.parse(fieldString);

            state.reportFields = savedFields;
          }

          if (!filterString) {
            state.selectedFilters = {
              Date: state.selectedDateRange,
            };

            state.appliedFilters = state.selectedFilters;
          } else {
            const savedFilter = JSON.parse(filterString);

            state.selectedFilters = {
              ...savedFilter,
              Date: [
                formatDate(new Date(savedFilter["Date"][0])),
                formatDate(new Date(savedFilter["Date"][1])),
              ],
            };

            state.appliedFilters = state.selectedFilters;

            state.selectedDateType =
              (getDateType(
                state.selectedFilters["Date"][0],
                state.selectedFilters["Date"][1]
              ) as SelectedDateType) || SelectedDateType.Custom;

            state.selectedDateRange = [
              state.selectedFilters["Date"][0],
              state.selectedFilters["Date"][1],
            ];

            state.appliedDateType =
              (getDateType(
                state.selectedFilters["Date"][0],
                state.selectedFilters["Date"][1]
              ) as SelectedDateType) || SelectedDateType.Custom;
          }
        }
      )
      .addCase(fetchSelectedFilterViewAction.rejected, (state, { error }) => {
        state.selectedFilters = {
          Date: state.selectedDateRange,
        };

        state.appliedFilters = state.selectedFilters;
        return;
      })
      .addCase(toggleFavorite.pending, (state) => {})
      .addCase(toggleFavorite.fulfilled, (state, { payload }) => {
        state.favorites = parseFavorites(payload);
      })
      .addCase(toggleFavorite.rejected, (state, { error }) => {})
      .addCase(updateFavoritePositions.pending, (state) => {})
      .addCase(updateFavoritePositions.fulfilled, (state, { payload }) => {
        state.favorites = payload ?? [];
      })
      .addCase(updateFavoritePositions.rejected, (state, { error }) => {})
      .addCase(fetchFavorites.pending, (state) => {})
      .addCase(fetchFavorites.fulfilled, (state, { payload }) => {
        state.favorites = parseFavorites(payload);
      })
      .addCase(fetchFavorites.rejected, (state, { error }) => {})
      .addCase(fetchAllFilterViewsAction.pending, (state) => {})
      .addCase(fetchAllFilterViewsAction.fulfilled, (state, { payload }) => {
        state.reportFilterViews = parseReportFilterViews(payload);
      })
      .addCase(fetchAllFilterViewsAction.rejected, (state, { error }) => {})
      .addCase(selectFilterViewAction.pending, (state) => {})
      .addCase(selectFilterViewAction.fulfilled, (state, { payload }) => {
        state.reportFilterViews = parseReportFilterViews(payload);
      })
      .addCase(selectFilterViewAction.rejected, (state, { error }) => {})
      .addCase(removeFilterViewAction.pending, (state) => {})
      .addCase(removeFilterViewAction.fulfilled, (state, { payload }) => {
        state.reportFilterViews = parseReportFilterViews(payload);
      })
      .addCase(removeFilterViewAction.rejected, (state, { error }) => {})
      .addCase(reorderFilterViewAction.pending, (state) => {})
      .addCase(reorderFilterViewAction.fulfilled, (state, { payload }) => {
        state.reportFilterViews = parseReportFilterViews(payload);
      })
      .addCase(reorderFilterViewAction.rejected, (state, { error }) => {})

      .addCase(fetchTrendingInfoAction.pending, (state) => {})
      .addCase(fetchTrendingInfoAction.fulfilled, (state, { payload }) => {
        state.trendingInfos = parseTrendingInfos(payload);
      })
      .addCase(fetchTrendingInfoAction.rejected, (state, { error }) => {});

    builder.addCase(deleteHomeSheetAction.pending, (state) => {});
    builder.addCase(deleteHomeSheetAction.fulfilled, (state, { payload }) => {
      state.homeSheets = parseHomeSheets(payload);
    });
    builder.addCase(deleteHomeSheetAction.rejected, (state, { error }) => {});

    builder.addCase(saveHomeSheetAction.pending, (state) => {});
    builder.addCase(saveHomeSheetAction.fulfilled, (state, { payload }) => {
      state.homeSheets = parseHomeSheets(payload);
    });
    builder.addCase(saveHomeSheetAction.rejected, (state, { error }) => {});

    builder.addCase(fetchHomeSheetsAction.pending, (state) => {});
    builder.addCase(fetchHomeSheetsAction.fulfilled, (state, { payload }) => {
      state.homeSheets = parseHomeSheets(payload);
      if (state.homeSheets.length === 0) {
        state.homeSheetsData = { name: "", data: [] };
        state.homeSheetsDataInfo = [];
      }
    });
    builder.addCase(fetchHomeSheetsAction.rejected, (state, { error }) => {});

    builder.addCase(fetchHomeSheetsDataAction.pending, (state) => {
      state.isLoadingHomeSheetsData = true;
    });
    builder.addCase(
      fetchHomeSheetsDataAction.fulfilled,
      (state, { payload }) => {
        state.homeSheetsData = payload;
        state.isLoadingHomeSheetsData = false;
      }
    );
    builder.addCase(fetchHomeSheetsDataAction.rejected, (state) => {
      state.isLoadingHomeSheetsData = false;
    });

    builder.addCase(fetchHomeSheetsInfoAction.pending, (state) => {
      state.isLoadingHomeSheetsInfo = true;
    });
    builder.addCase(
      fetchHomeSheetsInfoAction.fulfilled,
      (state, { payload }) => {
        state.homeSheetsDataInfo = payload.map((info: any) =>
          parseReportDataInfo(info)
        );
        state.isLoadingHomeSheetsInfo = false;
      }
    );
    builder.addCase(fetchHomeSheetsInfoAction.rejected, (state, { error }) => {
      state.isLoadingHomeSheetsInfo = false;
    });
  },
});

export const {
  setCurrentReport,
  updateReportFilter,
  updateSelectedDateRange,
  updatedAppliedDateType,
  updatedSelectedDateType,
  applyFilter,
  clearFilter,
  clearAndApplyFilter,
  updateReportFields,
  updateSelectedFilters,
  updateCurrentFilterViewName,
  updateReportFilterViews,
} = reportSlice.actions;

export default reportSlice.reducer;

const FilterResetIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      className={className}
    >
      <path
        d="M11.6673 9.83333H5.83398V4M6.17348 19.5832C7.26065 21.2572 8.85586 22.5386 10.7249 23.2393C12.594 23.9399 14.6385 24.0229 16.5582 23.476C18.4779 22.9291 20.1717 21.7812 21.3909 20.2007C22.6102 18.6203 23.2906 16.6906 23.3323 14.6949C23.3741 12.6993 22.7749 10.7428 21.6228 9.11277C20.4707 7.48274 18.8263 6.265 16.9312 5.63834C15.036 5.01168 12.9899 5.00912 11.0931 5.63102C9.1964 6.25292 7.54901 7.46653 6.39282 9.09367"
        stroke="currentColor"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterResetIcon;

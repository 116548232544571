import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import Button from "../Common/Button";
import CompetitionSection from "../Home/CompetitionSection";
import TrophyIcon from "../icons/TrophyIcon";

const Competition = () => {
  const [searchParams] = useSearchParams();
  const reports = useAppSelector((state) => state.report.reports);
  const id = searchParams.get("id");

  return (
    <>
      {reports
        .filter((r) => r.isCompetition && (id ? r.id === +id : true))
        .map((c) => (
          <CompetitionSection
            key={c.categoryName}
            competitionName={c.categoryName}
            competitions={c.reports.map((r) => ({
              imageUrl: r.imageUrl,
              name: r.reportName,
              link: `/report?alias=${r.alias}`,
              external: false,
            }))}
          />
        ))}
      <div>
        <Button
          bgColor="bg-base-highlight"
          activeColor="active:bg-secondary-400"
          className="flex !h-[40px] px-[10px] py-[6px] items-center gap-[10px] rounded-[8px] border-[1px] border-solid border-neutral-200"
          onClick={() => {}}
        >
          <span className="text-opposite-highlight font-primary text-sm not-italic font-semibold leading-[120%]">
            Prize Locker
          </span>
          <TrophyIcon className="text-opposite-highlight" />
        </Button>
      </div>
    </>
  );
};

export default Competition;

import isMobile from "is-mobile";
import {
  forwardRef,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "react-loading-skeleton/dist/skeleton.css";

import { renderHeader, renderSubHeader, renderTableRows } from "./helpers";

interface TableProps {
  isFullScreen: boolean;
  isFitScreen: boolean;
  loading: boolean;
  isMinHeight?: boolean;
  tableHeader?: string[];
  tableSubHeader?: any;
  tableContent?: any[];
  className?: string;
  defaultIcon?: ReactNode;
  handleOnClickEntity: (selectedEntity: string) => void;
}

const Table = forwardRef<HTMLDivElement, TableProps>(
  (
    {
      isFullScreen,
      isMinHeight,
      isFitScreen,
      loading,
      tableHeader,
      tableSubHeader,
      tableContent = [],
      className,
      defaultIcon,
      handleOnClickEntity,
    }: TableProps,
    ref
  ) => {
    const skeletonRowCount = isMobile() ? 30 : 40;
    const skeletonColCount = 5; // Default to 5 columns if no headers provided

    // Lazy loading states
    const rowIncrement = isMobile() ? 30 : 40; // Number of rows to load on each scroll
    const [visibleRows, setVisibleRows] = useState(rowIncrement); // Start with 20 rows
    const [sortByColumn, setSortByColumn] = useState(-1);
    const [sortByDirection, setSortByDirection] = useState(0);

    const tableRef = useRef<HTMLDivElement | null>(null);
    const [tableWidth, setTableWidth] = useState<number>(0);

    // Handle scroll event to trigger lazy loading
    const handleScroll = () => {
      const table = document.querySelector(".report-table");
      if (table) {
        const { scrollTop, scrollHeight, clientHeight } = table;
        if (scrollTop + clientHeight >= scrollHeight - 50) {
          loadMoreRows();
        }
      }
    };

    const loadMoreRows = () => {
      if (visibleRows < tableContent.length) {
        setVisibleRows((prev) =>
          Math.min(prev + rowIncrement, tableContent.length)
        );
      }
    };

    const sortBy = (newSortByColumn: number) => {
      if (sortByColumn === newSortByColumn) {
        setSortByDirection(1 - sortByDirection);
      } else {
        setSortByDirection(1);
        setSortByColumn(newSortByColumn);
      }
    };

    const sortedTableContent = useMemo(() => {
      return tableContent.sort((row1, row2) => {
        return (
          ((Object.values(row2)[sortByColumn] as number) -
            (Object.values(row1)[sortByColumn] as number)) *
          (sortByDirection === 0 ? -1 : 1)
        );
      });
    }, [sortByColumn, sortByDirection, tableContent]);

    useEffect(() => {
      const table = document.querySelector(".report-table");
      table?.addEventListener("scroll", handleScroll);
      return () => {
        table?.removeEventListener("scroll", handleScroll);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visibleRows, tableContent]);

    useEffect(() => {
      setVisibleRows(rowIncrement);
    }, [rowIncrement, tableContent]);

    useEffect(() => {
      if (tableRef.current) {
        setTableWidth(tableRef.current.clientWidth);
      }
    }, [tableRef]);

    return (
      <div
        className={`w-full ${className} report-table overflow-auto
        ${
          isFullScreen
            ? "h-table-height-expanded"
            : isMinHeight
            ? "max-h-height-normal"
            : "h-table-height-normal"
        }
        ${isFitScreen ? "overflow-hidden" : ""}`}
        ref={(el) => {
          tableRef.current = el;
          if (ref) {
            if (typeof ref === "function") {
              ref(el);
            } else {
              ref.current = el;
            }
          }
        }}
      >
        <table
          className={`w-full bg-custom-deepCharcoal ${className} ${
            isFitScreen
              ? isFullScreen
                ? "h-table-height-expanded"
                : "h-table-height-normal"
              : ""
          }`}
        >
          <thead>
            {renderHeader(
              isFitScreen,
              tableHeader,
              loading,
              skeletonColCount,
              sortBy,
              sortByColumn,
              sortByDirection,
              tableWidth,
              (tableHeader?.length || 1) - 1
            )}
            {renderSubHeader(
              isFitScreen,
              tableSubHeader,
              loading,
              skeletonColCount,
              sortByColumn,
              tableWidth,
              (tableHeader?.length || 1) - 1
            )}
          </thead>
          <tbody>
            {renderTableRows(
              isFitScreen,
              isFullScreen,
              isFitScreen
                ? sortedTableContent
                : sortedTableContent.slice(0, visibleRows), // Only render visible rows
              loading,
              skeletonRowCount,
              skeletonColCount,
              defaultIcon,
              sortByColumn,
              tableWidth,
              (tableHeader?.length || 1) - 1,
              handleOnClickEntity
            )}
          </tbody>
        </table>
      </div>
    );
  }
);
export default Table;

import Button from "../Common/Button";
import IconButton from "../Common/IconButton";
import MobilePopup from "../Common/MobilePopup";

import trashIcon from "../../assets/icons/trash-01.svg";
import CloseIcon from "../icons/CloseIcon";

import { IHomeSheet } from "../../interfaces/homeSheet";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { deleteHomeSheetAction } from "../../redux/slices/reportSlice";
import toast from "react-hot-toast";

interface EditHomeSheetPopupProps {
  isPopupOpen: boolean;
  closePopup: () => void;
}

interface HomeSheetItemProps {
  sheet: IHomeSheet;
  isLast: boolean;
  onRemoveHomeSheet: () => void;
}

const HomeSheetItem = ({
  sheet,
  isLast,
  onRemoveHomeSheet,
}: HomeSheetItemProps) => {
  return (
    <div
      className={`flex px-[8px] py-2 gap-[12px] items-center w-full ${
        !isLast && "border-b-[1px] border-solid border-neutral-900"
      }`}
    >
      <h2 className="flex-grow-[1] text-base-highlight font-primary not-italic font-semibold leading-[120%] select-none whitespace-nowrap overflow-hidden overflow-ellipsis">
        {sheet.reportName} - {sheet.reportViewName} -{" "}
        {sheet.reportFilterViewName}
      </h2>

      <IconButton
        icon={trashIcon}
        size={16}
        onClick={onRemoveHomeSheet}
      ></IconButton>
    </div>
  );
};

const EditHomeSheetPopup = ({
  isPopupOpen,
  closePopup,
}: EditHomeSheetPopupProps) => {
  const dispatch = useAppDispatch();
  const { homeSheets } = useAppSelector((state) => state.report);

  const onToggleFavorite = (index: number) => {
    const sheet = homeSheets[index];

    toast.promise(
      dispatch(
        deleteHomeSheetAction({
          reportId: sheet.reportId,
          reportFilterViewName: sheet.reportFilterViewName,
          reportViewName: sheet.reportViewName,
          sheetType: sheet.sheetType,
          sheetField: sheet.sheetField || "",
        })
      ).unwrap(),
      {
        loading: "Removing from home screen...",
        success:
          "The sheet has been successfully removed from your home screen.",
        error:
          "Oops! There was an error while trying to remove the sheet from your home screen.",
      }
    );
  };

  return (
    <div>
      <MobilePopup
        backBg="bg-overlay"
        popupBg="bg-custom-deepCharcoal"
        popupBorder="ring-secondary-900"
        isOpen={isPopupOpen}
        onClose={closePopup}
      >
        <div className="flex flex-col gap-m">
          <div className="flex items-center justify-between py-[16px] px-[19px] ">
            <div>
              <h2 className="text-textPrimary font-primary text-[18px] not-italic font-semibold leading-[120%]">
                Edit Home sheets
              </h2>

              <h2 className="text-textPrimary font-primary opacity-80 text-[11px] not-italic font-medium leading-[120%]">
                Add selected to home screen
              </h2>
            </div>

            <IconButton onClick={closePopup}>
              <CloseIcon className="text-primaryText" />
            </IconButton>

            <div className="w-[1px] h-[21px] mt-[12px] bg-neutral-900 fixed left-[50%] top-0"></div>
          </div>

          <div className="flex flex-col gap-[8px] px-[12px] min-h-[240px] max-h-[70vh] overflow-y-auto">
            {homeSheets.map((sheet, index) => {
              return (
                <HomeSheetItem
                  key={index}
                  sheet={sheet}
                  isLast={index === homeSheets.length - 1}
                  onRemoveHomeSheet={() => onToggleFavorite(index)}
                ></HomeSheetItem>
              );
            })}
          </div>

          <div className="flex justify-center items-center px-[12px] py-[16px] gap-[16px] border-t-[1px] border-solid border-t-neutral-800">
            <Button
              bgColor="bg-base-highlight"
              activeColor="active:bg-neutral-200"
              className="py-[6px] px-[10px] max-h-[40px] min-w-[120px]"
              onClick={closePopup}
            >
              <span className="font-primary text-opposite-highlight text-sm not-italic font-semibold leading-[120%]">
                Close
              </span>
            </Button>
          </div>
        </div>
      </MobilePopup>
    </div>
  );
};

export default EditHomeSheetPopup;

const ShareIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      className={className}
    >
      <path
        d="M3.56445 10.0003V16.667C3.56445 17.109 3.74005 17.5329 4.05261 17.8455C4.36517 18.1581 4.78909 18.3337 5.23112 18.3337H15.2311C15.6731 18.3337 16.0971 18.1581 16.4096 17.8455C16.7222 17.5329 16.8978 17.109 16.8978 16.667V10.0003M13.5645 5.00033L10.2311 1.66699M10.2311 1.66699L6.89779 5.00033M10.2311 1.66699V12.5003"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShareIcon;

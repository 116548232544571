const HomeEmptyIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12.4994H5.99995V22.4994C5.99995 22.7646 6.10531 23.019 6.29285 23.2065C6.48038 23.3941 6.73474 23.4994 6.99995 23.4994H21C21.2652 23.4994 21.5195 23.3941 21.7071 23.2065C21.8946 23.019 22 22.7646 22 22.4994V12.4994ZM24 12.4994V23.4994C24 24.0299 23.7892 24.5386 23.4142 24.9136C23.0391 25.2887 22.5304 25.4994 22 25.4994H5.99995C5.46952 25.4994 4.96081 25.2887 4.58574 24.9136C4.21067 24.5386 3.99995 24.0299 3.99995 23.4994V12.4994H2.05495C1.84412 12.4995 1.63866 12.4329 1.46793 12.3092C1.2972 12.1855 1.16994 12.011 1.10433 11.8106C1.03873 11.6102 1.03813 11.3942 1.10263 11.1935C1.16712 10.9928 1.29341 10.8176 1.46345 10.6929L13.4185 1.92593C13.5878 1.80176 13.792 1.73407 14.002 1.73245C14.212 1.73084 14.4172 1.79538 14.5885 1.91693L21 6.46693V5.49943C21 5.23421 21.1053 4.97985 21.2928 4.79232C21.4804 4.60478 21.7347 4.49943 22 4.49943H23C23.2652 4.49943 23.5195 4.60478 23.7071 4.79232C23.8946 4.97985 24 5.23421 24 5.49943V8.59643L26.942 10.6839C27.115 10.8067 27.2444 10.9813 27.3117 11.1825C27.3789 11.3838 27.3804 11.6011 27.3159 11.8033C27.2515 12.0054 27.1244 12.1818 26.9531 12.3069C26.7818 12.4321 26.5751 12.4995 26.363 12.4994H24ZM17 14.4994H21V22.4994H17V14.4994ZM22 10.4994V12.4994H5.99995V10.4994L13.4375 4.39193C13.6068 4.26776 13.811 4.20007 14.021 4.19845C14.231 4.19684 14.4362 4.26138 14.6075 4.38293L22 10.4994Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default HomeEmptyIcon;

import Backdrop from "../Common/Backdrop";

interface TutorialVideoProps {
  title: string;
  link: string;
  isPopupOpen: boolean;
  closePopup: () => void;
}

const TutorialVideo = ({
  title,
  link,
  isPopupOpen,
  closePopup,
}: TutorialVideoProps) => {
  return (
    <Backdrop
      isOpen={isPopupOpen}
      onClose={closePopup}
      backBg="bg-overlay"
      contentClassName="overflow-hidden absolute left-[50%] translate-x-[-50%] translate-y-[-50%] top-[50%] w-[90%] h-[50vw] rounded-[8px] border-[1px] border-solid border-custom-white-80 bg-custom-darkTeal flex items-center justify-center"
    >
      {isPopupOpen && (
        <iframe
          width="100%"
          height="100%"
          title={title}
          src={link}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      )}
    </Backdrop>
  );
};

export default TutorialVideo;

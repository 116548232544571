// Define the type for the color palette
type ThemeColors = {
  [key: string]: string[]; // Allow any string key with string values
};

const DEFAULT_THEME_COLORS: ThemeColors = {
  Default: [
    "#000000", // Opposite
    "#ffffff", // White
    "#ffffff", // Primary Text
    "#a99655", // Primary
    "#fafafa", // Secondary
    "#9badb3", // Neutral
    "#2c3333", // DarkTeal
  ],

  "SUNSET GLOW": [
    "#1a535c", // Opposite
    "#ffffff", // White
    "#ffffff", // Primary Text
    "#ff6b6b", // Primary
    "#fafafa", // Secondary
    "#89b3b6", // Neutral
    "#438a95", // DarkTeal
  ],

  "NEON VIBES": [
    "#eaeaea", // Opposite
    "#000000", // White
    "#252a34", // Primary Text
    "#ff2e63", // Primary
    "#08d9d6", // Secondary
    "#89b3b6", // Neutral
    "#dbdbdb", // DarkTeal
  ],

  "LIGHT SKY": [
    "#f3f4f7", // Opposite
    "#000000", // White
    "#252a34", // Primary Text
    "#3462ff", // Primary
    "#5eb525", // Secondary
    "#d3d6dc", // Neutral
    "#dbdbdb", // DarkTeal
  ],

  "EARTHY TONES": [
    "#2a2a2a", // Opposite
    "#ffffff", // White
    "#ffffff", // Primary Text
    "#db0000", // Primary
    "#faeded", // Secondary
    "#a6a6a6", // Neutral
    "#282828", // DarkTeal
  ],
};

export default DEFAULT_THEME_COLORS;

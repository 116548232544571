import Competition from "../../components/Competition";
import { useAppSelector } from "../../redux/hooks";

const CompetitionPage = () => {
  const { isPopupOpened } = useAppSelector((state) => state.global);

  return (
    <div
      className={`flex flex-col gap-[32px] p-[12px] max-w-[500px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] mx-auto relative ${
        isPopupOpened ? "overflow-hidden" : "overflow-y-auto"
      }`}
    >
      <Competition />
    </div>
  );
};

export default CompetitionPage;

const SmallHomeIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      className={className}
    >
      <path
        d="M16.5418 7.89418C16.5413 7.89379 16.5409 7.89327 16.5405 7.89288L9.60584 0.958488C9.31025 0.662773 8.91726 0.5 8.49924 0.5C8.08122 0.5 7.68823 0.662773 7.39251 0.958488L0.461489 7.88938C0.459154 7.89172 0.45669 7.89418 0.454485 7.89651C-0.152509 8.50701 -0.151472 9.49753 0.457468 10.1065C0.735674 10.3848 1.10298 10.5459 1.49584 10.5629C1.51193 10.5644 1.52801 10.5652 1.54422 10.5652H1.82048V15.6684C1.82048 16.6784 2.64226 17.5 3.65223 17.5H6.36529C6.64038 17.5 6.86334 17.2769 6.86334 17.002V13.001C6.86334 12.5402 7.2383 12.1653 7.69912 12.1653H9.29936C9.76018 12.1653 10.135 12.5402 10.135 13.001V17.002C10.135 17.2769 10.358 17.5 10.6331 17.5H13.3461C14.3562 17.5 15.1779 16.6784 15.1779 15.6684V10.5652H15.4342C15.852 10.5652 16.245 10.4024 16.5409 10.1066C17.1505 9.49675 17.1507 8.50442 16.5418 7.89418ZM15.8365 9.40233C15.729 9.50985 15.586 9.56912 15.4342 9.56912H14.6798C14.4047 9.56912 14.1818 9.79208 14.1818 10.0672V15.6684C14.1818 16.1291 13.8069 16.5039 13.3461 16.5039H11.1311V13.001C11.1311 11.991 10.3095 11.1692 9.29936 11.1692H7.69912C6.68902 11.1692 5.86724 11.991 5.86724 13.001V16.5039H3.65223C3.19154 16.5039 2.81658 16.1291 2.81658 15.6684V10.0672C2.81658 9.79208 2.59362 9.56912 2.31853 9.56912H1.57717C1.56938 9.5686 1.56173 9.56821 1.55382 9.56808C1.40544 9.56549 1.26628 9.50661 1.162 9.4022C0.940211 9.18041 0.940211 8.81946 1.162 8.59754C1.16213 8.59754 1.16213 8.59741 1.16226 8.59728L1.16265 8.59689L8.09704 1.66276C8.20443 1.55524 8.34723 1.49609 8.49924 1.49609C8.65112 1.49609 8.79392 1.55524 8.90144 1.66276L15.8343 8.59547C15.8353 8.5965 15.8365 8.59754 15.8375 8.59858C16.0581 8.82076 16.0577 9.18093 15.8365 9.40233Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SmallHomeIcon;

const ResetIcon = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      className={className}
    >
      <path
        d="M18.3327 10.3304C18.3327 14.9329 14.6018 18.6637 9.99935 18.6637C5.39685 18.6637 1.66602 14.9329 1.66602 10.3304C1.66602 5.7279 5.39685 1.99707 9.99935 1.99707V3.66374C8.43165 3.66386 6.91414 4.21645 5.71345 5.22443C4.51275 6.2324 3.70568 7.63127 3.43405 9.17526C3.16241 10.7192 3.44358 12.3096 4.22815 13.6668C5.01272 15.0241 6.25051 16.0614 7.72403 16.5966C9.19756 17.1318 10.8125 17.1305 12.2853 16.5931C13.758 16.0557 14.9942 15.0165 15.7767 13.658C16.5592 12.2996 16.8379 10.7088 16.5639 9.16526C16.2899 7.62169 15.4807 6.22405 14.2785 5.2179L12.4993 6.99707V1.99707H17.4993L15.4602 4.03624C16.3627 4.81773 17.0864 5.78436 17.582 6.87044C18.0777 7.95651 18.3337 9.13657 18.3327 10.3304Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ResetIcon;

const MenuIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3 5C3 4.72386 3.22386 4.5 3.5 4.5H20.5C20.7761 4.5 21 4.72386 21 5V6C21 6.27614 20.7761 6.5 20.5 6.5H3.5C3.22386 6.5 3 6.27614 3 6V5Z"
        fill="currentColor"
      />
      <path
        d="M3 18.5C3 18.2239 3.22386 18 3.5 18H20.5C20.7761 18 21 18.2239 21 18.5V19.5C21 19.7761 20.7761 20 20.5 20H3.5C3.22386 20 3 19.7761 3 19.5V18.5Z"
        fill="currentColor"
      />
      <path
        d="M3 11.5C3 11.2239 3.22386 11 3.5 11H20.5C20.7761 11 21 11.2239 21 11.5V12.5C21 12.7761 20.7761 13 20.5 13H3.5C3.22386 13 3 12.7761 3 12.5V11.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MenuIcon;

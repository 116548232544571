import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateReportFields } from "../../../redux/slices/reportSlice";
import CheckIcon from "../../icons/CheckIcon";

interface ColumnSelectionItemProps {
  fieldName: string;
  checked: boolean;
  index: number;
  onChange: (checked: boolean) => void;
}

const ColumnSelectionItem: React.FC<ColumnSelectionItemProps> = ({
  fieldName,
  checked,
  index,
  onChange,
}) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  return (
    <Draggable draggableId={fieldName} index={index}>
      {(provided) => {
        return (
          <div
            className="flex items-center px-4 py-3 rounded-sm bg-custom-darkTeal"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() => onChange(!checked)} // Add click handler to the div
          >
            <input
              type="checkbox"
              className="hidden"
              checked={checked}
              onChange={handleCheckboxChange}
            />

            <div
              className={`w-5 h-5 mr-2 rounded-md border-2 border-primaryText flex items-center justify-center cursor-pointer ${
                checked ? "bg-custom-teal" : ""
              }`}
            >
              {checked && <CheckIcon className="text-primaryText" />}
            </div>

            <span className="select-none text-primaryText font-primary text-sm not-italic font-semibold leading-[120%]">
              {fieldName}
            </span>
          </div>
        );
      }}
    </Draggable>
  );
};

const ColumnSelections = () => {
  const dispatch = useAppDispatch();
  const {
    reportFields,
    reportDataInfo: { tableColumns },
  } = useAppSelector((state) => state.report);

  const [allSelected, setAllSelected] = useState(false);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedFields = Array.from(reportFields);
    const [removed] = reorderedFields.splice(result.source.index, 1);
    reorderedFields.splice(result.destination.index, 0, removed);

    dispatch(updateReportFields(reorderedFields));
  };

  // Update the visibility state when a checkbox is changed
  const handleCheckboxChange = (index: number, checked: boolean) => {
    const updatedFields = [...reportFields];
    updatedFields[index] = {
      ...updatedFields[index],
      fieldVisible: checked,
    };

    dispatch(updateReportFields(updatedFields));
  };

  const handleAllChecked = (checked: boolean) => {
    setAllSelected(checked);
    let updatedFields = [...reportFields];
    updatedFields = updatedFields.map((field) => ({
      ...field,
      fieldVisible: checked,
    }));

    dispatch(updateReportFields(updatedFields));
  };

  useEffect(() => {
    if (reportFields.findIndex((field) => field.fieldVisible === false) < 0) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [reportFields]);

  const columnsMapping = useMemo(
    () =>
      tableColumns.reduce(
        (mapping, c) => ({
          ...mapping,
          [c.field]: c.name,
        }),
        {} as { [key: string]: string }
      ),
    [tableColumns]
  );

  return (
    <div className="flex flex-col w-full bg-custom-deepCharcoal rounded-[8px] shadow-8dp-umbra px-4 py-4 gap-2">
      <div className="border-b-[1px] border-custom-white-10 pb-2">
        <div
          className="flex items-center px-4 py-3 rounded-sm bg-custom-darkTeal"
          onClick={() => {
            handleAllChecked(!allSelected);
          }}
        >
          <input
            type="checkbox"
            className="hidden"
            checked={allSelected}
            onChange={() => {}}
          />

          <div
            className={`w-5 h-5 mr-2 rounded-md border-2 border-primaryText flex items-center justify-center cursor-pointer ${
              allSelected ? "bg-custom-teal" : ""
            }`}
          >
            {allSelected && <span className="text-primaryText">✔</span>}
          </div>

          <span className="select-none text-primaryText font-primary text-sm not-italic font-semibold leading-[120%]">
            ALL
          </span>
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              className="droppable "
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className="flex flex-col gap-2">
                {reportFields.map((field, index) => (
                  <ColumnSelectionItem
                    fieldName={columnsMapping[field.field]}
                    checked={field.fieldVisible}
                    key={field.field}
                    index={index}
                    onChange={(checked) => handleCheckboxChange(index, checked)}
                  />
                ))}
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ColumnSelections;

import { ReactNode } from "react";

interface BackdropPros {
  children?: ReactNode; // To render any custom content inside the popup
  isOpen: boolean; // Control the open/close state externally
  onClose: () => void; // Handle closing the popup externally
  backBg?: string;
  contentClassName?: string;
}

const Backdrop = ({
  children,
  isOpen,
  onClose,
  backBg,
  contentClassName,
}: BackdropPros) => {
  return (
    <div
      className={`fixed inset-0 z-50 top-0 flex justify-center items-end transition-opacity duration-300 ease-out ${backBg} ${
        isOpen
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      }`}
      onClick={onClose}
    >
      <div
        className={`top-0 ring-[1px] ring-neutral-800 transform transition-all duration-300 ease-out ${contentClassName} ${
          isOpen
            ? "translate-y-0 scale-100 opacity-100"
            : "translate-y-50 scale-95 opacity-0"
        }`}
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the popup
      >
        {children}
      </div>
    </div>
  );
};

export default Backdrop;

import { Link, useNavigate } from "react-router-dom";
import { ICompetition } from "../../interfaces/competition";

interface CompetitionSectionProps {
  competitionName?: string;
  competitions?: ICompetition[];
  onClose: () => void;
}

const Competitions = ({
  competitionName = "Competitions",
  competitions = [],
  onClose,
}: CompetitionSectionProps) => {
  const navigate = useNavigate();

  const onClickViewAll = () => {
    onClose();
    navigate("/competitions");
  };

  return (
    <div className="flex flex-col items-start gap-[12px] px-[12px]">
      <div className="flex justify-between items-center gap-[10px] self-stretch">
        <h2 className="text-opposite-highlight text-lg not-italic font-semibold leading-[120%]">
          {competitionName}
        </h2>

        <button
          className="flex justify-center items-center px-sm py-s"
          onClick={onClickViewAll}
        >
          <span className="text-neutral-900 font-primary text-sm not-italic font-semibold leading-[120%]">
            View All
          </span>
        </button>
      </div>

      <div className="flex items-center overflow-x-auto w-full">
        <div className="flex gap-sm items-center">
          {competitions.map((competition, index) => {
            return (
              <Link
                key={index}
                to={competition.link ?? "#"}
                target={competition.external ? "_blank" : "_self"}
                onClick={onClose}
              >
                <div className="flex flex-col items-center gap-[16px] rounded-[4px] bg-item-dark-gradient p-[12px] h-[154px] max-w-[124px]">
                  <img
                    src={competition.imageUrl}
                    alt={competition.name}
                    srcSet=""
                    className="w-[80px] h-[80px] min-w-[80px] min-h-[80px] flex-shrink-0 rounded-[64px] shadow-pen-umbra-1 bg-gradient-1"
                  />

                  <p className="text-base-highlight text-center font-primary text-sm not-italic font-semibold leading-[120%] min-w-[100px]">
                    {competition.name}
                  </p>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Competitions;

import { ReactNode, useState } from "react";

interface IconButtonProps {
  icon?: string; // The SVG icon or any JSX element
  onClick: () => void; // Event handler for the button click
  size?: number; // Optional size, default is "24px"
  color?: string; // Optional color, default is "black"
  padding?: number;
  className?: string; // Additional optional Tailwind CSS classes
  children?: ReactNode;
}

const IconButton = ({
  icon,
  onClick,
  size = 24,
  color = "black",
  padding = 4,
  className = "",
  children,
}: IconButtonProps) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsClicked(true);
    onClick();
    setTimeout(() => setIsClicked(false), 200); // Resets the animation after 200ms
  };

  return (
    <button
      onClick={handleClick}
      className={`flex justify-center cursor-pointer items-center h-min hover:bg-neutral-800 duration-300 p-[${padding}px] rounded-full ${
        isClicked ? "scale-90" : "scale-100"
      } transition-transform ease-in-out ${className}`}
      style={{ color }}
    >
      {icon ? (
        <img
          src={icon}
          alt="Menu Icon"
          style={{
            width: size,
            height: size,
          }}
          className="text-base"
        />
      ) : (
        children
      )}
    </button>
  );
};

export default IconButton;

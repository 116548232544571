import Slider from "react-slick"; // Import Slider from react-slick
import salesIcon from "../../assets/icons/Sales.svg";
import { ITrending } from "../../interfaces/trending";
import UserIcon from "../icons/UserIcon";
import UsersIcon from "../icons/UsersIcon";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const TrendingSection = ({ trendingInfos }: { trendingInfos: ITrending[] }) => {
  const settings = {
    infinite: true,
    speed: 500,
    rows: 4,
    arrows: false,
    dots: true,
    slidesToShow: 1,
    slidesPerRow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="flex flex-col items-start gap-[12px] mt-m">
      <div className="flex justify-between items-center gap-[10px] self-stretch">
        <h2 className="text-primaryText text-lg not-italic font-bold leading-[120%]">
          Trending
        </h2>
      </div>

      <div className="slide-container w-full">
        <Slider {...settings}>
          {trendingInfos.map((trending, index) => {
            return (
              <div key={index}>
                <div className="flex items-center gap-[16px] px-sm py-[16px] bg-neutral-1000 rounded-xs w-full mt-2">
                  <div className="relative">
                    <div className="w-[80px] h-[80px] border-[1px] rounded-xl border-solid border-neutral-700">
                      {trending.photo ? (
                        <img
                          src={trending.photo}
                          alt="Avatar"
                          className="w-[80px] h-[80px] border-[2px] rounded-xl border-solid border-base object-cover"
                        />
                      ) : trending.entityMetric === "REP" ? (
                        <UserIcon className="w-[80px] h-[80px] border-[2px] rounded-xl border-solid border-base p-3" />
                      ) : (
                        <UsersIcon className="w-[80px] h-[80px] border-[2px] rounded-xl border-solid border-base p-3" />
                      )}
                    </div>

                    <img
                      src={salesIcon}
                      alt="Sales"
                      className="absolute right-0 bottom-0 w-[24px] h-[24px] p-xs bg-primary-500 rounded-xl [box-shadow:0px_3.6px_16.8px_0px_rgba(0,_0,_0,_0.14)]"
                    />
                  </div>

                  <div className="flex flex-col gap-xs overflow-hidden">
                    <p className="text-neutral-400 font-primary text-xs not-italic font-semibold leading-[120%] whitespace-nowrap">
                      {trending.title}
                    </p>

                    <p className="text-base-highlight -font-primary not-italic font-bold leading-[120%] whitespace-nowrap">
                      {trending.subTitle}
                    </p>

                    <p className="text-neutral-100 h-[34px] self-stretch text-base-highlight font-primary text-sm not-italic font-medium leading-[120%] whitespace-nowrap overflow-hidden overflow-ellipsis">
                      {trending.description}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default TrendingSection;

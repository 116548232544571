const ArrowDown = ({ className }: { className: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      className={className}
    >
      <path
        d="M6.97744 1.31461L3.94895 5.04369L0.918237 1.31461C0.918018 1.31407 0.917739 1.31333 0.917424 1.31237C0.915727 1.30722 0.914062 1.29895 0.914062 1.28875C0.914062 1.27855 0.915727 1.27027 0.917424 1.26512C0.917739 1.26416 0.918018 1.26343 0.918237 1.26289L1.16833 0.955169L3.56183 3.88399L3.94917 4.35795L4.33629 3.88381L6.72741 0.955232L6.97744 1.26288C6.97766 1.26342 6.97794 1.26416 6.97826 1.26512C6.97995 1.27027 6.98162 1.27855 6.98162 1.28875C6.98162 1.29895 6.97995 1.30722 6.97826 1.31237C6.97794 1.31333 6.97766 1.31407 6.97744 1.31461Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};

export default ArrowDown;

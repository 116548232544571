import React from "react";

interface ButtonProps {
  onClick?: () => void; // Optional click handler
  children?: React.ReactNode; // Content of the button
  className?: string; // Additional custom classes
  bgColor?: string; // Background color
  activeColor?: string; // Active background color
  textColor?: string; // Text color
  buttonType?: "button" | "submit" | "reset"; // Button type, limited to valid HTML button types
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  children,
  className = "",
  bgColor = "bg-primary",
  activeColor = "active:bg-primary",
  textColor = "text-base",
  buttonType = "button",
}) => {
  return (
    <button
      type={buttonType} // Use the `buttonType` prop directly here
      onClick={onClick}
      className={`flex items-center justify-center px-[14px] shadow-sm py-sm gap-[8px] rounded-[6px] ${bgColor} ${activeColor} ${textColor} transition-colors duration-300 ease-in-out ${className} h-[45px]`}
    >
      {children}
    </button>
  );
};

export default Button;

import React, { useState } from "react";

import ChatVVNT from "../../components/ChatVVNT";
// import ChatVVNTButton from "../../components/ChatVVNT/ChatVVNTButton";
import Home from "../../components/Home";

import { useAppSelector } from "../../redux/hooks";

const HomePage: React.FC = () => {
  const [showChat, setShowChat] = useState(false);
  const { isPopupOpened } = useAppSelector((state) => state.global);

  // const handleOnChat = () => {
  //   setShowChat(true);
  // };

  const handleOnCloseChat = () => {
    setShowChat(false);
  };

  return (
    <div
      className={`flex flex-col gap-[18px] p-[12px] max-h-fit-height max-w-[500px] md:max-w-[768px] lg:max-w-[1024px] xl:max-w-[1280px] mx-auto relative ${
        isPopupOpened ? "overflow-hidden" : "overflow-y-auto"
      }`}
    >
      <Home />

      {/* <ChatVVNTButton handleOnChat={handleOnChat} /> */}

      <ChatVVNT showChat={showChat} onClose={handleOnCloseChat} />
    </div>
  );
};

export default HomePage;

const CloseIcon = ({ className }: { className: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M13.4119 12L19.7119 5.71C19.9002 5.5217 20.006 5.2663 20.006 5C20.006 4.7337 19.9002 4.47831 19.7119 4.29C19.5236 4.1017 19.2682 3.99591 19.0019 3.99591C18.7356 3.99591 18.4802 4.1017 18.2919 4.29L12.0019 10.59L5.71189 4.29C5.52359 4.1017 5.26819 3.99591 5.00189 3.99591C4.73559 3.99591 4.4802 4.1017 4.29189 4.29C4.10359 4.47831 3.9978 4.7337 3.9978 5C3.9978 5.2663 4.10359 5.5217 4.29189 5.71L10.5919 12L4.29189 18.29C4.19816 18.383 4.12377 18.4936 4.073 18.6154C4.02223 18.7373 3.99609 18.868 3.99609 19C3.99609 19.132 4.02223 19.2627 4.073 19.3846C4.12377 19.5064 4.19816 19.617 4.29189 19.71C4.38486 19.8037 4.49546 19.8781 4.61732 19.9289C4.73917 19.9797 4.86988 20.0058 5.00189 20.0058C5.1339 20.0058 5.26461 19.9797 5.38647 19.9289C5.50833 19.8781 5.61893 19.8037 5.71189 19.71L12.0019 13.41L18.2919 19.71C18.3849 19.8037 18.4955 19.8781 18.6173 19.9289C18.7392 19.9797 18.8699 20.0058 19.0019 20.0058C19.1339 20.0058 19.2646 19.9797 19.3865 19.9289C19.5083 19.8781 19.6189 19.8037 19.7119 19.71C19.8056 19.617 19.88 19.5064 19.9308 19.3846C19.9816 19.2627 20.0077 19.132 20.0077 19C20.0077 18.868 19.9816 18.7373 19.9308 18.6154C19.88 18.4936 19.8056 18.383 19.7119 18.29L13.4119 12Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;

import { useCallback } from "react";
import { Label, PanelHeader } from ".";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateReportFilter } from "../../../redux/slices/reportSlice";
import FilterDropDown from "../../Common/FilterDropDown";

const AdditionalFilters = () => {
  const dispatch = useAppDispatch();
  const { reportDataInfo, reportFilters, selectedFilters } = useAppSelector(
    (state) => state.report
  );

  const handleOptionSelect = useCallback(
    (filterName: string, selectedOption: string[]) => {
      // Dispatch the action to update the selected filter
      dispatch(updateReportFilter({ filterName, selectedOption }));
    },
    [dispatch]
  );

  const renderAdditionalFilters = () => {
    return (
      <div className="grid grid-cols-2 gap-[16px] gap-y-[32px]">
        {reportDataInfo.filterAdditional.map((filterName, index) => {
          const options = reportFilters[filterName];

          const selectedOptions: string[] = (() => {
            const filterValue = selectedFilters[filterName];

            if (typeof filterValue === "string") {
              return [filterValue]; // Convert single string to an array
            } else if (Array.isArray(filterValue)) {
              return filterValue.flat(); // Flatten any nested arrays
            } else {
              return options; // Handle case where filterValue might be undefined or invalid
            }
          })();

          return (
            <div className="flex flex-col gap-[4px]" key={index}>
              <Label label={filterName} />

              <FilterDropDown
                options={options}
                selectedOption={selectedOptions || ["ALL"]} // Set the current selection
                onOptionSelect={(option) =>
                  handleOptionSelect(filterName, option)
                }
                className="w-[100%]"
                buttonBgColor="bg-custom-darkTeal"
                buttonTextColor="text-primaryText"
                buttonHeight="h-[38px]"
                buttonWidth="!w-[100%]"
                dropdownOffset={42}
                dropdownBg="bg-custom-dropdownBg"
                dropdownText="text-custom-dropdownText"
                handleApply={() => {}}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="w-full bg-custom-deepCharcoal rounded-[8px] gap-[24px] shadow-8dp-umbra">
      <div className="flex flex-col w-full gap-[32px] px-[8px] pb-[24px]">
        <PanelHeader header="ADDITIONAL" />
        {renderAdditionalFilters()}
      </div>
    </div>
  );
};

export default AdditionalFilters;

import { useEffect } from "react";
import DEFAULT_THEME_COLORS from "../const/theme";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { loadTheme } from "../redux/slices/globalSlice";
import { applyTheme, applyThemeColors } from "../utils/theme";

const useTheme = () => {
  const dispatch = useAppDispatch();
  const { themes, currentTheme } = useAppSelector((state) => state.global);

  useEffect(() => {
    dispatch(loadTheme());
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(DEFAULT_THEME_COLORS).indexOf(currentTheme) === -1) {
      const customTheme = themes.find(
        (theme) => theme.themeName === currentTheme
      );
      if (customTheme) applyThemeColors(customTheme.themeColors);
    } else {
      applyTheme(currentTheme);
    }
  }, [currentTheme, themes]);
};

export default useTheme;
